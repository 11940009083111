import React from 'react';
import { Link, Text, View } from '@react-pdf/renderer';
import { styles, sectionTitleStyle } from '../styles/styles';
import PDFConfirmations from '../confirmations/PDFConfirmations';

const PDFCV = (props) => {
    return (
        <View wrap={false}>
            <View style={sectionTitleStyle.container}>
                <View style={sectionTitleStyle.sectionTitlePastille}>
                    <Text>4</Text>
                </View>
                <Text style={sectionTitleStyle.sectionTitle}>CV</Text>
            </View>
            <View style={styles.sectionProfile}>
                <Text style={styles.blueSubtitles}>Lien vers le CV</Text>
                {(!props.supporting_documents || !props?.supporting_documents?.find(document => document?.type === "CV")) && <Text style={styles.normalBlackTextDin}>Non Renseigné</Text>}
                {props.supporting_documents.length > 0 &&
                    props.supporting_documents.map((document, key) => {
                        if (document?.type === "CV") {
                            return <Link key={key} style={styles.normalBlackTextDin} src={`${props.env.REACT_APP_API_URL}${document?.path}/getFile/${document?.name}.${document?.extension}+${document?.id}`}>Voir le CV</Link>
                        }
                        return null;
                    })
                }
                <PDFConfirmations {...props} />
            </View>
        </View>
    );
};

export default PDFCV;