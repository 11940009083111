import React, { createContext, useReducer } from "react";

const initialState = {
    offer: null,
    candidatures: null,
    project: null,
    reload: false,
}

const OfferDetailsContext = createContext(initialState);

const reducer = (state, action) => {
    let { target, type } = action;
    switch (type) {
        case "setOfferAndCandidatures": {
            let candidatures = { ...target.missions };
            let project = { ...target.project };

            delete target.project;
            delete target.missions;
            return {
                offer: target,
                candidatures: candidatures,
                project: project
            }
        }
        case "reload": {
            return {
                reload: !state.reload,
            }
        }
        default: return state
    }
}

const OfferDetailsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);


    return (
        <OfferDetailsContext.Provider value={{ state, dispatch }}>
            {children}
        </OfferDetailsContext.Provider >
    )
}

export { OfferDetailsContext, OfferDetailsProvider };