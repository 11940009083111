import React, { useContext, useState, useEffect } from "react";
import ProjectComponent from "./ProjectComponent";
import { projectsContext } from "../../../../stores/projectsStore";
import { PROJECT_GET_MANY } from "../../../../queries/getProjects";
import client from "../../../../ApolloSetup";
import { withRouter } from "react-router-dom";
import PaginationComponent from "../../../pagination/PaginationComponent";
import { Loader } from "../../../loader/Loader";
import { useQuery } from '@apollo/react-hooks';
import { projectCardConstant } from "../../../../constants/projects.constants";
import { searchEngineConstant } from "../../../../constants/searchEngine.constants";
import { useHistory, useParams } from 'react-router-dom';
import ProjectNotFound from './ProjectNotFound';

const { TITLE } = projectCardConstant;
const { SEARCH_BUTTON_INPUT, DELETE_BUTTON_INPUT } = searchEngineConstant;

const ProjectContainer = (props) => {

    const { page } = useParams();
    const projectsStore = useContext(projectsContext);
    const [inputState, setInputState] = useState(""); //store input value on change
    const [inputValue, setInputValue] = useState(""); //store input value on component mount
    const [launch, setLaunch] = useState(false); //reset component
    const history = useHistory();

    const { data, loading, errors } = useQuery(PROJECT_GET_MANY, {
        variables: {
            options: {
                orderBy: "createdAt_ASC",
                page: parseInt(page),
                elementsPerPage: 10
            },
            title: inputValue
        },
        fetchPolicy: "no-cache",
        client
    });

    //remounts component when launch changes (handle search and handle clear)
    useEffect(() => {
        setInputValue(inputState);
    }, [launch]);

    //store input onchange
    const handleInputChange = (e) => {
        setInputState(e.target.value);
    };

    //relaunch component and push to page one
    const handleSearch = () => {
        history.push('1');
        setLaunch(!launch);
    };

    //push to page 1, clear input state, relaunch component
    const handleClear = () => {
        history.push('1');
        setInputState("");
        setLaunch(!launch);
    };

    if (loading === true) { return <div style={{ height: '50vh' }}><Loader class="center" /></div> }
    if (errors) return <div>ERROR</div>;
    if (data && !loading && !errors) {
        const projects = data?.projectGetMany?.data;
        const meta = data?.projectGetMany?.meta;
        return (
            <div>
                <section className="search mb-3">
                    <div className="form-group input-search px-3 py-3 bg-white rounded shadow-sm mb-4">
                        <div className="input-group">
                            <input type="text" name="inputSearch" value={inputState} onChange={handleInputChange} className="form-control mr-3 border-0" placeholder="Rechercher le titre d'un projet... (3 lettres minimum)" />
                            <button onClick={handleSearch} type="button" className="btn btn-civipol mr-3" disabled={inputState.length <= 2}>
                                {SEARCH_BUTTON_INPUT}
                            </button>
                            <button onClick={handleClear} type="button" className="btn btn-civipol-white">
                                {DELETE_BUTTON_INPUT}
                            </button>
                        </div>
                    </div>
                </section>
                { (data?.projectGetMany?.data?.length > 0 && meta) &&
                    <>
                        <div className="mb-4">
                            <h2 className="big-title mb-3">{TITLE} - Total: {meta.allElemsCount} {meta.resultsCount !== meta.allElemsCount && `- Trouvés: ${meta.resultsCount}`}</h2>
                        </div>
                        {projects.map((value, key) => (
                            <div key={key}> <ProjectComponent value={value} /> </div>
                        ))}
                        <PaginationComponent context={projectsStore} nb_all={meta?.resultsCount} page={page} />
                    </>
                }
                {(data?.projectGetMany?.data?.length === 0 || !projects) && <ProjectNotFound />}
            </div>
        );
    } else {
        return null;
    }
}

export default withRouter(ProjectContainer);