import {translate} from "./translation.constants";

export const headerConstants = {
    WELCOME: translate("header.welcome"),
    LOGOUT: translate("header.logout"),
    HOME: translate("header.home"),
    EXPERTS: translate("header.experts"),
    PROJECTS: translate("header.projects"),
    VALIDATIONS: translate("header.validations"),
    DEMANDS: translate("header.demands"),
    MAILING: translate("header.mailing")
}
