import React from "react";
import { expertConstants } from "../../../../constants/experts.contants";
import { Link } from 'react-router-dom';
import moment from "moment";
import { notificationContext } from '../../../../stores/notificationsStore';
import { useContext } from "react";
import Female from '../../../../static/images/expert-card/portrait-female-default@2x.png';
import Male from '../../../../static/images/expert-card/portrait-male-default@2x.png';

const MissionSituation = (props) => {
    const { missions, expert } = props;
    for (let mission in missions) {
        if (missions[mission].staffed === "InMission" && missions[mission].key_expert === true) {
            return (
                <span className="profile-status status mr-4" data-color="#da413f">
                    <span className="dot rounded-circle mr-2">
                    </span>
                    Expert principal en mission
                </span>
            )
        } else if ((missions[mission].staffed === "InMission" || missions[mission].staffed === "Agreed") && missions[mission].key_expert === false) {
            return (
                <span className="profile-status status mr-4" data-color="#da413f">
                    <span className="dot rounded-circle mr-2">
                    </span>
                    Expert en mission
                </span>
            )
        } else if (missions[mission].staffed === "Postule") {
            return (
                <span className="profile-status status mr-4" data-color="#3fda8e">
                    <span className="dot rounded-circle mr-2">
                    </span>
                    Expert disponible
                </span>
            )
        }
    }
    if (missions.length === 0) {
        if (expert.status === "COMPLETE") {
            return (
                <span className="profile-status status mr-4" data-color="#3fda8e">
                    <span className="dot rounded-circle mr-2">
                    </span>
                    Expert disponible
                </span>
            )
        }
        if (expert.status === "CREATED" || expert.status === null) {
            return (
                <span className="profile-status status mr-4" data-color="#666666">
                    <span className="dot rounded-circle mr-2">
                    </span>
                    Profil incomplet
                </span>
            )
        }
    }
    return null
};

const CandidaturesModal = ({ missions, close }) => (
    <div className='d-flex flex-column'>
        {missions
            .filter(mission => mission.staffed === 'Postule')
            .map((mission, key) => <Link key={key} onClick={() => close()} to={`/details-offer/${mission?.offer?.id}`} >{mission?.offer?.title?.fr}</Link>)
        }
    </div>
);

const ExpertCard = (props) => {

    const { firstName, lastName, createdAt, updatedAt, missions, profile_pic, projectLeader, id, status, civility } = props;
    const { DATE_CREATION, LAST_MODIFICATION, MISSION_FOR_CIVIPOL } = expertConstants;
    const notificationStore = useContext(notificationContext);
    const created = moment(createdAt).format("DD/MM/YYYY");
    const updated = moment(updatedAt).format("DD/MM/YYYY");
    let nb_missions = 0
    let divStyle = "";

    if (!profile_pic && civility === 'FEMALE') {
        divStyle = { backgroundImage: `url(${Female})` };
    }
    if (!profile_pic && civility === 'MALE') {
        divStyle = { backgroundImage: `url(${Male})` };
    }

    if (profile_pic) {
        divStyle = { backgroundImage: 'url(' + process.env.REACT_APP_API_URL + profile_pic.substring(1, profile_pic.length) + ')' };
    }
    
    const handleCandidaturesModal = () => {
        notificationStore.dispatch({
            type: 'setAlert',
            target: {
                element: 'candidaturesExpert',
                custom: <CandidaturesModal missions={missions} close={() => notificationStore.dispatch({type: 'closeAlert'})} />,
                isCancel: false
            }
        });
    };

    for (let i = 0; i < missions.length; i++) { if (missions[i].staffed === "Terminated") { nb_missions += 1 } };
    return (
        <div className="col">
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row flex-row justify-content-between align-items-start">
                        <div className="col-8 mb-4 mb-lg-0">
                            <div className="d-flex justify-content-start align-items-center mb-4">
                                <div className="rounded-circle profile-picture" style={divStyle || null}>
                                </div>
                                <div className="d-flex flex-column ml-4">
                                    <div className="d-flex">
                                        <p className="profile-name text-capitalize">{`${firstName.toLowerCase()} ${lastName.toLowerCase()}`}</p>
                                        {projectLeader && <h5 className="ml-5 text-primary">Team Leader</h5>}
                                        {status === 'BLOCKED' && <h5 className="text-danger ml-5">Compte bloqué</h5>}
                                    </div>
                                    <MissionSituation missions={missions} expert={props} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-start align-items-start">
                                <div className="information-expert mr-4 mr-lg-5">
                                    <label className="text-uppercase">{DATE_CREATION}</label>
                                    <h4 className="mb-0">{created}</h4>
                                </div>
                                <div className="information-expert mr-4 mr-lg-5">
                                    <label className="text-uppercase">{LAST_MODIFICATION}</label>
                                    <h4 className="mb-0">{updated}</h4>
                                </div>
                                <div className="information-expert mr-4 mr-lg-5">
                                    <label className="text-uppercase">{MISSION_FOR_CIVIPOL}</label>
                                    <h4 className="mb-0">{nb_missions}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 d-flex flex-column justify-content-end">
                            <Link className="text-decoration-none btn btn-civipol-white mb-3" to={`/experts/expert/${id}`}>Voir le profil</Link>
                            <button
                                onClick={handleCandidaturesModal}
                                className="text-decoration-none btn btn-civipol-white mb-3"
                            >Candidatures</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpertCard;