import React, { useContext, useState } from "react";
import { missionsContext } from "../../../../stores/missionsStore";
import { candidaturesConstants } from "../../../../constants/missions.constants";
import moment from "moment";
import client from "../../../../ApolloSetup";
import { ACCEPT_CANDIDATURE, REJECT_CANDIDATURE } from "../../../../mutations/upsertMissions";
import { withRouter, Link } from "react-router-dom";
import { notificationContext } from "../../../../stores/notificationsStore";

const { DATE, BUTTON_SEE_OFFER, BUTTON_VALIDATION, BUTTON_REJECTION, DATE_REJECT } = candidaturesConstants;
const lang = localStorage.lang;

const handleCandidatureError = ({ dispatch }, history, id_expert, graphQLErrors) => {

    for (let error in graphQLErrors) {
        if (graphQLErrors[error].message === "Not complete") {
            dispatch({ type: "setAlert", target: { element: "expertNotComplete", isCancel: false } });
        } else if (graphQLErrors[error].message === "Already team leader assigned") {
            dispatch({ type: "setAlert", target: { element: "teamLeaderAlreadyAssigned", isCancel: false } });
        } else {
            dispatch({ type: "setAlert", target: { element: "errorAlert", isCancel: false } });
        }
    };
};

const validateCandidature = (id_expert, id_mission, { dispatch }, notificationStore, history, isTeamLeader) => {
    client.mutate({
        mutation: ACCEPT_CANDIDATURE,
        variables: { id_expert: id_expert, id_mission: id_mission, isTeamLeader }
    })
        .then(values => { dispatch({ type: "updateMissions" }) })
        .catch(({ graphQLErrors }) => { handleCandidatureError(notificationStore, history, id_expert, graphQLErrors) });
}

const rejectCandidature = (id_expert, id_mission, { dispatch }) => {
    client.mutate({
        mutation: REJECT_CANDIDATURE,
        variables: { id_expert: id_expert, id_mission: id_mission }
    })
        .then(values => { dispatch({ type: "updateMissions" }) })
        .catch(err => console.log(err));
}

const CandidatureComponent = (props) => {
    const missionStore = useContext(missionsContext);
    const notificationStore = useContext(notificationContext);
    const [isTeamLeader, setTeamLeader] = useState(false);
    const { value } = props;
    const title = value.offer.title[lang];
    const { date_postulat, date_reject } = value;
    const id_mission = value.id;
    const id_expert = props.match.params.id;

    const handleTeamLeader = ({ target }) => {
        setTeamLeader(target.checked);
    };

    return (
        <div className="row flex-column">
            <div className="col">
                <h2 className="mb-2">{title}</h2>
                <div className="row">
                    <div className="col col-12 d-flex justify-content-between mb-3">
                        <div>
                            <p className="text-muted text-uppercase mb-0">{DATE}</p>
                            <p className="mb-0">{moment(date_postulat).format("DD/MM/YYYY")}</p>
                        </div>
                        {props.reject &&
                            <div>
                                <p className="text-muted text-uppercase mb-0">{DATE_REJECT}</p>
                                <p className="mb-0">{moment(date_reject).format("DD/MM/YYYY")}</p>
                            </div>
                        }
                        <div>
                            <Link className="btn btn-civipol-white" to={`/details-offer/${props.value.offer.id}`}>
                                {BUTTON_SEE_OFFER}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {!props.reject &&
                        <div className="col d-flex justify-content-between">

                            <div className="custom-control custom-checkbox custom-checkbox-fat">
                                <input type="checkbox" className="custom-control-input" id="teamLeader" onClick={handleTeamLeader} />
                                <label className="custom-control-label" htmlFor="teamLeader">Team Leader</label>
                            </div>
                            <button onClick={() => validateCandidature(id_expert, id_mission, missionStore, notificationStore, props.history, isTeamLeader)} className="btn btn-green mr-3">{BUTTON_VALIDATION}</button>
                            <button onClick={() => rejectCandidature(id_expert, id_mission, missionStore)} className="btn btn-red">{BUTTON_REJECTION}</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default withRouter(CandidatureComponent);