import gql from "graphql-tag";

const GET_MODIFICATIONS_LOGS = gql`
    query getLogsModifications($expertID: ID!){
        getLogsModifications(expertID: $expertID){
            expert {
                firstName
                lastName
            }
            role
            action
            action_type
            log
            createdAt
            employe {
                login
            }
            table
            mission {
                offer {
                    id_drupal
                }
            }
        }   
    }
`;

const GET_CONSULTATIONS_LOGS = gql`
    query getLogsConsultations($expertID: ID!){
        getLogsConsultations(expertID: $expertID){
            expert {
                firstName
                lastName
            }
            createdAt
            employe {
                login
            }
        }   
    }
`;


export {GET_MODIFICATIONS_LOGS, GET_CONSULTATIONS_LOGS}