import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { TranslateContextWrapper } from '../../../../stores/TranslateContext';
import { abilitiesConstants } from "../../../../constants/profile-expert.constants";
import { CalendarComponent } from "../../../calendar/CalendarComponent";
import { Form } from 'react-bootstrap';
const {
    SERVICES_DETAILS_TITLE,
    SERVICES_DETAILS_PUBLIC_AGENT,
    SERVICES_DETAILS_FONTIONNARY,
    RETIRED_FONCTIONNARY,
    SERVICES_DETAILS_NOT_FONTIONNARY,
    ENTITY,
    GRADE,
    RETIRED_SINCE,
    TYPE_OF_CONTRACT,
    SALARIAL_CONTRACT,
    PRESTATION_CONTRACT
} = abilitiesConstants;


const sectionMessageName = "expertForm.abilities.";
const entitiesArray = ["SELECT", "DGPN", "DGGN", "DGSCGC", "DGDDI", "DGEF", "DGSI", "DGSL", "SG", "JUSTICE_MIN", "ECONOMIC_MIN", "DEFENSE_MIN", "FOREIGN_AFF_MIN", "FIRST_MIN", "OTHER"];
const levelsArray = ["SELECT", "AP", "A", "B", "C"];

const CustomSelector = ({
    typeOfSelect,
    ...props
}) => {

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: "2px solid #efefef",
            height: "60px",
        })
    };

    const handleChangeSelect = ({ value }) => {
        props.setValues({
            ...props.values,
            expert_ability: {
                ...props.values.expert_ability,
                [typeOfSelect]: value
            }
        });
    };

    const value = (typeOfSelect === "entity")
        ? (props.values.expert_ability.entity)
            ? { value: props.values.expert_ability.entity, label: props.translateContext.translateMessage(sectionMessageName + "DG." + props.values.expert_ability.entity) }
            : { value: "SELECT", label: props.translateContext.translateMessage(sectionMessageName + "DG.SELECT") }
        : (props.values.expert_ability.level)
            ? { value: props.values.expert_ability.level, label: props.translateContext.translateMessage(sectionMessageName + "levels." + props.values.expert_ability.level) }
            : { value: "SELECT", label: props.translateContext.translateMessage(sectionMessageName + "levels.SELECT") }

    const options = (typeOfSelect === "entity")
        ? entitiesArray.map((entity) => { return { label: props.translateContext.translateMessage(`${sectionMessageName}DG.${entity}`), value: entity } })
        : levelsArray.map((level) => { return { label: props.translateContext.translateMessage(`${sectionMessageName}levels.${level}`), value: level } });

    return (
        <Select
            styles={customStyles}
            options={options}
            value={value}
            onChange={handleChangeSelect}
        />
    );
};

const Fonctionnaire = (formikProps) => {
    return (
        <div>
            <div className="triangle-1" />
            <div className="form-group row flex-row before abilities-subselect px-2 px-md-4 py-3 py-md-4">
                <div className="col-12 col-md-6 mb-4 mb-md-0" >
                    {ENTITY}
                    <CustomSelector typeOfSelect="entity" {...formikProps} />
                </div>
                <div className="col-12 col-md-6 mb-4" >
                    {GRADE}
                    <CustomSelector typeOfSelect="level" {...formikProps} />
                </div>
            </div>
        </div>
    );
};

const Retired = (formikProps) => {
    return (
        <div>
            <div className="triangle-1" />
            <div className="form-group row flex-row before abilities-subselect px-2 px-md-4 py-3 py-md-4">
                <div className="col-12 col-md-6 mb-4 mb-md-0" >
                    {ENTITY}
                    <CustomSelector typeOfSelect="entity" {...formikProps} />
                </div>
                <div className="col-12 col-md-6 mb-4" >
                    {RETIRED_SINCE}
                    <input
                        name={`expert_ability.date_retired`}
                        onChange={formikProps.handleChange}
                        type="date"
                        className="date-input"
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.expert_ability.date_retired || Date.now()}
                    />
                </div>
            </div>
        </div>
    );
};

const Independant = (formikProps) => {
    return (
        <div>
            <div className="triangle-1" />
            <div className="form-group d-flex flex-column before abilities-subselect px-2 px-md-4 py-3 py-md-4">
                <div className="mb-2">{TYPE_OF_CONTRACT}</div>
                <div className="row flex-row">
                    <div className="col-12 col-md-6 mb-4 mb-md-0" >
                        <div className="custom-control custom-radio custom-control-inline">
                            <Form.Check className="custom-control custom-radio custom-radio-fat" type='radio'>
                                <input onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} type="radio" id="salarial" name="expert_ability.type_of_contract" className="custom-control-input" value="SALARIAL" defaultChecked={formikProps.values.expert_ability.type_of_contract === "SALARIAL"} />
                                <label className="custom-control-label" htmlFor="salarial">{SALARIAL_CONTRACT}</label>
                            </Form.Check>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4" >
                        <div className="custom-control custom-radio custom-control-inline">
                            <Form.Check className="custom-control custom-radio custom-radio-fat" type='radio'>
                                <input onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} type="radio" id="service" name="expert_ability.type_of_contract" className="custom-control-input" value="SERVICE" defaultChecked={formikProps.values.expert_ability.type_of_contract === "SERVICE"} />
                                <label className="custom-control-label" htmlFor="service">{PRESTATION_CONTRACT}</label>
                            </Form.Check>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ProfessionalSituation = (props) => {

    const initialState = {
        entity: null,
        level: null,
        is_retired: false,
        date_retired: Date.now(),
        is_fonctionnaire: true,
        type_of_contract: null,
    };

    const handleChangeCheckbox = ({ target }) => {
        const { id } = target;
        if (id === "fonctionnaire") {
            props.setValues({
                ...props.values,
                expert_ability: {
                    ...initialState,
                    is_fonctionnaire: true,
                    is_retired: false,
                    date_retired: ""
                }
            });
        } else if (id === "retired") {
            props.setValues({
                ...props.values,
                expert_ability: {
                    ...initialState,
                    is_fonctionnaire: true,
                    is_retired: true,
                    date_retired: props.values.expert_ability.date_retired
                }
            });
        } else {
            props.setValues({
                ...props.values,
                expert_ability: {
                    ...initialState,
                    date_retired: "",
                    is_fonctionnaire: false,
                    is_retired: false,
                }
            });
        }
    };

    return (
        <div>
            <h2 className="title-section-blue text-uppercase mb-4">{SERVICES_DETAILS_TITLE}</h2>
            <label>{SERVICES_DETAILS_PUBLIC_AGENT}*</label>
            <div className="row flex-row form-group" onChange={handleChangeCheckbox}>
                <div className="col-12 col-md-4 mb-4 mb-md-0">
                    <div className="custom-control custom-radio custom-control-inline">
                        <Form.Check className="custom-control custom-radio custom-radio-fat" type='radio'>
                            <input type="radio" id="fonctionnaire" name="customRadioInline1" className="custom-control-input" value="fonctionnaire" defaultChecked={props.values.expert_ability.is_fonctionnaire && !props.values.expert_ability.is_retired} />
                            <label className="custom-control-label form-check-label" htmlFor="fonctionnaire">{SERVICES_DETAILS_FONTIONNARY}</label>
                        </Form.Check>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-4 mb-md-0">
                    <div className="custom-control custom-radio custom-control-inline">
                        <Form.Check className="custom-control custom-radio custom-radio-fat" type='radio'>
                            <input type="radio" id="retired" name="customRadioInline1" className="custom-control-input" value="retired" defaultChecked={props.values.expert_ability.is_retired} />
                            <label className="custom-control-label form-check-label" htmlFor="retired">{RETIRED_FONCTIONNARY}</label>
                        </Form.Check>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-4 mb-md-0 pr-0">
                    <div className="custom-control custom-radio custom-control-inline">
                        <Form.Check className="custom-control custom-radio custom-radio-fat" type='radio'>
                            <input type="radio" id="not-fonctionnaire" name="customRadioInline1" className="custom-control-input" value="not-fonctionnaire" defaultChecked={!props.values.expert_ability.is_fonctionnaire && props.values.expert_ability !== ""} />
                            <label className="custom-control-label form-check-label" htmlFor="not-fonctionnaire">{SERVICES_DETAILS_NOT_FONTIONNARY}</label>
                        </Form.Check>
                    </div>
                </div>
            </div>
            <div>
                {((props.values.expert_ability.is_fonctionnaire && !props.values.expert_ability.is_retired)
                    || (props.values.expert_ability === "")) && <Fonctionnaire {...props} />}
                {(props.values.expert_ability.is_retired) && <Retired {...props} />}
                {((!props.values.expert_ability.is_fonctionnaire) && (props.values.expert_ability !== "")) && <Independant {...props} />}
            </div>
        </div>
    );

};

export default TranslateContextWrapper(ProfessionalSituation);