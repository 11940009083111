import gql from "graphql-tag";

const REGISTER_SEARCH = gql `
    mutation setHistoric($search: JSON!, $name: String!) {
        setHistoric(search: $search, name: $name) {
            id
            name
            search
        }   
    }
`;

const DELETE_SEARCH = gql `
    mutation deleteResearch($name: String!) {
        deleteResearch(name: $name) {
            id
            name
            search
        }   
    }
`;

export {REGISTER_SEARCH, DELETE_SEARCH};