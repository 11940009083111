import React from "react";
import {FormattedMessage} from "react-intl";
import {abilitiesConstants} from "../../../../constants/profile-expert.constants";
import LanguageSelection from "./LanguageSelection";
const {
    LANGUAGES_TITLE, 
    LANGUAGES_SUBTITLE, 
    LANGUAGES_SUBTITLE_LINK
} = abilitiesConstants;
const Languages = (props) => {
    return (
        <div>
            <h2 className="title-section-blue text-uppercase mb-4">{LANGUAGES_TITLE}</h2>
            <div className="mb-4">
                <p className="text-muted mb-0"><small>{LANGUAGES_SUBTITLE}</small></p>
                <a href="https://www.cambridgeenglish.org/fr/exams-and-tests/cefr/" target="_blank" rel="noopener noreferrer">
                    <p className="text-muted"><small><u>{LANGUAGES_SUBTITLE_LINK}</u></small></p>
                </a>
                {props.errors.languages && props.errors.languages.all && <p className="is-invalid"><FormattedMessage id="validation.selectAtLeastOne" defaultMessage="Not translated" /></p>}
            </div>
            <LanguageSelection {...props} />
        </div>
    );
}

export default Languages 