import React, { useContext } from "react";
import { notificationContext } from "../../../stores/notificationsStore";
import SweetAlert from "react-bootstrap-sweetalert";
import { promptNotification } from "../../../constants/notifications.constants";

const PromptAlert = () => {
    const { state, dispatch } = useContext(notificationContext);
    const { element, isOpen } = state.alert;

    if (element === "historic") {
        const handleConfirm = async (response) => {
            if (state.alert.callback !== null) {
                await dispatch({ type: "setTitleHistoric", target: response });
                state.alert.callback(response);
            }
            dispatch({ type: "closeAlert" })
        }


        if (!isOpen) return null;
        else {
            const { TITLE, TEXT, SUBMIT, CANCEL } = promptNotification[element];
            return (
                <SweetAlert
                    input
                    title={TITLE}
                    showCancel
                    placeholder={"Entrer un titre"}
                    confirmBtnTex={SUBMIT}
                    cancelBtnText={CANCEL}
                    onConfirm={(response) => handleConfirm(response)}
                    closeOnClickOutside={true}
                    onCancel={() => dispatch({ type: "closeAlert" })}
                >
                    {TEXT}
                </SweetAlert>
            )
        }
    } else return null;
}

export default PromptAlert;