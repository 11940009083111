import React, { useEffect, useContext } from "react";
import { searchEngineConstant } from "../../../../constants/searchEngine.constants";
import { historicSearchContext } from "../../../../stores/historicSearchStore";
import client from "../../../../ApolloSetup";
import { GET_SEARCH } from "../../../../queries/historic";
import { DELETE_SEARCH } from "../../../../mutations/historic";
import { expertPageContext } from "../../../../stores/expertsPageStore";
import { setSessionItemByKeyValue } from "../../../utils/sessionStorage";
import { withRouter } from "react-router-dom";

const { SEARCH_ENGINE_BUTTON, DELETE } = searchEngineConstant;

const deleteResearch = async (name, { dispatch }) => {

    await client
        .mutate({
            mutation: DELETE_SEARCH,
            variables: { name: name }
        })
        .then((values) => {
            dispatch({ type: "setHistoricArray", target: values.data.getHistoric });
            dispatch({ type: "updateHistoric" })
        })
        .catch(err => {
            console.log(err);
        });
}

const HistorySearch = (props) => {
    const historicStore = useContext(historicSearchContext);
    const { history } = props;

    //query to get the historic researches of the employe
    useEffect(() => {
        getResearchesAsync();
    }, [historicStore.state.update]);

    const getResearchesAsync = async () => {
        await client.query({
            query: GET_SEARCH,
        })
            .then((values) => {
                historicStore.dispatch({ type: "setHistoricArray", target: values.data.getHistoric });
            })
            .catch((err) => console.log(err));
    }

    //lauches the research (push to page 1 and changes the session storage)
    const launchRequest = async (input, dispatch) => {
        setSessionItemByKeyValue("searchExperts", { search: { ...input }, page: 1 });
        history.push("/experts/page/1");
    }

    //maps all the researches of the employe
    const HistoricMapper = (props) => {
        const { context } = props;
        const { state } = context;
        const expertsPageStore = useContext(expertPageContext)

        if (state.historicsArray && state.historicsArray.length > 0) {
            return (
                <div>
                    {state.historicsArray.map((value, key) => {
                        return (
                            <div key={key} className="d-flex justify-content-between align-items-center mb-3">
                                <h2 className="title-section-black mb-0">{value.name}</h2>
                                <div>
                                    <button onClick={() => deleteResearch(value.name, context)} className="btn btn-civipol-white">{DELETE}</button>
                                    <button onClick={() => launchRequest(value.search, expertsPageStore.dispatch)} className="btn btn-civipol">{SEARCH_ENGINE_BUTTON}</button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <ul className="list-group list-group-flush">
            <li className="list-group-item bg-grey">
                <HistoricMapper context={historicStore} />
            </li>
        </ul>
    );
}

export default withRouter(HistorySearch);