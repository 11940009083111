import React, { useState, useEffect } from "react";
import { searchEngineConstant } from "../../../../constants/searchEngine.constants";
import client from "../../../../ApolloSetup";
import { getSessionItemByKey } from "../../../utils/sessionStorage";
import { emptyObj } from "../../../../stores/searchEngineStore";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const { EXPORT_RESULTS_XLS } = searchEngineConstant;

const initialState = {
    experts: [],
    isLoading: false,
    isError: false,
    update: false
}

const ExportCsvComponent = ({ request }) => {

    let [state, setState] = useState(initialState);

    const formatCsvAsync = ({ experts }) => {
        return new Promise((resolve, reject) => {
            if (experts) {
                let csv = [['Nom', 'Prénom', 'Date de naissance', 'Numéro et nom de rue', 'Ville', 'Pays', 'Code postal', 'Téléphone', 'Email', 'Profil', 'CV']];
                for (let expert in experts) {
                    const expertsWithValues = experts[expert];
                    const cv = expertsWithValues?.supporting_documents?.find(obj => obj.type === "CV");
                    let cvLink = null;
                    if (cv) {
                        cvLink = {
                            v: cv.name,
                            l: { Target: `${process.env.REACT_APP_API_URL}/public/supporting-documents/getFile/${cv.name}.${cv.extension}+${cv.id}` }
                        };
                    }
                    const profil = {
                    v: `PROFIL_${expertsWithValues.firstName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(' ', '_')}_${expertsWithValues.lastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(' ', '_')}`,
                        l: { Target: `${window.location.origin}/experts/expert/${expertsWithValues.id}` }
                    };
                    const test = [
                        expertsWithValues.firstName,
                        expertsWithValues.lastName,
                        expertsWithValues?.birthday?.split('T')[0],
                        expertsWithValues.address_1_number_street,
                        expertsWithValues.address_1_city,
                        expertsWithValues.address_1_country,
                        expertsWithValues.address_1_postal_code,
                        expertsWithValues.tel_mobile,
                        expertsWithValues.email_1,
                        profil,
                        cvLink
                    ];
                    csv.push(test);
                }
                resolve(csv);
            } else reject("error");
        })

    }

    const handleDownLoad = async () => {
        let { search } = getSessionItemByKey("searchExperts");
        let filters = (!search || (JSON.stringify(search) === JSON.stringify(emptyObj))) ? {} : { filters: { ...search } };
        try {
            const response = await client.query({
                query: request,
                variables: { filters: filters }
            });
            let arrayForCsv = await formatCsvAsync(response.data.getExperts);

            const ws = XLSX.utils.json_to_sheet(arrayForCsv);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            FileSaver.saveAs(data, 'export.xlsx');
        } catch (err) {
            setState({ ...state, isError: true, isLoading: false, update: !state.update });
        }
    }

    useEffect(() => {
        return () => setState(initialState);
    }, [state.update]);

    return (
        <div>
            <button onClick={handleDownLoad} className="btn btn-civipol-white" data-toggle="tooltip" data-placement="top" title="Pensez à désactiver votre bloqueur de publicité">
                {EXPORT_RESULTS_XLS}
            </button>
        </div>

    );
}

export default ExportCsvComponent;