import React from "react";
import Select from 'react-select';
import { TranslateContextWrapper } from '../../../../stores/TranslateContext';
import * as langs from 'langs';
import { FormattedMessage } from "react-intl";
import { abilitiesConstants } from "../../../../constants/profile-expert.constants";

const translatePath = "expertForm.abilities.levelsLang.";

const customStyles = {
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px",
    })
}

const {
    LANGUAGE_LANGUE,
    LANGUAGE_LANGUE_LABEL,
    LANGUAGE_LEVEL_LABEL,
    SELECT_PLACEHOLDER,
} = abilitiesConstants;


const handleChange = args => (selected) => {
    const { values, setValues, keyElem, select } = args;
    if (selected.value === "cancel") selected.label = null;
    let obj = {
        ...values.languages[keyElem],
        [select]: selected.value
    };
    values.languages[keyElem] = obj;
    setValues(values);
}

const LangueSelection = (props) => {

    const { setValues, keyElem, values, isError } = props;
    const { languages } = values;
    let arrayOptions = [];
    const langues = langs.names(true);
    let valueLangue = null;
    let label = isError ? "is-invalid-label" : "";

    if (languages && languages[keyElem].language && languages[keyElem].language !== "cancel" && languages[keyElem].language !== "") {
        valueLangue = { label: values.languages[keyElem].language, value: values.languages[keyElem].language };
    }
    arrayOptions = langues.map((v) => ({ value: v, label: v }));
    arrayOptions.unshift({ value: "cancel", label: (props.translate) && props.translate("general.cancelSelection") })
    return (
        <div className="col-12 col-md-6 mb-3 mb-md-0">
            <label className={label}>
                {LANGUAGE_LANGUE_LABEL}
            </label>
            <Select
                styles={customStyles}
                value={valueLangue}
                options={arrayOptions}
                placeholder={SELECT_PLACEHOLDER}
                onChange={handleChange({ values, setValues, keyElem, select: "language" })}
            />
        </div>
    )
}

const LevelSelection = (props) => {

    let arrayOptions = [];
    const levels = ["native", "A1", "A2", "A3", "B1", "B2", "C1", "C2"];
    const { setValues, keyElem, values, isError } = props;
    const { languages } = values;
    let valueLevel = null;
    let label = isError ? "is-invalid-label" : "";

    if (languages && languages[keyElem].level && languages[keyElem].level !== "cancel" && languages[keyElem].level !== "") {
        valueLevel = { label: props.translate(`${translatePath}${values.languages[keyElem].level}`), value: values.languages[keyElem].level };
    }

    arrayOptions = levels.map(v => ({ value: v, label: props.translate(`${translatePath}${v}`) }))
    arrayOptions.unshift({ value: "cancel", label: (props.translate) && props.translate("general.cancelSelection") })
    return (
        <div className="col-12 col-md-6 mb-3 mb-md-0">
            <label className={label}>
                {LANGUAGE_LEVEL_LABEL}
            </label>
            <Select
                styles={customStyles}
                options={arrayOptions}
                value={valueLevel}
                placeholder={SELECT_PLACEHOLDER}
                onChange={handleChange({ values, setValues, keyElem, select: "level" })}
            />
        </div>
    )
}

const Optional = ({ optional }) => {
    if (optional) return <small className="grey-small ml-2"><FormattedMessage id="general.optional" defaultMessage="Not translated" /></small>
    else return null;
}

const LanguageComponent = (props) => {

    const { values, keyElem, errors, optional } = props;
    let isInvalid = "";
    let isErrorLevel = null;
    let isErrorLanguage = null;
    let error = null;
    let required = null;
    if (errors && errors.languages) {
        try {
            error = JSON.parse(errors.languages);
            if (error[keyElem].language === true && error[keyElem].level === true && keyElem === 0) {
                isErrorLevel = error[keyElem].level
                isErrorLanguage = error[keyElem].language
                isInvalid = "is-invalid-label form-label";
                required = <FormattedMessage id="validation.required" defaultMessage="Not Translated" />
            }
            if ((error[keyElem].level === true && error[keyElem].language === false) || (error[keyElem].language === true && error[keyElem].level === false)) {
                isErrorLevel = !error[keyElem].level
                isErrorLanguage = !error[keyElem].language
                isInvalid = "is-invalid-label form-label";
            }
        } catch (err) { }
    }

    if (values) {
        const { languages } = values;

        if (languages && languages[keyElem]) {
            return (
                <div className="d-flex flex-column">
                    <div className="d-flex">
                        <p className={isInvalid}> {LANGUAGE_LANGUE} {keyElem + 1}</p> <p className="is-invalid">{required}</p>
                        <Optional optional={optional} />
                    </div>
                    <div className="row flex-row justify-content-between align-items-end mb-3">
                        <LangueSelection isError={isErrorLevel} values={languages[keyElem].language} translate={props.translateContext.translateMessage} {...props} />
                        <LevelSelection isError={isErrorLanguage} values={languages[keyElem].level} translate={props.translateContext.translateMessage} {...props} />
                    </div>

                </div>
            )
        }
    }
}
export default TranslateContextWrapper(LanguageComponent);