import React, {createContext, useReducer} from "react";

const initialState = {
    employee: {},
}

const EmployeeContext = createContext(initialState);

const reducer = (state, action) => {
    const {target} = action;

    switch (action.type) {
        case "setUser": {
            return {
                [target.key]: target.value
            }
        }
        default: return state
    }
}

const EmployeeProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <EmployeeContext.Provider value={{state, dispatch}}>
            {children}
        </EmployeeContext.Provider>
    )
}

export {EmployeeContext, EmployeeProvider}