import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CollapseContext } from "../../stores/collapseStore";
import { withRouter } from "react-router-dom";

const MenuForm = (props) => {
    const collapseContext = useContext(CollapseContext);
    const { pathname } = props.location
    const [sticky_type, setSticky_type] = useState("");
    
    const handleClick = async (section) => {
        await collapseContext.methods.setCollapse(section, !collapseContext.state[section])
    }
    
    useEffect(() => {
        if (pathname.indexOf("validations") !== -1) {
            setSticky_type("sticky-top-menu-validation");
        } else {
            setSticky_type("sticky-top-menu")
        }
        
    }, []);
    if (sticky_type !== "") {
        return (
            <div className="col-12 col-md-4 ">
                <div id="menu" className={`sticky-top ${sticky_type}`}>
                    <div className={(collapseContext.state.identity) ? "card card-active mb-2" : "card mb-2"} onClick={() => handleClick("identity")}>
                        <div className="card-body px-2 py-2 ">
                            <div className="d-flex align-items-center scrollspy">
                                <span className="icon-number"><b>1</b></span>
                                <span className=" flex-grow-1 mb-0">
                                    <FormattedMessage id="menu.identity" defaultMessage="Not translated" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={(collapseContext.state.missionSearched) ? "card card-active mb-2" : "card mb-2"} onClick={() => handleClick("missionSearched")}>
                        <div className="card-body px-2 py-2">
                            <div className="d-flex align-items-center scrollspy">
                                <span className="icon-number"><b>2</b></span>
                                <span className="flex-grow-1 mb-0">
                                    <FormattedMessage id="menu.missions" defaultMessage="Not translated" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={(collapseContext.state.abilities) ? "card card-active mb-2" : "card mb-2"} onClick={() => handleClick("abilities")}>
                        <div className="card-body px-2 py-2">
                            <div className="d-flex align-items-center scrollspy">
                                <span className="icon-number"><b>3</b></span>
                                <span className="flex-grow-1 mb-0">
                                    <FormattedMessage id="menu.abilities" defaultMessage="Not translated" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={(collapseContext.state.CV) ? "card card-active mb-2" : "card mb-2"} onClick={() => handleClick("CV")}>
                        <div className="card-body px-2 py-2">
                            <div className="d-flex align-items-center scrollspy">
                                <span className="icon-number"><b>4</b></span>
                                <span className="flex-grow-1 mb-0">
                                    <FormattedMessage id="menu.CV" defaultMessage="Not translated" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={(collapseContext.state.individualsInformations) ? "card card-active mb-2" : "card mb-2"} onClick={() => handleClick("individualsInformations")}>
                        <div className="card-body px-2 py-2">
                            <div className="d-flex align-items-center scrollspy">
                                <span className="icon-number"><b>5</b></span>
                                <span className="flex-grow-1 mb-0">
                                    <FormattedMessage id="menu.individualsInformations" defaultMessage="Not translated" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={(collapseContext.state.supporting_documents) ? "card card-active mb-2" : "card mb-2"} onClick={() => handleClick("supporting_documents")}>
                        <div className="card-body px-2 py-2">
                            <div className="d-flex align-items-center scrollspy">
                                <span className="icon-number"><b>6</b></span>
                                <span className="flex-grow-1 mb-0">
                                    <FormattedMessage id="menu.documentToUpload" defaultMessage="Not translated" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else return null
}

export default withRouter(MenuForm);