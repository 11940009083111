import React from 'react';
import Select from 'react-select';
import * as countries from "i18n-iso-countries";
import { abilitiesConstants } from "../../../../constants/profile-expert.constants";

const {
    SIGNIFICANTS_COUNTRIES,
} = abilitiesConstants;


const customStyles = {
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px"
    }),
    menu: (provided, state) => ({
        ...provided,
        position: "absolute",
        zIndex: "10",
        height: "300px",
    }),
    menuList: (provided, state) => ({
        ...provided,
        height: "300px",
        zIndex: "10",
    })
}

const handleChange = ({ setValues, values }, selected) => {
    setValues({
        ...values,
        identity: {
            ...values.identity,
            countries: selected
        }
    })
}

const CountriesSelector = (props) => {

    let countriesArray = Object.values(countries.getNames("fr"));
    for (let i = 0, count = countriesArray.length; i < count; i++) {
        countriesArray[i] = { value: countriesArray[i], label: countriesArray[i] }
    }
    return (
        <div className="mb-3">
            <h2 className="title-section-blue text-uppercase mb-4">{SIGNIFICANTS_COUNTRIES}</h2>
            <div>
                <Select
                    styles={customStyles}
                    options={countriesArray}
                    closeMenuOnSelect={false}
                    isMulti={true}
                    onChange={(selected) => {
                        handleChange(props, selected);
                    }}
                    value={(props.values.identity && props.values.identity.countries) ? props.values.identity.countries : null}
                />

            </div>
        </div>
    )
}

export default CountriesSelector;