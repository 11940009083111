import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { styles, sectionTitleStyle, skillsStyle, selectStyle } from '../styles/styles';
import { messages } from '../../../static/messages/messages';

const ProfessionnaleSituation = (props) => {

    if (props.expert_ability.is_fonctionnaire && !props.expert_ability.is_retired) {
        return (
            <View>
                <Text style={styles.normalBlackTextDin}>Status: Fonctionnaire</Text>
                <Text style={styles.normalBlackTextDin}>Entité: {props.expert_ability?.entity}</Text>
                <Text style={styles.normalBlackTextDin}>Grade: {props.expert_ability?.level}</Text>
            </View>
        );
    } else if (props.expert_ability.is_fonctionnaire && props.expert_ability.is_retired) {
        return (
            <View>
                <Text style={styles.normalBlackTextDin}>Status: Fonctionnaire Retraité</Text>
                <Text style={styles.normalBlackTextDin}>Entité: {props.expert_ability?.entity}</Text>
                <Text style={styles.normalBlackTextDin}>Retraité depuis: {props.expert_ability?.date_retired}</Text>
            </View>
        );
    } else if (!props.expert_ability.is_fonctionnaire && !props.expert_ability.is_retired) {
        if (props.expert_ability.length === 0) {
            return (
                <View>
                    <Text style={styles.normalBlackTextDin}>Non renseigné</Text>
                </View>
            );
        }
        return (
            <View>
                <Text style={styles.normalBlackTextDin}>Status: Indépendant</Text>
                <Text style={styles.normalBlackTextDin}>Contrat souhaité: {props.expert_ability?.is_retired}</Text>
            </View>
        );
    } else {
        return null;
    }
};

const PDFAbilities = (props) => {
    let countries = [];
    try {
        countries = JSON.parse(props?.identity?.countries);
    } catch {
        countries = props?.identity?.countries.length > 0 ? props?.identity?.countries : [];
    }
    return (
        <View >
            <View style={sectionTitleStyle.container}>
                <View style={sectionTitleStyle.sectionTitlePastille}>
                    <Text>3</Text>
                </View>
                <Text style={sectionTitleStyle.sectionTitle}>Compétences</Text>
            </View>
            <View style={styles.sectionProfile}>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.blueSubtitles}>Langues maitrisées</Text>
                    {
                        props?.languages?.map((language, index) => {
                            return (
                                <View key={index} style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                                    <View style={{ width: '50%' }}>
                                        <Text style={styles.lightGreyUppercaseLabel}>Langue {index + 1}</Text>
                                        <Text style={styles.normalBlackText}>{language?.language?.length > 0 ? language?.language : 'Non renseigné'}</Text>
                                    </View>
                                    <View style={{ width: '50%' }}>
                                        <Text style={styles.lightGreyUppercaseLabel}>Niveau {index + 1}</Text>
                                        <Text style={styles.normalBlackText}>{language?.level?.length > 0 ? language?.level : 'Non renseigné'}</Text>
                                    </View>
                                </View>
                            );
                        })
                    }
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.blueSubtitles}>Situation professionnelle</Text>
                    <ProfessionnaleSituation {...props} />
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.blueSubtitles}>Domaines d'expertise</Text>
                    <View style={skillsStyle.skillsContainer}>
                        {props.skills.length === 0 && <Text style={styles.normalBlackText}>Non renseigné</Text>}
                        {props.skills.map((skill) => {
                            return (
                                <View>
                                    <View style={{ ...skillsStyle.skillContainer, marginBottom: 10 }}>
                                        <View style={skillsStyle.tagContainer}>
                                            <Text style={{ color: 'white' }}>{messages.fr.expertForm.abilities.tags[skill.type]}</Text>
                                        </View>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Text style={{ ...styles.normalBlackText, paddingTop: '2%', marginRight: 5 }}>Années d'expérience:</Text>
                                            <View style={selectStyle.selectContainer}>
                                                <Text>{skill.year_experience}</Text>
                                            </View>
                                        </View>
                                        {<Text style={{...styles.normalBlackText, paddingTop: '2%'}}>Diplôme: {skill.diploma ? "OUI" : "NON"}</Text>}
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                </View>
                <View>
                    <Text style={styles.normalBlackTextDin}>Pays où l'expérience est significative: </Text>
                    <View style={{paddingBottom: '3', display: 'flex', flexDirection: 'row', minWidth: '100%', flexWrap: 'wrap'}}>
                        {countries.length > 0 ?
                            countries.map((country, key) => {
                                if (country.label.length > 0) {
                                    return <Text key={key} style={{...styles.normalBlackText, marginRight: 10}}>{`${country.label}`}</Text>;
                                }
                                return null;
                            })
                            :
                            <Text style={styles.normalBlackText}>Non renseigné</Text>
                        }
                    </View>
                </View>
            </View>
        </View>
    )
};

export default PDFAbilities;