import React, { useEffect, useContext } from "react";
import client from "../../../../ApolloSetup";
import { statisticsConstants } from "../../../../constants/home.constants";
import { GET_STATISTICS } from "../../../../queries/getStatistics";
import { statisticContext } from "../../../../stores/statisticStore";

const {
    TITLE
} = statisticsConstants;

const getStatsAsync = ({ dispatch }) => {
    client.query({
        query: GET_STATISTICS
    })
        .then(value => dispatch({ type: "setStats", target: value.data.getStatistics }))
        .catch(err => console.log(err));
}

const DisplayStats = () => {

    const statsStore = useContext(statisticContext);

    useEffect(() => {
        getStatsAsync(statsStore);
    }, []);
    if (statsStore.state.nb_registered_on_week !== null) {
        return (
            <div className="row">
                <div className="col-6 col-xl mb-4 mb-xl-0 card-group">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{statsStore.state.experts_to_evaluate}</h5>
                            <p className="card-text text-uppercase">Offre terminée à évaluer</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-xl mb-4 mb-xl-0 card-group">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{statsStore.state.nb_total_expert}</h5>
                            <p className="card-text text-uppercase">Experts enregistrés au total</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-xl mb-4 mb-xl-0 card-group">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{statsStore.state.offers_not_provided}</h5>
                            <p className="card-text text-uppercase">Offres d'emploi non pourvues</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-xl mb-4 mb-xl-0 card-group">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{statsStore.state.nb_registered_on_week}</h5>
                            <p className="card-text text-uppercase">Experts enregistrés cette semaine</p>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    return (null)
}

const StatisticSection = () => {
    return (
        <section className="activity-monitoring mb-5">
            <h2 className="title-section-blue text-uppercase mb-3">{TITLE}</h2>
            <DisplayStats />
        </section >
    );
}

export default StatisticSection;