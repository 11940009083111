import gql from "graphql-tag";

const GET_ALL_PROJECTS = gql`
    query  getProjectsByPages($page: Int!, $filters: Filter_Projects) {
        getProjectsByPages(page: $page, filters: $filters) {
            projects {
                id
                title {fr}
                primary_image {fr}
                themes {fr id_taxo}
                link {fr}
                chronology
                offers {
                    title {fr}
                    missions {
                        staffed
                    }
                }
            }
            nb_all
        }
    }
`;

const PROJECT_GET_MANY = gql`
    query projectGetMany($options: manyResultsOptions, $projectID: [ID], $title: String) {
        projectGetMany(options: $options, projectID: $projectID, title: $title) {
            data {
                id
                title {fr}
                introduction_text {fr}
                primary_image {fr}
                themes {fr id_taxo}
                link {fr}
                chronology
                project_status {
                    en
                    fr
                }
                offers {
                    soft_delete
                    id
                    id_drupal
                    title { fr en }
                    description_text {fr en}
                    mission_length {fr en parent}
                    geographical_zone {fr en parent}
                    application_expiration
                    introduction_text  {fr en}
                    profile_text {fr en}
                    job_expertise {fr en parent id_taxo}
                    missions {
                        id
                        staffed
                        key_expert
                        date_accepted
                        date_postulat
                        expert {
                            id
                            profile_pic
                            firstName
                            lastName
                            expert_ability {
                                fonctionnaire
                                DG
                            }
                        }
                    }
                }
            }
            meta {
                page
                pagesCount
                resultsCount
                resultsPerPage
                orderedBy
                allElemsCount
            }
        }
    }
`;

const GET_PROJECT_BY_ID = gql`
    query getProjectByID($id: ID!) {
        getProjectByID(id: $id) {
            id
            title {fr}
            introduction_text {fr}
            primary_image {fr}
            themes {fr id_taxo}
            link {fr}
            chronology
            offers {
                id
                soft_delete
                id_drupal
                title { fr en }
                description_text {fr en}
                mission_length {fr en parent}
                geographical_zone {fr en parent}
                application_expiration
                introduction_text  {fr en}
                profile_text {fr en}
                job_expertise {fr en parent id_taxo}
                missions {
                    id
                    staffed
                    key_expert
                    date_accepted
                    date_postulat
                    expert {
                        id
                        profile_pic
                        firstName
                        lastName
                        expert_ability {
                            fonctionnaire
                            DG
                        }
                    }
                }
            }
        }
    }
`;

export { GET_ALL_PROJECTS, GET_PROJECT_BY_ID, PROJECT_GET_MANY };