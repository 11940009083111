import React from "react";
import { Link } from "react-router-dom";

const LinkTo = (props) => {
    if (props.link) {
        const {
            to,
            msge
        } = props.link;

        return (
            <Link to={to} className="btn btn-civipol" >{msge}</Link>
        )
    } else return null;
}
const NoResultTemplate = ({title, text, ...props}) => {

    return (
        <div className="col">
            <div className="card">
                <div className="card-body text-center">
                    <img src={require("../../static/images/not-found/illu_noserp.svg")} className="card-img img-fluid mb-4" alt="no-result" />
                    <h5 className="card-title text-uppercase mb-5">{title}</h5>
                    <p className="card-text mb-3">{text}</p>
                    <LinkTo {...props} />
                </div>
            </div>
        </div>
    );
}

export default NoResultTemplate;