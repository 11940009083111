import React, { useContext } from "react";
import { projectDetailsContext } from "../../../../stores/projectDetailsStore";
import OfferComponent from "./OfferComponent";
const setArray = (obj) => {
    let array = [];
    for (let offer in obj) {
        array.push(obj[offer]);
    }
    return array;
}

const OffersContainer = () => {
    const { state } = useContext(projectDetailsContext);
    if (state.isLoading) return <div>LOADING</div>
    else if (Object.entries(state.offersFromProject).length === 0 && state.offersFromProject.constructor === Object) return <div>IL N'Y A PAS D'OFFRE ENREGISTRÉE</div>
    else {
        let array = setArray(state.offersFromProject);
        return (
            <div>
                {array.map((value, key) => {
                    return (
                        <div key={key}>
                            <OfferComponent value={value} />
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default OffersContainer;