import React from 'react';
import {template} from '../../constants/templates.constants';

const {ERROR_TITLE_DEFAULT, ERROR_TEXT_DEFAULT} = template;

const ErrorTemplate = ({
    custom = {
        title: null,
        text: null
    }
}) => {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body text-center">
                        <img src={require("../../static/images/not-found/illu_noserp.svg")} className="card-img img-fluid mb-4" alt="no-result" />
                        <h5 className="card-title text-uppercase mb-5">{custom?.title || ERROR_TITLE_DEFAULT}</h5>
                        <p className="card-text mb-3">{custom?.text || ERROR_TEXT_DEFAULT}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ErrorTemplate;