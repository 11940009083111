import React from "react";
import QuickActions from "./sections/quickActions/QuickActions";
import ProfilesConsulted from "./sections/profilesConsulted/ProfilesConsulted";
import StatisticSection from "./sections/statisticsSection/StatisticSection";

const Home = () => {
    return (
        <div id="content" className="py-5">
            <div className="container">
                <QuickActions />
                <StatisticSection />
                <hr className="mb-5"/>
                <ProfilesConsulted />
            </div>
        </div>
    )
}

export default Home;