import React, { createContext, useReducer } from "react";

const initialState = {
    projects: null,
    isLoading: true,
    nb_all: null,
}

const projectsContext = createContext(initialState);

const reducer = (state, action) => {
    const { target } = action;

    switch (action.type) {
        case "setProjects": {
            return {
                // ...state,
                projects: target.projects,
                nb_all: target.nb_all,
                isLoading: false
            }
        }
        default: return state
    }
}

const ProjectsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    
    return (
        <projectsContext.Provider value={{ state, dispatch }} >
            {children}
        </projectsContext.Provider>
    );
}

export {ProjectsProvider, projectsContext};