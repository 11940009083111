import React from "react";
import { expertConstants } from "../../../../constants/experts.contants";
import * as moment from "moment";
import { Link } from "react-router-dom";

const getExpertAttributed = (array) => {
    for (let cell in array) {
        if (array[cell].staffed === "Agreed" || array[cell].staffed === "InMission" || array[cell].staffed === "Terminated") {
            return array[cell]
        }
    };
    return null;
}

const ExpertInMission = (props) => {
    const { DATE_ACCEPTED } = expertConstants;
    const { date_accepted, expert } = props;
    if (expert) {
        const { firstName, lastName, profile_pic, id} = expert;

        let divStyle = { backgroundImage: 'url("../../../../../static/images/experts-card/portrait-male-default@2x.png")' };
        if (profile_pic) {
            divStyle = { backgroundImage: 'url(' + process.env.REACT_APP_API_URL + profile_pic.substring(1, profile_pic.length) + ')' };
        }

        return (
            <Link className="text-decoration-none" to={`/experts/expert/${id}`}>
                <div className="card animate">
                    <div className="card-body">
                        <div className="row flex-row justify-content-between align-items-start">
                            <div className="col-12 col-lg-9 mb-4 mb-lg-0">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="rounded-circle profile-picture" style={divStyle || null}>
                                    </div>
                                    <div className="d-flex justify-content-between ml-4  mr-4">
                                        <h4 className="profile-name text-capitalize">{`${firstName.toLowerCase()} ${lastName.toLowerCase()}`}</h4>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-start">
                                        <div className="information-expert mr-4 mr-lg-5">
                                            <label className="text-uppercase">{DATE_ACCEPTED}</label>
                                            <h4 className="mb-0">{moment(date_accepted).format("DD/MM/YYYY")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    } else return <p>COMPTE UTILISATEUR SUPPRIMÉ</p>;
}

const MissionComponent = (props) => {
    let expertInMission = getExpertAttributed(props);
    if (expertInMission) {

        return (
            <div className="row">
                <div className="col mb-4">
                    <h2 className="big-title mb-5">EXPERT ATTRIBUÉ</h2>
                    <ExpertInMission {...expertInMission} />
                </div>
            </div>
        );
    } else return null;
}
export default MissionComponent;