import { translate } from "./translation.constants";

export const projectCardConstant = {
    TITLE: translate("projects.title"),
    START_DATE: translate("projects.projectCard.date_start"),
    END_DATE: translate("projects.projectCard.date_end"),
    SEE_PROJECT_BUTTON: translate("projects.projectCard.seeProject"),
    SEE_DETAILS_BUTTON: translate("projects.projectCard.detailsProject"),
    NO_OFFER: translate("projects.projectCard.noOffer"),
    OFFER_NO_ATTRIB: translate("projects.projectCard.offersNotAttrib"),
    PROJECT_OVER: translate("projects.projectCard.projectOver"),
}

export const projectDetails = {
    ABOUT_TITLE: translate("projects.details.aboutTitle"),
    CHIEF_TITLE: translate("projects.details.chiefTitle"),
    OFFERS_TITLE: translate("projects.details.offersTitle"),
    SEE_ON_WEB: translate("projects.details.project.seeOnWebProject"),
    START_DATE: translate("projects.details.project.startDate"),
    END_DATE: translate("projects.details.project.endDate"),
    DURATION: translate("projects.details.project.duration"),
}
