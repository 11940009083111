import React, { useContext } from "react";
import { notificationContext } from "../../../stores/notificationsStore";
import Toast from 'react-bootstrap/Toast'
import {toastNotifications} from "../../../constants/notifications.constants";
const ToastComponent = () => {

    const { state, dispatch } = useContext(notificationContext);
    const { isOpen, element } = state.toast;

    if (!isOpen) return null;
    else {
        const {TITLE, TEXT} = toastNotifications[element];
        return (
            <Toast
                show={isOpen}
                autohide={true}
                delay={3000}
                onClose={() => dispatch({ type: "closeToast" })}
                className="toast-notif top-right"
            >
                <div className="d-flex px-1 py-1" >
                    <div className="px-2 py-2">
                        <img src={require("../../../static/images/alert-card/illu_profil-edited.svg")} style={{ height: "50px", width: "50px" }} alt="illu_profile" className="img-fluid" />
                    </div>
                    <div className="d-flex flex-column px-2 py-2">
                        <div className="d-flex justify-content-between">
                            <strong className="title text-left text-lg-left">
                            {TITLE}
                            </strong>
                            <button onClick={() => dispatch({ type: "closeToast" })} type="button" className="close" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <p className="mb-0 mt-2">
                        {TEXT}
                        </p>
                    </div>

                </div>
            </Toast>
        )
    }
}

export default ToastComponent;