import React, { useContext } from "react";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { searchEngineContext } from "../../../../../stores/searchEngineStore";
import CalendarDisponibilities from "./CalendarDisponibilities/CalendarDisponibilities";

const MissionDuration = (engineStore) => {
    const missionsDuration = [
        { value: "", label: "Indifférent" },
        { value: "short", label: "Court terme < 1 mois" },
        { value: "medium", label: "Moyen terme > 1 mois" },
        { value: "long", label: "Long terme > 1 an" },
    ];

    return (
        <div className="form-group">
            <label className="search-title" htmlFor="mission-duration">Durée mission</label>
            <SelectComponent {...engineStore} options={missionsDuration} name="mission_duration" isMulti />
        </div>
    )
}

const PublicFunction = (engineStore) => {

    const options = [
        { value: "INDIFFERENT", label: "Indifférent" },
        { value: "YES", label: "Oui" },
        { value: "YES_RETIRED", label: "Oui Retraité" },
        { value: "NO", label: "Non" }
    ]

    return (
        <div className="form-group">
            <label className="search-title" htmlFor="public-function">Fonction publique</label>
            <SelectComponent {...engineStore} name="public_function_status" options={options} />
        </div>
    )
}

const PublicFunctionDuration = (engineStore) => {
    const options = [
        { value: "INDIFFERENT", label: "Indifférent" },
        { value: "2", label: "2 ans" },
        { value: "5", label: "5 ans" },
        { value: "8", label: "8 ans" },
        { value: "8P", label: "Plus de 8 ans" },
    ];
    return (
        <div className="form-group">
            <label className="search-title" htmlFor="date_retired">Depuis</label>
            <SelectComponent {...engineStore} name="date_retired" options={options} disabled={engineStore?.state?.servicesDetails?.publicFunction !== 'YES_RETIRED'} />
        </div>
    )
}

const FirstSection = () => {
    const engineStore = useContext(searchEngineContext);

    return (
        <div className="row mb-4">
            <div className="col">
                <div className="row mb-4">
                    <div className="col-6 col-xl-4">
                        <MissionDuration {...engineStore} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xl-4">
                        <PublicFunction {...engineStore} />
                    </div>
                    <div className="col-6 col-xl-4">
                        <PublicFunctionDuration {...engineStore} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FirstSection;