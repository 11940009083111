import React from "react";
import { quickActionsHome } from "../../../../constants/quickActions.constants";
import {Link} from "react-router-dom";

const { TITLE, SEARCH_EXPERT, SEARCH_BLOCKED_EXPERT } = quickActionsHome;

const QuickActions = () => {

    return (
        <section className=" col quick-actions mb-5">
            <h2 className="title-section-blue text-uppercase mb-3">{TITLE}</h2>
            <div className="row flex-row align-items-center justify-content-between section-button">
                <div disabled className="col-6 mb-4">
                    <Link to="/experts/page/1" className="btn btn-block btn-civipol-white">{SEARCH_EXPERT}</Link>
                </div>
                <div className="col-6 mb-4">
                    <Link to="/experts/blocked_account/1" className="btn btn-block btn-civipol-white">{SEARCH_BLOCKED_EXPERT}</Link>
                </div>
            </div>
            <hr className="mb-4" />
        </section>
    );

}

export default QuickActions;