import React, { useContext } from "react";
import { Accordion } from "react-bootstrap";
import { searchEngineConstant } from "../../constants/searchEngine.constants";
import InputSearch from "./sections/InputSearch/InputSearch";
import HistorySearch from "./sections/historySearch/HistorySearch";
import AdvancedSearch from "./sections/advancedSearch/AdvancedSearch";
import client from "../../ApolloSetup";
import { searchEngineContext } from "../../stores/searchEngineStore";
import { expertPageContext } from "../../stores/expertsPageStore";
import RegisterSearch from "./sections/RegisterSearch/RegisterSearch";
import ReinitSearch from "./sections/ReinitSearch/ReinitSearch";
import { GET_EXPERTS_BY_PAGE, GET_ALL_EXPERTS_BY_SEARCH } from "../../queries/getExperts";
import Suggestions from '../quickActions/quickActionsExperts/sections/Suggestions';
import { withRouter } from "react-router-dom";
import { getSessionItemByKey, setSessionItemByKeyValue } from "../utils/sessionStorage";
import { emptyObj } from "../../stores/searchEngineStore";
import ExportCsvComponent from "./sections/export/ExportComponent";

const {
    SEARCH_TITLE,
    ADVANCED_SEARCH_TITLE,
    HISTORY_SEARCH,
    OFFER_SUGGESTION
} = searchEngineConstant;

/**
 * Boutton de recherche
 */
const LaunchResearchButton = ({ history }) => {

    const searchStore = useContext(searchEngineContext);
    const expertPageStore = useContext(expertPageContext);
    /**
     * Lorsqu'on clique sur recherche et qu'on n'est pas sur la premiere page on redirige et on set le session storage sur page 1
     */
    const pushToPageOne = () => {
        history.push("/experts/page/1");
        let item = getSessionItemByKey("searchExperts");
        item = { ...item, page: 1, search: searchStore.state };
        setSessionItemByKeyValue("searchExperts", item);
    }

    /**
     * Execute la recherche
     */
    const executeSearch = async (input, { dispatch, state }) => {
        const emptyObj = {
            inputSearch: "",
            mission_duration: "",
            nationality: "",
            countries: "",
            language: {
                langue: "",
                langue_level: "",
            },
            skills: [],
            servicesDetails: {},
            disponibility: "",
            display_disponible: false,
        };
        let { page, search } = getSessionItemByKey("searchExperts");
        let filters = (JSON.stringify(emptyObj) === JSON.stringify(search) || !search) ? {} : { filters: { ...search } };
        await client.query({
            query: GET_EXPERTS_BY_PAGE,
            variables: { page: parseInt(page), filters: filters }
        })
            .then((values) => {
                if (values.data.getExperts === null) throw new Error();
                if (!values.errors) {
                    dispatch({ type: "setExpertsResearch", target: values.data.getExperts });
                } else {
                    dispatch({ type: "setError", target: "ERROR" });
                }
            })
            .catch(err => dispatch({ type: "setError", target: "ERROR" }));
    }

    return (
        <div className="row">
            <div className="col text-right">
                <button
                    onClick={() => {
                        pushToPageOne();
                        executeSearch(searchStore.state, expertPageStore);
                    }}
                    type="button"
                    className="btn btn-civipol">
                    Lancer
                    </button>
            </div>
        </div>
    )
}

const SearchEngine = (props) => {
    const expertsContext = useContext(expertPageContext);
    const { nb_all, isResearch, error } = expertsContext.state;
    let titleExperts = `${(nb_all) ? nb_all : ""} Experts ${(isResearch === true) ? "trouvés" : "enregistrés"}`;
    const { history } = props;

    if (error) titleExperts = "Pas d'expert trouvé pour cette recherche";
    return (
        <div>
            <section className="search mb-5">
                <InputSearch />
                <div id="advanced-history-search">
                    <Accordion>
                        <Accordion.Toggle eventKey="advanced-search" className="btn btn-arrow"><u>{ADVANCED_SEARCH_TITLE}</u></Accordion.Toggle>
                        <Accordion.Toggle eventKey="history-search" className="btn btn-arrow"><u>{HISTORY_SEARCH}</u></Accordion.Toggle>
                        <div className="accordion-group mt-4">
                            <Accordion.Collapse eventKey="advanced-search" id="advanced-search">
                                <div className="bg-grey px-4 py-4">
                                    <form className="mb-0">
                                        <AdvancedSearch />
                                        <LaunchResearchButton history={history} />
                                    </form>
                                </div>
                            </Accordion.Collapse>
                            <Accordion.Collapse eventKey="history-search" id="history-search">
                                <HistorySearch />
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            </section>
            <header className="mb-4">
                <div className="d-flex flex-column flex-lg-row justify-content-center align-items-around align-items-lg-center mb-2">
                    <RegisterSearch />
                    <ReinitSearch />
                </div>
                <div className='d-flex mb-2 justify-content-center'>
                    <ExportCsvComponent request={GET_ALL_EXPERTS_BY_SEARCH} />
                </div>
                <div className='d-flex w-100 mb-5'>

                    {(expertsContext.state.experts && expertsContext.state.experts.length > 0) &&
                        <div className='row flex-fill align-items-center' style={{ backgroundColor: 'rgb(247, 247, 247)' }}>
                            <div className='col p-5'>
                                <label className="mb-2">{OFFER_SUGGESTION}</label>
                                <Suggestions id_experts={expertsContext.state.experts.map(expert => expert.id)} />
                            </div>
                        </div>
                    }
                </div>
                <h2 className="title-section-black mb-0">{titleExperts}</h2>
            </header>
        </div>
    );
}

export default withRouter(SearchEngine);