import { translate } from "./translation.constants";

export const candidaturesProjectsDetailS = {
    SEE_PROFILE: translate("offers.seeProfile"),
    ACCOUNT_DELETED: translate("offers.accountDeleted"),
    SEARCH_EXPERT: translate("offers.searchExpert"),
    DATE_CANDIDATURE_ACCEPTED: translate("offers.dateCandidatureAccepted"),
    KEY_EXPERT: translate("offers.keyExpert"),
    KEY_EXPERT_IN_MISSION: translate("offers.keyExpertInMission"),
    EXPERT_IN_MISSION: translate("offers.expertInMission"),
    NO_CANDIDATURE: translate("offers.noCandidature"),
    MISSION_DURATION: translate("offers.missionDuration"),
    CANDIDATURE_LIMIT: translate("offers.candidatureLimit"),
    GEO_ZONE: translate("offers.geoZone"),
    SEE_OFFER: translate("offers.seeOffer"),
}
