import React, { useEffect, useContext } from 'react';
import { Page, Document, PDFViewer, BlobProvider } from '@react-pdf/renderer';
import PDFIdentity from './identity/PDFIdentity';
import client from "../../ApolloSetup";
import LOG_CONSULTATION from "../../mutations/logsConsultation";
import { GET_EXPERT_BY_ID, GET_EXPERT_PDF } from "../../queries/getExperts";
import { ExpertDetailsContext } from "../../stores/expertsDetailsStore";
import { Loader } from "../loader/Loader"
import { styles } from './styles/styles';
import PDFMissionSearched from './missionSearched/PDFMissionSearched';
import PDFAbilities from './abilities/PDFAbilities';
import PDFCV from './cv/PDFCV';
import PDFIndividualsInformations from './individualsInformations/PDFIndividualsInformations';
import PDFSupportingDocuments from './supportingDocuments/PDFSupportingDocuments';

const getExpert = async (id, expertContext) => {
    await client.mutate({
        mutation: LOG_CONSULTATION,
        variables: {
            id_expert: id
        }
    }).catch(err => console.log(err));
    await client.query({
        query: GET_EXPERT_BY_ID,
        variables: { expertID: id },
        fetchPolicy: 'network-only',
    }).then(value => expertContext.methods.setExpert(value.data.getExpert)).catch(err => console.log(err));
};

const sendToExpert = async (id, blob) => {
    await client.query({
        query: GET_EXPERT_PDF,
        variables: {
            expertID: id,
            document: blob
        }
    })
};

const PDFComponent = ({ expertContext, env, isExport = false }) => {

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <PDFIdentity {...expertContext.state.expert} env={env} />
                <PDFMissionSearched {...expertContext.state.expert} />
            </Page>
            <Page size="A4" style={styles.page}>
                <PDFAbilities {...expertContext.state.expert} />
            </Page>
            <Page size="A4" style={styles.page}>
                <PDFCV {...expertContext.state.expert} env={env} />
            </Page>
            {
                expertContext.state.expert.wasAcceptedToMission &&
                <Page size="A4" style={styles.page}>
                    <PDFIndividualsInformations {...expertContext.state.expert} env={env} isExport={isExport} />
                    <PDFSupportingDocuments {...expertContext.state.expert} env={env} isExport={isExport} />
                </Page>
            }
        </Document>
    );
};

const PDFExport = (props) => {

    const { id } = props.match.params;
    const expertContext = useContext(ExpertDetailsContext);
    const env = process.env;
    useEffect(() => {
        getExpert(id, expertContext);
    }, []);


    if (expertContext.state.isLoading) {
        return <Loader class="center" />;
    } else if (expertContext.state.expert !== null) {
        return (
            <div className="h-100 w-100">
                <BlobProvider document={PDFComponent({ expertContext, env, isExport: true })}>
                    {({ blob, url, loading, error, }) => {
                        if (blob) {
                            return <button className='btn btn-civipol-white' onClick={() => sendToExpert(id, blob,)}>ENVOYER LE PROFIL À L'EXPERT</button>
                        } else {
                            return null;
                        }
                    }}
                </BlobProvider>
                <PDFViewer className="h-100 w-100">
                    <PDFComponent expertContext={expertContext} env={env} />
                </PDFViewer>

            </div >
        );
    } else {
        return <div>ERROR</div>;
    }
};

export default PDFExport;