import React from "react";
import ProjectContainer from "./sections/ProjectsContainer";

const Projects = () => {
    return (
        <div id="content" className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <ProjectContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects;