import gql from "graphql-tag";

const SET_EVALUATION_EMPLOYE = gql`
    mutation registerEvaluationExpert($id_expert: ID! $id_mission: ID!, $id_evaluation: ID! $infos: JSON){
        registerEvaluationExpert(id_expert: $id_expert, id_mission: $id_mission, id_evaluation: $id_evaluation, infos: $infos){
            id
        }
    }
`;


export { SET_EVALUATION_EMPLOYE };
