import React from "react";
import { withRouter, Link } from "react-router-dom";
import { getSessionItemByKey, setSessionItemByKeyValue } from "../utils/sessionStorage";


const PaginationComponent = (props) => {

    const nb_all = (props?.context?.state?.nb_all) ? props.context.state.nb_all : props.nb_all;
    const { keySearchStorage } = props;
    let { page } = getSessionItemByKey(keySearchStorage) || parseInt(props.match.params) || props;
    let maxPage = Math.ceil(nb_all / 10);

    const handleClick = (newPage) => {
        let item = getSessionItemByKey(keySearchStorage);
        item = { ...item, page: newPage };
        setSessionItemByKeyValue(item);
        window.scrollTo(0, 0);
    }

    const PrevButton = () => {
        return (
            <li className={(page > 1) ? "d-flex align-items-center page-item previous" : "d-flex align-items-center page-item previous disabled"}>
                <Link onClick={() => handleClick(page - 1)} className="page-link page-link-arrow previous" to={`${page - 1}`}></Link>
            </li>
        )
    }

    const NexButton = () => {
        return (
            <li className={(parseInt(page) === maxPage) ? " d-flex align-items-center page-item next disabled" : "d-flex align-items-center page-item next"}>
                <Link onClick={() => {handleClick(parseInt(page) + 1)}} className="page-link page-link-arrow next" to={`${parseInt(page) + 1}`}></Link>
            </li>
        )
    }

    const PaginationButtons = () => {
        let table = [];
        for (let i = 1; i <= maxPage; i++) {
            table.push(
                <li key={i} className={(parseInt(props.match.params.page) === i) ? "page-item active" : "page-item"}>
                    <Link onClick={() => handleClick(i)} className="page-link" to={`${i}`}>{i}</Link>
                </li>
            )
        }
        return table;
    }

    if (maxPage > 1) {
        return (
            <footer className="mt-5">
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                        <PrevButton />
                        <PaginationButtons />
                        <NexButton />
                    </ul>
                </nav>
            </footer>
        )
    } else return null;
}
export default withRouter(PaginationComponent);