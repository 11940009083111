import React, { createContext, useReducer } from "react";

const initialState = {
    alert: {
        element: null,
        isOpen: false,
        callback: null,
        title: null,
        isCancel: true,
        custom: null
    },
    toast: {
        element: null,
        isOpen: false
    }
}

const notificationContext = createContext(initialState);

const reducer = (state, action) => {
    const { target } = action;
    switch (action.type) {
        case "setAlert": {
            return {
                ...state,
                alert: {
                    isCancel: target?.isCancel || false,
                    element: target.element,
                    custom: target?.custom,
                    isOpen: true,
                    callback: target.callback,
                }
            }
        }
        case "closeAlert": {
            return {
                ...state,
                alert:{
                    ...state.alert,
                    element: null,
                    isOpen: false,
                    callback: null,
                }
            }
        }
        case "setToast": {
            return {
                ...state,
                toast: {
                    element: target.element,
                    isOpen: true,
                }
            }
        }
        case "closeToast": {
            return {
                ...state,
                toast: {
                    element: null,
                    isOpen: false,
                }
            }
        }
        case "setTitleHistoric": {
            return {
                ...state,
                alert: {
                    ...state.alert,
                    title: target
                }
            }
        }
        default: {
            return state;
        }
    }
}

const NotificationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <notificationContext.Provider value={{ state, dispatch }} >
            {children}
        </notificationContext.Provider>
    )
}

export { NotificationProvider, notificationContext };