import React, { Fragment, useState } from "react";
import { Form } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { FormattedMessage } from 'react-intl';

const PhoneInput = ({ section, name, facultatif, label, ...props }) => {
    const { errors } = props;
    const [countryFlag, setCountryFlag] = useState(localStorage.lang);
    const handleChange = (value, country) => {
        setCountryFlag(country.countryCode);
        props.setValues({
            ...props.values,
            [section]: {
                ...props.values[section],
                [name]: value
            }
        })
    }
    return (
        <Fragment>
            <div className="mb-4">
                <Form.Label className={errors[section] && errors[section][name] ? "is-invalid-label" : null}><FormattedMessage id={label} defaultMessage="Not translated" /> </Form.Label>
                {facultatif ? <small className="ml-2 text-muted"><FormattedMessage id="general.optional" defaultMessage="Not translated" /></small> : null}
                <ReactPhoneInput
                    enableSearchField={true}
                    defaultCountry={countryFlag}
                    value={props.values[section][name]}
                    onChange={(value, country) => {
                        handleChange(value, country)
                    }}
                    buttonStyle={{
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#efefef'
                    }}
                    containerStyle={{
                        width: '100%',
                        height: '60px',

                    }}
                    inputStyle={{
                        width: 'inherit',
                        height: '60px',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#efefef'
                    }}
                />
                {errors[section] && errors[section][name] && <p className="is-invalid">{errors[section][name]}</p>}
            </div>
        </Fragment>
    )
}

export default PhoneInput;
