import {translate} from "./translation.constants";

const path = "evaluationExpert.";
const pathNotation = path + "notations.";
const pathGeneralNotation = path + "generalNotations.";

export const evaluationConstants = {
    TITLE_PAGE : translate(path + "title"),
    PROJECT_TITLE : translate(path + "projectTitle"),
    OFFER_TITLE : translate(path + "offerTitle"),
    DATE_TITLE : translate(path + "dateEvaluationTitle"),
    COMPETENCES_TITLE : translate(path + "competencesTitle"),
    APTITUDES_TITLE : translate(path + "aptitudesTitle"),
    GENERAL_TITLE : translate(path + "generalTitle"),
    CHIEF_POLE : translate(path + "chiefPole"),
    RESPONSABLE_PROJECT : translate(path + "projectResponsable"),
    END_PERIOD : translate(path + "endOfPeriod"),
    END_PERIOD_RENIEW : translate(path + "endOfPeriodReniew"),
    REGISTER: translate(path + "registerButton"),
    PROJECT_CODE: translate(path + "projectCode"),
    COUNTRY: translate(path + "country"),
    LINK_TO_OFFER: translate(path + "linkToOffer"),
    MISSION_INFORMATION: translate(path + "missionInformationTitle")
}

export const notationsConstants = {
    NON_APPLICABLE : translate(pathNotation + "nonApplicable"),
    NOTIONS : translate(pathNotation + "notions"),
    CONFIRMED : translate(pathNotation + "confirmed"),
    MAITRISE : translate(pathNotation + "maitrise"),
    EXPERT : translate(pathNotation + "expert"),
}

export const generalNotationsConstants = {
    AMELIORABLE : translate(pathGeneralNotation + "ameliorable"),
    CONFORM : translate(pathGeneralNotation + "conform"),
    DEPASSE : translate(pathGeneralNotation + "depasse"),
}

