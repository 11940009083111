import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TranslateContextWrapper } from '../../../../stores/TranslateContext';
import LanguageComponent from "./LanguageComponent";

const Error = ({errors}) => {
    if (errors && errors.languages === "validation.selectAtLeastOne") {
        return <p className="is-invalid-label form-label"><FormattedMessage id={errors.languages} defaultMessage="Not Translated" /> </p>
    } else return null;
}

const LanguageSelection = (props) => {
    const { translateContext } = props;

    return (
        <div>
            <Error {...props} />
            <LanguageComponent keyElem={0} {...translateContext} {...props} />
            <LanguageComponent optional keyElem={1} {...translateContext} {...props} />
            <LanguageComponent optional keyElem={2} {...translateContext} {...props} />
        </div>
    )
}

export default TranslateContextWrapper(LanguageSelection)