import React, { useContext } from "react";
import { notificationContext } from "../../../stores/notificationsStore";
import SweetAlert from "react-bootstrap-sweetalert";
import { alertNotifications } from "../../../constants/notifications.constants";
import { generalConstants } from "../../../constants/general.constants";

const Alert = () => {
    const { state, dispatch } = useContext(notificationContext);
    const { element, isOpen, custom } = state.alert;
    const handleConfirm = () => {
        if (state.alert.callback) {
            state.alert.callback();
        }
        dispatch({ type: "closeAlert" })
    }
    if (!isOpen) return null;
    else {
        if (alertNotifications[element]) {
            const { TITLE, TEXT } = alertNotifications[element];
            const { YES, NO } = generalConstants;

            return (
                <SweetAlert
                    title={TITLE}
                    onConfirm={handleConfirm}
                    onCancel={() => dispatch({ type: "closeAlert" })}
                    confirmBtnTex={YES}
                    cancelBtnText={NO}
                    closeOnClickOutside={true}
                    showCancel={state.alert.isCancel}
                    confirmBtnCssClass="btn btn-civipol"
                    cancelBtnCssClass="btn btn-civipol-white mr-2"
                >
                    {custom ? custom : TEXT}
                </SweetAlert>
            )
        } else return null;
    }
}

export default Alert;