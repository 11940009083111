import React, { useEffect, useContext, useState } from "react";
import { GET_MISSIONS_BY_EXPERT_ID } from "../../queries/getMissions";
import { GET_PROJECT_BY_ID } from '../../queries/getProjects';
import client from "../../ApolloSetup";
import MenuMissions from "../menu/MenuMission";
import CandidaturesContainer from "./sections/candidatures/CandidaturesContainer";
import MissionsContainer from "./sections/missions/MissionsContainer";
import { withRouter } from "react-router-dom";
import { missionsContext } from "../../stores/missionsStore";

const dispatchInMissionCandidatures = (datas, dispatch) => {
    if (datas) {
        const missions = {
            missionsAcceptedButNotStart: [],
            inMission: [],
            missionTerminated: [],
        };
        const candidatures = {
            postule: [],
            rejected: [],
        };
        for (let i = 0; i < datas.length; i++) {

            if (datas[i].staffed === "Postule") {
                candidatures.postule.push(datas[i]);
            } else if (datas[i].staffed === "Rejected") {
                candidatures.rejected.push(datas[i]);
            } else if (datas[i].staffed === "Agreed") {
                missions.missionsAcceptedButNotStart.push(datas[i]);
            } else if (datas[i].staffed === "InMission") {
                missions.inMission.push(datas[i]);
            } else if (datas[i].staffed === "Terminated" || datas[i].staffed === "Untied") {
                missions.missionTerminated.push(datas[i]);
            }
        }
        dispatch({ type: "setMissions", target: missions });
        dispatch({ type: "setCandidatures", target: candidatures });
    }
}

const getMissions = async (id, { dispatch }, setResponse) => {
    await client.query({
        query: GET_MISSIONS_BY_EXPERT_ID,
        variables: { expertID: id }
    })
        .then((values) => { dispatchInMissionCandidatures(values.data.getMissionsOfUser, dispatch); setResponse(values) })
        .catch((err) => console.log(err));
}

const MissionsSection = (props) => {
    const [response, setResponse] = useState(null);
    const { id } = props.match.params;
    const missionsStore = useContext(missionsContext);
    const { update } = missionsStore.state;

    useEffect(() => {
        getMissions(id, missionsStore, setResponse);
    }, [update]);

    return (
        <div className="row scrollspy">
            <MenuMissions />
            <div className="col-12 col-md-8 mb-6 pt-6 menu-padding">
                <CandidaturesContainer />
                <MissionsContainer missions={response} />
            </div>
        </div>
    )
}

export default withRouter(MissionsSection);