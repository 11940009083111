import {translate} from "./translation.constants";

export const candidaturesConstants = {
    TITLE: translate("missionsPage.candidatures.title"),
    DATE: translate("missionsPage.candidatures.date"),
    BUTTON_SEE_OFFER: translate("missionsPage.candidatures.buttonSeeOffer"),
    BUTTON_VALIDATION: translate("missionsPage.candidatures.buttonValidation"),
    BUTTON_REJECTION: translate("missionsPage.candidatures.buttonRejection"),
    TITLE_POSTULE: translate("missionsPage.candidatures.postuleTitle"),
    TITLE_REJECT: translate("missionsPage.candidatures.rejectedTitle"),
    DATE_REJECT: translate("missionsPage.candidatures.dateReject"),
    REJECT_SELECTION_BUTTON: translate("missionsPage.candidatures.rejectSelectionButton"),
    REJECT_ALL_LABEL: translate("missionsPage.candidatures.rejectAllLabel"),
    REJECT_SELECT_LABEL: translate("missionsPage.candidatures.rejectSelectLabel"),
}

export const missionsConstants = {
    TITLE: translate("missionsPage.missions.title"),
    DATE_START: translate("missionsPage.missions.date_start"),
    DATE_END: translate("missionsPage.missions.date_end"),
    MISSION_ACCEPTED: translate("missionsPage.missions.missionAgreed"),
    IN_MISSION: translate("missionsPage.missions.InMission"),
    FINISHED_MISSION: translate("missionsPage.missions.finishedMission"),
    BUTTON_SEE_OFFER: translate("missionsPage.missions.buttonSeeOffer"),
    EVALUATE_EXPERT: translate("missionsPage.missions.buttonEvaluate"),
}

export const notificationsConstants = {
    TITLE_CANDIDATURES: translate("missionsPage.notifications.candidatures.title"),
    BUTTON_CANDIDATURES: translate("missionsPage.notifications.candidatures.button"),
    TITLE_MISSIONS: translate("missionsPage.notifications.missions.title"),
    BUTTON_MISSIONS: translate("missionsPage.notifications.missions.button"),
}