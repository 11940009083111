import { createUploadLink } from "apollo-upload-client";
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: "include",
})

//vanilla js logout function
const logoutManually = async () => {
    await client.mutate({
        mutation: gql`mutation{
            logoutEmploye{
                id
            }
        }
    `});
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace(`/signin`);
}

// Then the error link (for now it just logs the error)
const errorLink = onError(({ response, graphQLErrors, networkError, invalidArgs }) => {
    if (graphQLErrors) {
        graphQLErrors.map(async ({ message, locations, path, extensions }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,);

            if (extensions && extensions.code) {
                if (extensions.code === "UNAUTHENTICATED") {
                    await logoutManually();
                } else if (extensions.code === "UNAUTHORIZED") {
                    window.location.replace(`/home`);
                }
            }
        }
        );
    }
    if (invalidArgs) {
        console.log('[ARGS ERROR]', invalidArgs)
    }

    if (networkError) {
        console.log("[network error] => ", networkError);
        logoutManually();
    }
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}


// Final link
const link = ApolloLink.from([
    errorLink,
    httpLink,
]);

// Create the Apollo client to make GraphQL requests
const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
});

export default client;
