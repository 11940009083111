import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { styles, sectionTitleStyle } from '../styles/styles';
import * as moment from 'moment';

const PDFIdentity = (props) => {
    return (
        <View>
            <View style={{ marginBottom: '3%' }}>
                <Text style={styles.profileTitle}>{props.identity.firstName} {props.identity.lastName}</Text>
                <Text style={styles.normalBlackText}>PROFIL</Text>
            </View>
            <View style={sectionTitleStyle.container}>
                <View style={sectionTitleStyle.sectionTitlePastille}>
                    <Text>1</Text>
                </View>
                <Text style={sectionTitleStyle.sectionTitle}>Identité</Text>
            </View>
            <View style={styles.sectionProfile}>
                <Text style={styles.titleUser}>{props.identity.firstName} {props.identity.lastName}</Text>
                <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}> 
                    <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <Text style={styles.lightGreyUppercaseLabel}>Date de naissance</Text>
                        <Text style={styles.normalBlackText}>{props?.identity?.birthday?.split('T')[0]}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <Text style={styles.lightGreyUppercaseLabel}>Nationalité(s)</Text>
                        <Text style={styles.normalBlackText}>{props?.identity?.countries[0]?.value || 'Non renseignéé'}</Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text style={styles.blueSubtitles}>Coordonnées</Text>
                    <Text style={styles.blackSubtitles}>Adresse principale</Text>
                    <View style={{ marginBottom: 15 }}>
                        <Text style={styles.greyLabel}>Numéro et nom de rue</Text>
                        <Text style={styles.normalBlackText}>{props?.identity?.address_1_number_street || 'Non renseignée'}</Text>
                    </View>
                    <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Code postal</Text>
                            <Text style={styles.normalBlackText}>{props?.identity?.address_1_postal_code || 'Non renseigné'}</Text>
                        </View>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Ville</Text>
                            <Text style={styles.normalBlackText}>{props?.identity?.address_1_city || 'Non renseignée'}</Text>
                        </View>
                    </View>
                    <View style={{ marginBottom: 15 }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Pays</Text>
                            <Text style={styles.normalBlackText}>{props?.identity?.address_1_country || 'Non renseigné'}</Text>
                        </View>
                    </View>
                    <Text style={styles.blackSubtitles}>Contact</Text>
                    <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Téléphone 1</Text>
                            <Text style={styles.normalBlackText}>{props?.identity?.tel_mobile || 'Non renseigné'}</Text>
                        </View>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Téléphone 2</Text>
                            <Text style={styles.normalBlackText}>{props?.identity?.tel_fix || 'Non renseigné'}</Text>
                        </View>
                    </View>
                    <View style={{ marginBottom: 15 }}>
                        <Text style={styles.greyLabel}>Adresse mail</Text>
                        <Text style={styles.normalBlackText}>{props.identity.email_1}</Text>
                    </View>
                </View>
            </View>
        </View>
    );

};

export default PDFIdentity;