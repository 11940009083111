import React, { useContext } from "react";
import { missionsContext } from "../../../../stores/missionsStore";
import { missionsConstants } from "../../../../constants/missions.constants";
import MissionComponent from "./MissionComponent";
import NotificationMissionsTemplate from "../notifications/NotificationsMissionsTemplate";
import { TranslateContextWrapper } from "../../../../stores/TranslateContext";
import { Element } from 'react-scroll'

const { TITLE, MISSION_ACCEPTED, IN_MISSION, FINISHED_MISSION } = missionsConstants;

const AgreedMapper = (props) => {
    const { missions } = props;
    if (missions.length > 0) {
        return (
            <div className="mb-4">
                <h2 className="title-section-blue text-uppercase mb-4">{MISSION_ACCEPTED}</h2>
                {missions.map((value, key) => {
                    return (
                        <MissionComponent key={key} value={value} />

                    )
                })}
            </div>
        )
    } else return null;
}

const InMissionMapper = (props) => {
    const { missions } = props;
    if (missions.length > 0) {
        return (
            <div className="mb-4">
                <h2 className="title-section-blue text-uppercase mb-4">{IN_MISSION}</h2>
                {missions.map((value, key) => {
                    return (
                        <MissionComponent key={key} value={value} />
                    )
                })}
            </div>
        )
    } else return null;
}

const FinishedMissionMapper = (props) => {
    const { missions } = props;
    if (missions.length > 0) {
        return (
            <div className="mb-4">
                <h2 className="title-section-blue text-uppercase mb-4">{FINISHED_MISSION}</h2>
                {missions.map((value, key) => {
                    return (
                        <MissionComponent key={key} value={value} />
                    )
                })}
            </div>
        )
    } else return null;
}

const MissionsContainer = (props) => {

    const { state } = useContext(missionsContext);
    const { missions } = state;
    const Title = () => {

        if (missions && missions.length > 0) {
            let values = {
                nb_missions: missions.length,
                s: (missions.length > 1) ? "s" : "",
            }

            let text = props.translateContext.translateMessage("missionsPage.missions.text", "", values);
            return (
                <div className="row mb-4">
                    <div className="col">
                        {text}
                    </div>
                </div>
            )
        } else return null;
    }


    return (
        <Element name="2">
            <div className="d-flex flex-column mt-2">
                <div className="row">
                    <div className="col mb-4">
                        <h2 className="big-title mb-3">{TITLE}</h2>
                    </div>
                </div>
                {(missions && (missions.inMission.length > 0 || missions.missionTerminated.length > 0 || missions.missionsAcceptedButNotStart.length > 0)) ?
                    <div>
                        <Title />
                        <div className="row mb-4">
                            <div className="col">
                                <AgreedMapper missions={missions.missionsAcceptedButNotStart} />
                                <InMissionMapper missions={missions.inMission} />
                                <FinishedMissionMapper missions={missions.missionTerminated} />
                            </div>
                        </div>
                    </div>
                    : <NotificationMissionsTemplate type="missions" />
                }
            </div>
        </Element >
    )
}

export default TranslateContextWrapper(MissionsContainer);