import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import $ from 'jquery';
/**
 * store folder
 */
import { EmployeeProvider } from "./stores/employeeStore";
import { ExpertPageProvider } from "./stores/expertsPageStore";
import { ExpertDetailsProvider } from "./stores/expertsDetailsStore";
import { CollapseProvider } from "./stores/collapseStore";
import TranslateProvider from "./stores/TranslateContext";
import { NotificationProvider } from "./stores/notificationsStore";
import { MissionsProvider } from "./stores/missionsStore";
import { LogsProvider } from "./stores/logsStore";
import { ProjectsProvider } from './stores/projectsStore';
import { ProjectDetailsProvider } from './stores/projectDetailsStore';
import { SearchEngineProvider } from './stores/searchEngineStore';
import { HistoricSearchProvider } from './stores/historicSearchStore';
import { OfferDetailsProvider } from './stores/detailsOfferStore';
import { EvaluationProvider } from "./stores/evaluationStore";
import { ArrayEvaluationsProvider } from "./stores/arrayEvaluationsStore";
import { HomeStoreProvider } from "./stores/homeStore";
import { StatisticProvider } from "./stores/statisticStore";
/**
 * components/signin folder
 */
import SignIn from "./components/signIn/SignInPage";

/**
 * components/Home folder
 */
import Home from "./components/home/Home";

/**
 * components/header
 */
import Header from "./components/header/Header";

/**
 * components/experts
 */
import ExpertsList from "./components/experts/pages/ExpertsList";

import ExpertsBlockedList from './components/experts/pages/ExpertsBlockedList';

/**
 * components/projects
 */
import Projects from "./components/pages/projects/Projects";

/**
 * components/expert
 */
import Expert from "./components/expert/Expert";

/**
 * components/footer
 */
import Footer from "./components/footer/Footer";
/**
 * components/notification
 */
import Notification from "./components/notification/Notification";
/**
 * components/detailProject
 */
import DetailProject from "./components/detailProject/DetailProject";

/**
 * components/detailsOffer/DetailsOfferPage
 */
import DetailsOfferPage from "./components/detailsOffer/DetailsOfferPage";

import EvaluationComponent from "./components/evaluations/EvaluationExpert";
import SeeEvaluation from "./components/seeEvaluations/seeEvaluations";

import PDFExport from './components/pdfExport/PDFExport';

import HomeTeamLeader from "./components/teamLeader/HomeTeamLeader";
/**
 * router folder
 */
import PrivateRoute from "./router-components/PrivateRoute";
import NoMatch from "./router-components/NoMatch";


import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

/**
 * Provides all children with global values
 */

const AppProvider = ({ children }) => {
  return (
    <TranslateProvider>
      <NotificationProvider>
        <SearchEngineProvider>
          <HistoricSearchProvider>
            <EmployeeProvider>
              <HomeStoreProvider>
                <EvaluationProvider>
                  <StatisticProvider>
                    <ArrayEvaluationsProvider>
                      <LogsProvider>
                        <OfferDetailsProvider>
                          <ProjectsProvider>
                            <ProjectDetailsProvider>
                              <MissionsProvider>
                                <CollapseProvider>
                                  <ExpertPageProvider>
                                    <ExpertDetailsProvider>
                                      {children}
                                    </ExpertDetailsProvider>
                                  </ExpertPageProvider>
                                </CollapseProvider>
                              </MissionsProvider>
                            </ProjectDetailsProvider>
                          </ProjectsProvider>
                        </OfferDetailsProvider>
                      </LogsProvider>
                    </ArrayEvaluationsProvider>
                  </StatisticProvider>
                </EvaluationProvider>
              </HomeStoreProvider>
            </EmployeeProvider>
          </HistoricSearchProvider>
        </SearchEngineProvider>
      </NotificationProvider>
    </TranslateProvider>
  )
}



function App() {

  useEffect(() => {
    $(window).scroll(function () {
      if ($(document).scrollTop() > 40) {
        $('#main-menu').removeClass('navbar-dark');
        $('#main-menu').addClass('fixed-top bg-white shadow-lg navbar-light');
        $('#introduction').addClass('padding-top');
      } else {
        $('#main-menu').removeClass('fixed-top bg-white shadow-lg navbar-light');
        $('#main-menu').addClass('navbar-dark');
        $('#introduction').removeClass('padding-top');
      }
    });

  }, []);

  return (
    <div className="App">
      <Router>
        <AppProvider>
          <Notification />
          <Header />
          <Switch>
            <PrivateRoute exact path="/" />

            <Route path="/signin" component={SignIn} />
            <Route path="/not_found" component={NoMatch} />

            <PrivateRoute path="/team-leader/evaluation/:id" component={EvaluationComponent} />
            <PrivateRoute path="/team-leader" component={HomeTeamLeader} />
            <PrivateRoute path="/home" component={Home} />
            <PrivateRoute path="/experts/blocked_account/:page" component={ExpertsBlockedList} />
            <PrivateRoute path="/experts/page/:page" component={ExpertsList} />
            <PrivateRoute path="/experts/expert/:id/PDFexport" component={PDFExport} />
            <PrivateRoute path="/experts/expert/:id" component={Expert} />
            <PrivateRoute path="/projects/page/:page" component={Projects} />
            <PrivateRoute path="/projects/detail-project/:id" component={DetailProject} />
            <PrivateRoute path="/details-offer/:id_offer" component={DetailsOfferPage} />
            <PrivateRoute path="/evaluation/:id" component={EvaluationComponent} />
            <PrivateRoute path="/evaluations/:id_mission/page/:page" component={SeeEvaluation} />
            <Route component={() => <Redirect to="/not_found" />} />
            {/* CODE POUR LE LOT B */}
            {/* <PrivateRoute path="/demands" component={Demands} /> */}
            {/* <PrivateRoute path="/mailing" component={Mailing} /> */}
          </Switch>
          <Footer />
        </AppProvider>
      </Router>
    </div>
  );
}

export default App;
