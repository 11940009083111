import React from "react";
import {FormattedMessage} from "react-intl";
import Suggestions from "./sections/Suggestions";
import {useParams} from 'react-router-dom';

const QuickActionsExperts = () => {
    
    const match = useParams();

    return (
        <div className="mb-5">
            <h2 className="title-section-blue text-uppercase mb-4"><FormattedMessage id='quickActions.title' defaultMessage="Not translated" /></h2>
            <Suggestions id_experts={[match.id]} />
            <hr className="mb-4" />
        </div>  
    )
}

export default QuickActionsExperts;