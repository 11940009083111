import React, { useContext } from "react";
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../../stores/collapseStore";
import { ExpertDetailsContext } from "../../../stores/expertsDetailsStore";
import { Mutation } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { generalConstants } from "../../../constants/general.constants";
import { missionSearchedConstants } from "../../../constants/profile-expert.constants";
import * as utils from "../../../utils/utils";
import Select from 'react-select';
import { TranslateContextWrapper } from "../../../stores/TranslateContext";
import { UPSERT_MISSION_SEARCHED } from "../../../mutations/upsertExpert";
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { customStyles } from "./style-select";
import { withRouter } from "react-router-dom";
import { notificationContext } from "../../../stores/notificationsStore";
import { getStorageUser } from "../../utils/localStorage";
import { errorsGesture } from "../../utils/errors";

const sectionMessageName = "expertForm.missionSearched.missionDurationInputs.";

const {
    TYPE_OF_MISSION,
    MISSION_DURATION
} = missionSearchedConstants;

const handleChange = ({ values, setValues }, selected) => {
    setValues({
        ...values,
        identity: {
            ...values.identity,
            mission_duration: selected
        }
    })
}

const MissionsSearched = (props) => {

    const notification = useContext(notificationContext);
    const collapseContext = useContext(CollapseContext);
    const { state } = useContext(ExpertDetailsContext);
    const { id } = props.match.params;
    const { translateContext } = props;
    const { MODIFY, REGISTER } = generalConstants;
    const { getCollapseProps, getToggleProps } = useCollapse({ isOpen: collapseContext.state.missionSearched });
    const array = ["short", "medium", "long"];
    const options = [];

    for (let i = 0; i < array.length; i++) options.push({ value: array[i], label: translateContext.translateMessage(`${sectionMessageName}inputs.${array[i]}`) });
    return (
        <Mutation
            mutation={UPSERT_MISSION_SEARCHED}
            onCompleted={(datas) => {
                notification.dispatch({ type: "setToast", target: { element: "successModification" } });
            }}
            onError={(datas) => errorsGesture(datas, () => notification.dispatch({ type: "setAlert", target: { element: "errorAuthorizationAction" } }))}
        >
            {(missionSearchedCallBack, { loading, errors }) => {
                return (

                    <Formik initialValues={state.expert} onSubmit={(values) => {
                        notification.dispatch({
                            type: "setAlert", target: {
                                element: "modification", callback: () => {
                                    missionSearchedCallBack({ variables: { mission_duration: JSON.stringify(values.identity.mission_duration), expertID: id} });
                                }
                            }
                        })
                    }}
                        enableReinitialize
                        render={formikBag => {
                            return (
                                <section>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <div className="d-flex align-items-center">
                                            <span className="icon-number"><b>2</b></span>
                                            <h2 className="mb-0"><FormattedMessage id='menu.missions' defaultMessage="Not translated" /></h2>
                                        </div>
                                        <button className="btn btn-civipol-white" {...getToggleProps({
                                            onClick: () => {
                                                collapseContext.methods.setCollapse("missionSearched", !collapseContext.state.missionSearched);
                                            }
                                        })}>{MODIFY} </button>
                                    </div>
                                    <section name="missionSearched" {...getCollapseProps()} onTransitionEnd={(e) => {
                                        for (let values in collapseContext.state) {
                                            if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                utils.scrollTo(values);
                                            }
                                        }
                                    }}>
                                        <Form className="card-body" onSubmit={formikBag.handleSubmit}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h2 className="title-section-blue text-uppercase mb-4">{TYPE_OF_MISSION}</h2>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>{MISSION_DURATION}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <Select
                                                                styles={customStyles}
                                                                isMulti
                                                                options={options}
                                                                className="mb-3"
                                                                onChange={(selected) => handleChange(formikBag, selected)}
                                                                value={(formikBag.values.identity.mission_duration) ? formikBag.values.identity.mission_duration : null}
                                                                placeholder={translateContext.translateMessage("expertForm.missionSearched.placeholderSelector")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="card-footer border-top-0 py-4">
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <button className="btn btn-civipol mx-2" type="submit">{REGISTER}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </section>
                                </section>
                            )
                        }}
                    />
                )
            }}
        </Mutation>
    )
}

export default withRouter(TranslateContextWrapper(MissionsSearched));