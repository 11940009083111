import React, {createContext, useReducer} from 'react';

const initialState = {
    identity: true,
    missionSearched: false,
    abilities: false,
    CV: false,
    supporting_documents: false,
    confirmation: false,
    clicked: false,
    individualsInformations: false
}

const CollapseContext = createContext(initialState)

const reducer = (state, action) => {
    const {target} = action;
    switch (action.type){
        case "setCollapseProps": {
            return {...state, [target.section]: target.props, clicked: !state.clicked}
        }
        default: return state
    }
}

const CollapseProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    

    const setCollapse = (section, isOpen) => {
        for (let key in state){
            if (state[key] === true){
                dispatch({type: "setCollapseProps", target: {section: key, props: false}})
            }
        }
        dispatch({type: "setCollapseProps", target: {section: section, props: isOpen}})
    }

    return (
        <CollapseContext.Provider value={{state, methods: {
            setCollapse: setCollapse
        }}}>
        {children}
        </CollapseContext.Provider>
    )
}

export {CollapseProvider, CollapseContext}