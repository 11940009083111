export const getSessionItemByKey = (key) => {
    let item = sessionStorage.getItem(key);
    if (item) {
        return JSON.parse(item);
    } else return null;
}

export const setSessionItemByKeyValue = (key, value) => {
    let stringifiedValue = JSON.stringify(value);
    sessionStorage.setItem(key, stringifiedValue);
}