export const messages = {
    'en': {
        general: {
        },
        signin: {

        }

    },
    'fr': {
        general: {
            modify: "Modifier",
            optional: "Facultatif",
            register: "Enregistrer",
            yes: "Oui",
            no: "Non",
            cancelSelection: "Annuler la sélection",
        },
        error: {
            internalError: "Une erreur est survenue, merci de reesayer on de contacter CIVIPOL."
        },
        menu: {
            identity: "Identité",
            missions: "Missions recherchées",
            abilities: "Compétences",
            CV: "CV",
            documentToUpload: "Pièces justificatives",
            recommandations: "Recommandations",
            confirmation: "Confirmation",
            individualsInformations: "Informations individuelles"
        },
        header: {
            home: "Accueil",
            "team-leader": "Accueil",
            not_found: "Not Found",
            experts: "Experts",
            projects: "Projets",
            validations: "Validations",
            demands: "Demandes",
            mailing: "Mailing",
            logout: "Se déconnecter",
            welcome: "Bienvenue",
            evaluation: "Évaluation",
            evaluations: "Évaluations",
            "details-offer": "Détails de l'offre",

        },
        administration: {
            title: "Administration",
            deleteButton: "Suprimer l'expert"
        },
        signin: {
            title: "Accès à l’interface d'administration",
            login: "Nom d'utilisateur",
            password: "Mot de passe",
            errorFieldEmpty: "Merci de remplir tous les champs",
            signinError: "Les informations ne sont pas valides"
        },
        expert: {
            creationDate: "Date de création",
            lastModification: "Dernière modification",
            missionsForCivipol: "Missions pour CIVIPOL",
            dateAccepted: "Mission acceptée le",
            dateCandidature: "Date de candidature",
            unlockButton: "Débloquer l'expert",
            unlockAllButton: "Débloquer les experts"
        },
        validations: {
            headerNbProfilesToValidate: "Nouveau profil en attente de validation",
            headerAccept: "Accepter",
            headerReject: "Rejeter",
        },
        globalsInputs: {
            key: "Clé",
            value: "Valeur",
            language: "Langue",
            level: "Niveau européen",
            notFilled: "Non renseigné",
            title: 'Identité',
            expertiseDomain: "DOMAINES D'EXPERTISE",
            type: "Compétence",
            year_experience: "Années d'expérience",
            diploma: "Diplome",
            aboutExpert: "À propos de l'expert",
            civility: "Civilité",
            male: "Monsieur",
            female: "Madame",
            birthday: "Date de naissance",
            idPic: "Photo de profil",
            buttonProfilePic: "Modifier la photo de profil",
            firstName: "Prénom",
            lastName: "Nom de famille",
            nationality_1: "Nationalité 1",
            nationality_2: "Nationalité 2",
            placeholderSelector: "Sélectionnez",
            expertCoordinates: "Coordonnées de l'expert",
            contact: "Contact",
            address_1: "Adresse principale",
            numberAndStreet_1: "Numéro et nom de rue",
            address_1_postal_code: "Code postal",
            address_1_city: "Ville",
            address_1_country: "Pays",
            address_2: "Adresse secondaire",
            address_2_close: "Fermer l'adresse secondaire",
            address_1_number_street: "Numéro et nom de rue",
            address_2_number_street: "Numéro et nom de rue",
            address_2_postal_code: "Code postal",
            address_2_city: "Ville",
            address_2_country: "Pays",
            tel_mobile: "Téléphone 1",
            telephone_2: "Téléphone 2",
            mission_duration: "Missions recherchées",
            tel_fix: "Téléphone 2",
            email_1: "Adresse mail",
            email_2: "Adresse mail secondaire",
            fonctionnaire: "Fonction publique",
            discovered: "Comment avez vous connu CIVIPOL",
            expert_ability: "Situation professionnelle",
            fonc_level: "Grade",
            DG: "Entité",
            since: "Depuis",
            languages: "Langues parlées et niveau européen",
            accept_salarial_contract: "Contrat salarial souhaité",
            accept_service_contract: "Contrat de service souhaité",
            nonFonctionnaire: "Non fonction publique",
            countries: "Pays où l'expérience est significative",
            notificationOffers: "Recevoir des notifications d'offres",
            notificationActus: "Recevoir des notifications d'actualités",
        },
        expertForm: {
            identity: {
                title: 'Identité',
                aboutExpert: "À propos de l'expert",
                civility: "Civilité",
                male: "Monsieur",
                female: "Madame",
                birthday: "Date de naissance",
                idPic: "Photo de profil",
                buttonProfilePic: "Modifier la photo de profil",
                profile_pic: "Photo de profil",
                firstName: "Prénom",
                lastName: "Nom de famille",
                nationality_1: "Nationalité 1",
                nationality_2: "Nationalité 2",
                placeholderSelector: "Sélectionnez",
                expertCoordinates: "Coordonnées de l'expert",
                contact: "Contact",
                address_1: "Adresse principale",
                numberAndStreet_1: "Numéro et nom de rue",
                address_1_postal_code: "Code postal",
                address_1_city: "Ville",
                address_1_country: "Pays",
                address_2: "Adresse secondaire",
                address_2_close: "Fermer l'adresse secondaire",
                address_1_number_street: "Numéro et nom de rue",
                address_2_postal_code: "Code postal",
                address_2_city: "Ville",
                address_2_country: "Pays",
                tel_mobile: "Téléphone 1",
                telephone_2: "Téléphone 2",
                email_1: "Adresse mail",
                email_2: "Adresse mail secondaire",
                fonctionnaire: "Fonction publique",
                nonFonctionnaire: "Non fonction publique",
                countries: "Pays où l'expérience est significative",
                notificationOffers: "Recevoir des notifications d'offres",
                notificationActus: "Recevoir des notifications d'actualités",
            },
            ICE: {
                iceTitle: "Personnes à prévenir en cas d'urgence",
                first_name: "Prénom",
                last_name: "Nom de famille",
                address_number_street: "Numéro et nom de rue",
                address_postal_code: "Code postal",
                city: "Ville",
                address_country: "Pays",
                telephone: "Téléphone",
                email: "Adresse mail",
            },
            missionSearched: {
                title: "Missions Recherchées",
                typeOfMission: "Type de mission",
                missionDuration: "Durée de la mission souhaitée",
                missionDurationInputs: {
                    inputs: {
                        long: "Long terme > 1 an",
                        medium: "Moyen terme < 1 an",
                        short: "Court terme < 1 mois"
                    }
                },

                placeholderSelector: "Sélectionnez",
            },
            abilities: {
                languagesTitle: "Langues Maitrisées",
                languageHowToKnow: "Comment déterminer mon niveau de maitrise de la langue ?",
                languageSeeNorms: "Voir les normes de niveaux européens.",
                langue: "Langue ",
                langueLabel: "Langue",
                since: "Depuis",
                placeholderSelector: "Sélectionnez",
                levelsLang: {
                    native: "Langue maternelle",
                    A1: "A1 - Débutant",
                    A2: "A2 - Débutant",
                    A3: "A3 - Débutant",
                    B1: "B1 - Indépendant",
                    B2: "B2 - Indépendant",
                    C1: "C1 - Avancé",
                    C2: "C2 - Compétent"
                },
                levelLabel: "Niveau européen",
                professionnalSituation: "Situation professionnelle",
                publicAgent: "Agent de la fonction publique ?",
                fonctionnary: "Fonctionnaire en disponibilité/détachement",
                fonctionnary_retired: "Fonctionnaire retraité",
                retired_since: "Retraité depuis",
                notFonctionnary: "Non fonction publique",
                entity: "Entité",
                grade: "Grade",
                salarialContract: "Contrat salarial souhaité",
                serviceContract: "Contrat de services souhaité",
                typeOfContract: "Type de contractualisation souhaité",
                salarialContract: "Contrat de travail",
                prestationContract: "Contrat de prestation",
                DG: {
                    SELECT: "Sélectionnez",
                    DGPN: "DGPN",
                    DGGN: "DGGN",
                    DGSCGC: "DGSCGC",
                    DGDDI: "DGDDI",
                    DGEF: "DGEF",
                    DGSI: "DGSI",
                    DGSL: "DGSL",
                    SG: "SG",
                    JUSTICE_MIN: "Ministère justice",
                    ECONOMIC_MIN: "Ministère economie finance",
                    DEFENSE_MIN: "Ministère défense",
                    FOREIGN_AFF_MIN: "Ministère affaires étrangèress",
                    FIRST_MIN: "Premier ministère",
                    OTHER: "Autres",
                },
                levels: {
                    SELECT: "Sélectionnez",
                    RETIRED: "Retraité",
                    AP: "A+",
                    A: "A",
                    B: "B",
                    C: "C",
                    OTHER: "Autres",
                },
                expertiseDomainTitle: "Domaines d'expertise",
                selectDomain: "Sélectionnez une ou plusieurs compétences :",
                yearOfExperience: "Années d'expériences",
                diplomaForExperience: "Expérience validée par un diplôme",
                diploma: "L'expérience validée par un diplome a été {isValidate}.",
                year_experience: "Le nombre d'année d'expérience est passées de {old} à {new}.",
                tags: {
                    Cadre: "Cadre normatif de l’état civil",
                    CivilState: "Gestion de l’état civil",
                    IDTech: "Technologies de l’identité",
                    Corruption: "Lutte contre la corruption",
                    CriminalJustice: "Justice pénale",
                    FederalPolice: "Police judicaire",
                    PenitentiaryAdministration: "Administration pénitentiaire",
                    ScientificPolice: "Police technique et scientifique",
                    Contrefacon: "Lutte contre la contrefaçon",
                    Fraud: "Lutte contre la fraude",
                    HumanTraffic: "Lutte contre la traite des êtres humains",
                    BorderManagement: "Contrôle des frontières",
                    Immigration: "Lutte contre l'immigration irrégulière",
                    OrganizedCrime: "Lutte contre la criminalité organisée",
                    EconomicCrime: "Lutte contre la criminalité économique",
                    CyberCrime: "Lutte contre la cybercriminalité",
                    PublicOrder: "Ordre public",
                    AviationSafety: "Sureté / sécurité aérienne, portuaire et aéroportuaire",
                    StrongEventsSafety: "Sécurité des grands événements",
                    Crise: "Gestion de crise",
                    Extremism: "Lutte contre l'extrémisme violent",
                    Radicalisation: "Lutte contre la radicalisation",
                    Renseignement: "Renseignement",
                    Financial: "Lutte contre le blanchiment des capitaux et financement du terrorisme",
                    NRBC: "NRBC",
                    MineClearing: "Déminage",
                    CyberTerrorism: "Lutte contre le cyberterrorisme",
                    InfrastructureEngineering: "Ingénierie de protection des infrastructures",
                    AdminFinancialManagement: "Gestion administrative et financière",
                    Communication: "Communication / Presse",
                    TeamManagement: "Encadrement d’équipe",
                    HR: "Ressources Humaines",
                    Research: "Recherche",
                    FormatterTraining: "Formation de formateurs",
                    ProjectCoordiation: "Coordination de projets",
                    TripOrganisation: "Organisation de voyage / évènementiel",
                    Legal: "Juridique",
                    NTIC: "NTIC",
                    Procurement: "Achat / procurement",
                    OffersIngeneering: "Ingénierie d’offres",
                    PublicRelations: "Relations publiques",
                    Traductor: "Interprétariat / Traduction"
                },
                significantsCountries: "Pays où l'expérience est significative",
            },
            CV: {
                title: "Curriculum Vitae",
                choseFile: "Choisir un fichier",
                fileFormat: "Fichier au format Word (.doc ou .docx) ou PDF uniquement. (5Mo maximum)",
            },
            documentToUpload: {
                title: "Pièces justificatives",
                error: "Merci d'ajouter au moins une pièce justificative",
                error_2: "Le format d'un fichier n'est pas correct",
                subtitle: "Vous pouvez ajouter des pièces justificatives à votre profil :",
                advice: "Format de fichiers acceptés: Word, PDF, JPG, PNG, BMP",
                ID: "CNI",
                Passport: "Passeport",
                RIB: "RIB",
                Diploma: "Diplôme",
                Certificat: "Certificat de travail",
                AutorNotCumulActivity: "Autorisation de cumul d'activités",
                VitalCard: "Attestation de carte vitale",
                DriveLicence: "Permis de conduire",
                KBIS: "KBIS",
                Siret: "Siret",
                CriminalRecord: "Extrait de casier judiciaire",
                PassportNumber: "Numéro de passeport",
                VitalNumber: "Numéro de carte vitale",
                RCAttestation: "Attestation RC",
                TransportCertificate: "Copie du titre de transport (Navigo...)",
                MedicalSuitability: "Copie de la dernière fiche d'aptitude médicale, délivrée par la médecine du travail au cours des 5 dernières années.",
                SalarySlip: "Copie du dernier bulletin de salaire (si salarié).",
                PensionSlip: "Copie du dernier bulletin de pension (si retraité).",
                MutualInsuranceCertificat: "Copie de l'affiliation à un organisme de mutuelle.",
                ResidencePermit: "Copie d'un titre de séjour en cours de validité vous autorisant à exercer une activité professionnelle (si travailleur étranger).",
            },
            confirmation: {
                notificationsTitle: "Préférences de notification",
                getOffersByMail: "Le candidat souhaite recevoir les nouvelles offres d'emplois par mail.",
                getActusByMail: "Le candidat souhaire recevoir les actualités générales de CIVIPOL par mail",
                howDidYouKnowTitle: "Comment le candidat a découvert civipol ?",
                condamnationTitle: "Condamnation pénale",
                condmanationText: "Le candidat déclare sur l'honneur ne pas être soumis à une condamnation pénale et n'est sujet également à aucune procédure pénale en cours.",
                exactitudeTitle: "Exactitude des informations",
                exactitudeText: "Le candidat déclare sur l'honneur que les informations saisies sur son profil sont exactes",
                arrayHowDidYouKnow: {
                    default: "Sélectionnez une réponse",
                    title: "Comment avez vous connu CIVIPOL",
                    friends: "Par des proches, des collègues",
                    administration: "Par votre administration d’origine",
                    institution: "Par les institutions européennes",
                    medias: "Par les médias (TV, journaux, internet)",
                    socialNetworks: "Via les réseaux sociaux",
                    salons: "Par les salons MILIPOL",
                    other: "Autre",
                },
            },
            individualsInformations: {
                title: "Informations individuelles",
                personsToContact: {
                    title: "Personne a prévenir en cas d'urgence",
                    addPerson: "Ajouter une personne",
                    removePerson: "Supprimer une personne",
                    inputs: {
                        firstName: "Prénom",
                        lastName: "Nom",
                        relationShip: "Lien de parenté",
                        numberAndStreet: "Numéro et nom de rue",
                        postalCode: "Code postal",
                        addressCity: "Ville",
                        addressCountry: "Pays",
                        telephone: "Numéro de téléphone",
                        email: "Email"
                    }
                },
                personalInformations: {
                    title: "Informations complémentaires",
                    modifyButton: "Modifier",
                    seeDocument: "Voir",
                    inputs: {
                        matrimonialName: "Nom matrimonial",
                        placeOfBirth: "Commune de naissance",
                        nativeCountry: "Pays de naissance",
                        passportExpirationDate: "Date d'expiration du passeport",
                        passportPlaceDelivery: "Lieu de délivrance du passeport",
                        passportNumber: "Numéro de passeport",
                        passportFile: "Fichier Passeport",
                        importPassportFile: "Importer le passeport",
                        rib: "Ficher RIB",
                        importRibFile: "Importer le RIB",
                        socialSecurityNumber: "Numéro sociale",
                        vitalCardFile: "Fichier carte vitale",
                        importVitalCardFile: "Importer la carte vitale",
                        ibanNumber: "Numéro IBAN",
                        ribNumber: "Numéro de RIB"
                    }
                },
                medicalInformations: {
                    title: "Informations médicales",
                    inputs: {
                        doctorName: "Nom du médecin traitant",
                        doctorAddress: "Adresse",
                        doctorPhoneNumberFix: "Numéro de téléphone fixe",
                        doctorCellPhoneNumber: "Numéro de télephone mobile",
                        doctorEmail: "Email",
                        bloodGroup: "Groupe sanguin",
                        rhesus: "Rhésus",
                        therapeuticsContrindications: "Contre-indications médicales",
                        allergies: "Allergies"
                    }
                },
            },
        },
        notifications: {
            alert: {
                generalErrorTitle: "ERREUR",
                generalErrorText: "Une erreur est survenue",
                expertNotCompleteText: "Cet expert n'a pas complété son profil, vous ne pouvez pas valider sa candidature.",
                alertModificationTitle: "ATTENTION",
                alertModificationText: "Vous êtes sur le point de modifier le profil de cet expert. Confirmer ?",
                alertRejectExpertTitle: "ATTENTION",
                alertRejectExpertText: "Vous êtes sur le point de rejeter le profil de cet expert et donc de supprimer son compte. Confirmer ?",
                alertPromptHistoticTitle: "ENREGISTRER LA RECHERCHE",
                alertPromptHistoticText: "Merci de rentrer un titre pour la recherche",
                alertPromptHistoticPlaceHolder: "Votre titre",
                alertPromptHistoticSubmit: "Enregistrer la recherche",
                alertPromptHistoticCancel: "Annuler",
                elertKeyExpertTitle: "NOTIFICATION",
                elertKeyExpertText: "Souhaitez vous modifier l'état de cet expert pour cette mission ?",
                elertEvaluationExpertTitle: "Attention",
                elertEvaluationExpertText: "Vous allez évaluer cet expert, êtes-vous sûr des informations saisies ?",
                alertRecommandationExpertTitle: "Recommandation",
                alertRecommandationExpertText: "Vous êtes sur le point de modifier la recommandation de cet expert, êtes-vous sûr de vouloir continuer ?",
                expertNotValidatedText: "Cet expert n'est pas encore validé, souhaitez-vous voir son profil ?",
                teamLeaderAlreadyAssignedTitle: "Action impossible",
                teamLeaderAlreadyAssignedText: "Un team leader a déjà été associé pour cette mission.",
                notationTitle: "Note de l'expert",
                notationText: "L'expert a une note moyenne de {note} / 10 sur {nb_evaluations} évaluations.",
                candidaturesExpertTitle: "Candidatures",
                deleteAccountTitle: "Suppressions du compte",
                deleteAccountText: "Souhaitez vous vraiment supprimer ce compte expert ?",
                errorInputProfileTitle: "Action impossible",
                errorInputProfileText: "Vous ne pouvez pas modifier une section de profil qui n'a pas été totalement complétée.",
                errorAuthorizationActionTitle: "Action impossible",
                errorAuthorizationActionText: "Vous n'avez pas les droits nécessaires pour effectuer cette action."
            },
            toast: {
                toastModificationTitle: "MODIFICATION",
                toastModificationText: "Vos modifications ont bien été enregistrées.",
                toastSuggestionTitle: "SUGGESTION",
                toastSuggestionText: "L'offre a bien été suggérée a l'expert.",
            }
        },
        missionsPage: {
            candidatures: {
                title: "Ses candidatures",
                text: "L'expert a postulé a {nb_candidatures} offre{s} d'emploi Civipol :",
                postuleTitle: "Candidatures",
                rejectedTitle: "Rejets",
                dateReject: "Date de refus",
                date: "Date de candidature",
                buttonSeeOffer: "Voir l'offre",
                buttonValidation: "Valider la candidature",
                buttonRejection: "Refuser la candidature",
                rejectSelectionButton: "Refuser les candidatures",
                rejectAllLabel: "Refuser toutes les candidatures",
                rejectSelectLabel: "Refuser",

            },
            missions: {
                title: "Ses missions",
                text: "Il a effectué {nb_missions} mission{s} Civipol :",
                missionAgreed: "Missions acceptées mais pas débutées",
                InMission: "Les missions en cours",
                finishedMission: "Les missions terminées",
                buttonSeeOffer: "Voir l'offre",
                buttonEvaluate: "Evaluer l'expert",
                date_start: "Date de début",
                date_end: "Date de fin",
            },
            notifications: {
                candidatures: {
                    title: "Cet expert n'a postulé a aucune offre d'emploi Civipol",
                    button: "Voir les offres d'emploi"
                },
                missions: {
                    title: "Cet expert n'a pas encore effectué de mission Civipol",
                    button: "Voir les offres d'emploi"
                }
            }
        },
        quickActions: {
            title: "Actions Rapides",
            offersPlaceholder: "Rechercher une offre",
            offerSuggestionButton: "Suggérer cette offre",
            exportExpert: "Exporter les données de l'expert",
            recommandationButton: "Recommander cet expert",
        },
        quickActionsHome: {
            title: "Actions Rapides",
            searchExpert: "Rechercher un expert",
            seeValidatiogExperts: "Voir les profils en attente de validation",
            searchBlockedExpert: "Experts bloqués"
        },
        projects: {
            title: "Les Projets",
            projectCard: {
                date_start: "Date de début",
                date_end: "Date de fin",
                seeProject: "Voir le projet",
                detailsProject: "Voir le détail",
                noOffer: "Il n'y a pas d'offre attribuée au projet",
                offersNotAttrib: "Il y a {nb_offer} offre sans expert",
                projectOver: "Projet terminé",
            },
            details: {
                aboutTitle: "À propos du projet",
                chiefTitle: "Chef de projet",
                offersTitle: "Les offres d'emplois",
                project: {
                    seeOnWebProject: "Voir la fiche complète sur le site internet de civipol",
                    startDate: "Début du projet",
                    endDate: "Fin du projet",
                    duration: "Durée"
                }
            }
        },
        offers: {
            seeProfile: "Voir le profil",
            accountDeleted: "Compte utilisateur supprimé",
            searchExpert: "Rechercher expert",
            dateCandidatureAccepted: "Candidature acceptée le ",
            keyExpert: "Expert principal",
            keyExpertInMission: "Expert principal en mission",
            expertInMission: "Expert en mission",
            noCandidature: "Aucune candidature",
            missionDuration: "Durée de la mission",
            candidatureLimit: "Limite de candidature",
            geoZone: "Zone géographique",
            seeOffer: "Voir l'offre",
        },
        searchEngine: {
            searchTitle: "Rechercher dans la base experts",
            placeHolderInput: "Rechercher le nom d'un expert, une compétence, une langue, etc.",
            deleteButtonInput: "Réinitialiser",
            searchButtonInput: "Rechercher",
            advancedSearchTitle: "Recherche avancée",
            historySearch: "Historique des recherches sauvegardées",
            delete: "Supprimer",
            missionDuration: "Durée mission",
            publicFunctionTitle: "Fonction publique",
            since: "Depuis",
            selectOnlyRecommandedProfile: "Uniquement profil recommandé",
            displayOnlyRecommandedProfile: "Afficher uniquement les experts recommandés",
            displayOnlyDispoProfile: "Afficher uniquement les experts disponibles",
            searchEngineButton: "Lancer",
            abilities: "Compétences",
            abilitiesSubtitle: "Sénior: +10 ans d'expérience",
            exportResultsCSV: "Exporter au format CSV",
            exportResultsXLS: "Exporter au format XLS",
            tooltipAdBlock: "Pensez à désactiver votre bloqueur de publicités",
            offerSuggestion: "Suggestion d'offres",
            tags: {
                PenitentiaryAdministration: "Administration pénitentiaire",
                Cadre: "Cadre normatif de l’état civil",
                BorderManagement: "Contrôle des frontières",
                Crise: "Gestion de crise",
                CivilState: "Gestion de l’état civil",
                CriminalJustice: "Justice pénale",
                Contrefacon: "Lutte contre la contrefaçon",
                Corruption: "Lutte contre la corruption",
                EconomicCrime: "Lutte contre la criminalité économique",
                OrganizedCrime: "Lutte contre la criminalité organisée",
                CyberCrime: "Lutte contre la cybercriminalité",
                Fraud: "Lutte contre la fraude",
                HumanTraffic: "Lutte contre la traite des êtres humains",
                Extremism: "Lutte contre l'extrémisme violent",
                Immigration: "Lutte contre l'immigration irrégulière",
                PublicOrder: "Ordre public",
                FederalPolice: "Police judicaire",
                ScientificPolice: "Police technique et scientifique",
                StrongEventsSafety: "Sécurité des grands événements",
                AviationSafety: "Sureté / sécurité aérienne, portuaire et aéroportuaire",
                IDTech: "Technologies de l’identité",
                Radicalisation: "Lutte contre la radicalisation",
                Renseignement: "Renseignement",
                Financial: "Lutte contre le blanchiment des capitaux et financement du terrorisme",
                NRBC: "NRBC",
                MineClearing: "Déminage",
                CyberTerrorism: "Lutte contre le cyberterrorisme",
                InfrastructureEngineering: "Ingénierie de protection des infrastructures",
                AdminFinancialManagement: "Gestion administrative et financière",
                Communication: "Communication / Presse",
                TeamManagement: "Encadrement d’équipe",
                HR: "Ressources Humaines",
                Research: "Recherche",
                FormatterTraining: "Formation de formateurs",
                ProjectCoordiation: "Coordination de projets",
                TripOrganisation: "Organisation de voyage / évènementiel",
                Legal: "Juridique",
                NTIC: "NTIC",
                Procurement: "Achat / procurement",
                OffersIngeneering: "Ingénierie d’offres",
                PublicRelations: "Relations publiques",
                Traductor: "Interprétariat / Traduction"
            },
            indifferent: "Indifférent",
            missionsDuration: {
                INDIFFERENT: "Indifférent",
                short: "Court terme < 1 mois",
                medium: "Moyen terme > 1 mois",
                long: "Long terme > 1 an",
            },
            publicFunction: {
                INDIFFERENT: "Indifférent",
                DGPN: "DGPN",
                DGGN: "DGGN",
                DGSCGC: "DGSCGC",
                DGDDI: "DGDDI",
                DGEF: "DGEF",
                DGSI: "DGSI",
                DGSL: "DGSL",
                SG: "SG",
                JUSTICE_MIN: "Ministère justice",
                ECONOMIC_MIN: "Ministère économie finance",
                DEFENSE_MIN: "Ministère défense",
                FOREIGN_AFF_MIN: "Ministère affaires étrangères",
                FIRST_MIN: "Premier ministère",
                OTHER: "Autres",
            },

        },
        evaluationExpert: {
            title: "Évaluation",
            evaluationOf: "Evaluation de {firstName_expert} {lastName_expert}",
            projectTitle: "Projet : ",
            projet: "{project_name} du {start_date} au {end_date}",
            offerTitle: "Offre : ",
            offer: "{offer_name}",
            dateEvaluationTitle: "Date de l'évaluation :",
            dateEvaluation: "{date_evaluation}",
            competencesTitle: "Compétences requises",
            aptitudesTitle: "Aptitudes requises",
            generalTitle: "Appréciation générale",
            chiefPole: "Chef de pole :",
            projectCode: "Code projet interne :",
            projectResponsable: "Responsable de projet :",
            endOfPeriod: "Date de fin de période d'essai :",
            endOfPeriodReniew: "Date de fin de période d'essai renouvelée :",
            country: "Lieu d'intervention :",
            linkToOffer: "Lien vers l'offre d'emploi :",
            missionInformationTitle: "Informations sur la mission",
            competencesCriterias: {
                technicalAbilities: "Techniques",
                administrativGestion: "Gestion administrative",
                financialGestion: "Gestion financière",
                deliverable: "Livrable",
                pedagogy: "Pédagogie"
            },
            aptitudesCriterias: {
                communication: "Communication : aptitude à informer et rendre compte de l'avancée du projet auprès du client et du responsable projet",
                organisation: "Sens de l'organisation : aptitude à planifier et à respecter les échéances qui lui sont dévolues et à gérer les aléas",
                teamSpirit: "Esprit d'équipe : capacité à collaborer avec les autres pour promouvoir la réussite du projet",
                senseOfHearing: "Sens de l'écoute : capacité à prendre en compte objectivement des avis différents",
                exemplarity: "Exemplarité : adopte un comportement en tout point conforme aux valeurs de l'opérateur (probité, éthique, dignité)"
            },
            notations: {
                nonApplicable: "Non applicable",
                notions: "Insuffisant",
                confirmed: "À améliorer",
                maitrise: "Satisfaisant",
                expert: "Point fort",
            },
            generalNotations: {
                ameliorable: "À améliorer",
                conform: "Conforme aux objectifs du projet",
                depasse: "Au delà des objectifs",
            },
            registerButton: "Enregistrer",
        },
        statisticsHome: {
            title: "Suivi d'activités"
        },
        template: {
            consultedProfileTitle: "Profils consultés",
            consultedProfileText: "Vous n'avez pas encore consulté de profil.",
            validationTitle: "Validation",
            validationText: "Il n'y a pas de profil à valider pour le moment",
            noEvaluationTitle: "Evaluations",
            noEvaluationText: "Vous n'avez aucun expert à évaluer pour le moment.",
            noBlockedAccountTitle: "Comptes bloqués",
            noBlockedAccountText: "Il n'y a pas de compte bloqué",
            errorDefaultTitle: "Erreur",
            errorDefaultText: "Une erreur s'est produite, merci de réessayer plus tard.",
        },
        pdfExport: {
            exportButton: "Exporter au format PDF"
        }
    },
};

export function flattenMessages(nestedMessages, prefix = '') {
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value == 'string') {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
}
