import React, { useEffect, useContext } from "react";
import client from "../../../../ApolloSetup";
import { withRouter } from "react-router-dom";
import { logsContext } from "../../../../stores/logsStore";
import { GET_MODIFICATIONS_LOGS } from "../../../../queries/getLogs";
import ModificationComponent from "./ModificationComponent";

const getModificationsLogs = (id_expert, { dispatch }) => {

    client.query({
        query: GET_MODIFICATIONS_LOGS,
        variables: { expertID: id_expert }
    }).then((values) => {dispatch({ type: "setModifications", target: values.data.getLogsModifications })}).catch(err => console.log(err));
}

const ModificationsContainer = (props) => {

    const id_expert = props.match.params.id;
    const logsStore = useContext(logsContext);

    useEffect(() => {
        getModificationsLogs(id_expert, logsStore);
    }, [])

    if (logsStore.state.modifications && logsStore.state.modifications.length > 0) {
        return (
            <div>
                {logsStore.state.modifications.map((value, key) => {
                    return <ModificationComponent modification={value} key={key} />
                })}
            </div>
        )
    } else return <div>PAS DE LOGS POUR LE MOMENT</div>
}

export default withRouter(ModificationsContainer);