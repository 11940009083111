import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    profileTitle: {
        textTransform: 'uppercase',
        marginBottom: '10rem'
    },
    page: {
        padding: '30px',
    },
    titleUser: {
        textTransform: 'capitalize',
        marginBottom: 10
    },
    sectionProfile: {
        padding: 20,
        border: '2px solid #efefef',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column'
    },
    blueSubtitles: {
        color: '#0F4D95',
        fontWeight: 'light',
        textTransform: 'uppercase',
        fontSize: '5mm',
        marginBottom: 15
    },
    blackSubtitles: {
        color: 'black',
        fontSize: '5mm',
        marginBottom: 10
    },
    greyLabel: {
        color: '#333333',
        fontWeight: 100,
        fontSize: '5mm',
    },
    lightGreyUppercaseLabel: {
        textTransform: 'uppercase',
        fontSize: '5mm',
        color: '#666666',
        marginBottom: 15
    },
    normalBlackText: {
        fontSize: '5mm',
        marginBottom: 15
    },
    normalBlackTextDin: {
        fontSize: '5mm',
        marginBottom: 15
    }
});

export const sectionTitleStyle = StyleSheet.create({
    container: {
        margin: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    sectionTitlePastille: {
        backgroundColor: '#efefef',
        borderRadius: '50%',
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10
    },
    sectionTitle: {
        fontSize: '1cm',
        textTransform: 'capitalize',
        marginTop: 5
    }
});

export const selectStyle = StyleSheet.create({
    selectContainer: {
        border: '2px solid #efefef',
        padding: 5,
        backgroundColor: '#F7F7F7',
        fontSize: '5mm',
        minWidth: '10%'
    }
});

export const skillsStyle = StyleSheet.create({
    skillsContainer: {
        padding: 5,
        display: 'flex',
        flexDirection: 'column',
    },
    skillContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    tagContainer: {
        backgroundColor: '#0F4D95',
        minHeight: '10%',
        borderRadius: '50%',
        padding: '1%',
        paddingHorizontal: '2%'
    }
})