import React, {useContext} from "react";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { searchEngineContext } from "../../../../../stores/searchEngineStore";
import * as countries from "i18n-iso-countries";
import * as langs from 'langs';

const Nationality = (engineStore) => {

    let options = Object.values(countries.getNames("fr"));
    for (let i = 0, count = options.length; i < count; i++) {
        options[i] = { value: options[i], label: options[i] }
    }
    options.unshift({ value: "", label: "Indifférent" });
    return (
        <div className="form-group">
            <label className="search-title" htmlFor="nationality">Nationalité</label>
            <SelectComponent {...engineStore} name="nationality" options={options} />
        </div>
    )
}

const SignificantExperience = (engineStore) => {

    let options = Object.values(countries.getNames("fr"));
    for (let i = 0, count = options.length; i < count; i++) {
        options[i] = { value: options[i], label: options[i] }
    }
    options.unshift({ value: "", label: "Indifférent" });
    return (
        <div className="form-group">
            <label className="search-title" htmlFor="countries">Pays où l'expérience est significative</label>
            <SelectComponent {...engineStore} name="countries" options={options} isMulti />
        </div>
    )
}

const Language = (engineStore) => {

    let options = langs.names(true);
    for (let i = 0, count = options.length; i < count; i++) {
        options[i] = { value: options[i], label: options[i] }
    }
    options.unshift({ value: "", label: "Indifférent" });
    return (
        <div className="form-group">
            <label className="search-title" htmlFor="langue">Langues</label>
            <SelectComponent {...engineStore} name="langue" options={options} />
        </div>
    )
}

const LangueLevel = (engineStore) => {

    const options = [
        { value: "", label: "Indifférent" },
        { value: "native", label: "Langue maternelle" },
        { value: "A1", label: "A1 - Débutant" },
        { value: "A2", label: "A2 - Débutant" },
        { value: "A3", label: "A3 - Débutant" },
        { value: "B1", label: "B1 - Indépendant" },
        { value: "B2", label: "B2 - Indépendant" },
        { value: "C1", label: "C1 - Avancé" },
        { value: "C2", label: "C2 - Compétent" },
    ];

    return (
        <div className="form-group">
            <label className="search-title" htmlFor="langue_level">Niveau de langue</label>
            <SelectComponent {...engineStore} name="langue_level" options={options} disabled={engineStore.state?.language?.langue === ''} />
        </div>
    )
}


const NationalityLangues = () => {

    const engineStore = useContext(searchEngineContext);

    return (
        <div className="row mb-4">
            <div className="col">
                <div className="row mb-4">
                    <div className="col-6 col-xl-4">
                        <Nationality {...engineStore} />
                    </div>
                    <div className="col-6 col-xl-4">
                        <SignificantExperience {...engineStore} />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6 col-xl-4">
                        <Language {...engineStore} />
                    </div>
                    <div className="col-6 col-xl-4">
                        <LangueLevel {...engineStore} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NationalityLangues;