import React, { useEffect, useState } from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import client from '../../ApolloSetup';
import { getStorageUser } from "../utils/localStorage";
import { Loader } from '../loader/Loader';
import Error from "../error/Error";
import { Link } from 'react-router-dom';
import NoResultTemplate from "../templates/NoResultTemplate";
import { template } from "../../constants/templates.constants";

const {
    NO_EVALUATION_TEXT,
    NO_EVALUATION_TITLE
} = template;

const GET_PROJECT_TEAM_LEADER = gql`
    query getProjectDatasTeamLeader{
        getProjectDatasTeamLeader {
            staffed
            id
            expert {
                id
                firstName
                lastName
                profile_pic
                expert_ability {
                    type_of_contract
                }
            }
            evaluationEmployee {
                is_complete
                criterias {
                    name
                    value
                }
            }
            offer {
                title {
                    fr
                }
                mission_length {
                    fr
                }
                project {
                    title {
                        fr
                    }
                }
            }
        }
    }
`;

const ExpertCard = ({
    expert,
    mission,
    offer,
    evaluationEmployee
}) => {

    let divStyle = "";
    const { profile_pic, firstName, lastName } = expert;
    const { id } = mission;
    const { title, mission_length } = offer;
    if (profile_pic) {
        divStyle = { backgroundImage: 'url(' + process.env.REACT_APP_API_URL + profile_pic.substring(1, profile_pic.length) + ')' };
    }
    return (
        <div className="col">
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row flex-row justify-content-between align-items-start">
                        <div className="col-12 mb-4 mb-lg-0">
                            <div className="row">
                                <div className="d-flex flex-column col-6 justify-content-start">
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="rounded-circle profile-picture" style={divStyle || null} />
                                        <div className="d-flex flex-column ml-4">
                                            <h4 className="profile-name">{`${firstName} ${lastName}`}</h4>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column justify-content-start">
                                        <div>Mission: {title?.fr}</div>
                                        <div>Durée de la mission: {mission_length.fr}</div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column col-6 justify-content-end align-items-end mb-4">
                                    {evaluationEmployee?.length > 0 && <Link style={{ minWidth: '245px' }} to={`/evaluations/${id}/page/1`} className="btn btn-civipol-white mb-2">Voir les évaluations</Link>}
                                    <Link to={`/evaluation/${id}`} style={{ minWidth: '245px' }} className="btn btn-civipol">Evaluer l'expert</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const HomeTeamLeader = () => {

    const { loading, error, data } = useQuery(GET_PROJECT_TEAM_LEADER, {
        fetchPolicy: "no-cache",
        client
    });

    if (error) {
        return (
            <div style={{ minHeight: '60vh' }} className='d-flex justify-content-center align-items-center'>
                <Error error="error.internalError" />
            </div>
        );
    } else if (loading) {
        return <Loader />;
    } else {
        return (
            <div id="content" className="py-5">
                <div style={{ minHeight: '50vh' }} className="container">
                    {data?.getProjectDatasTeamLeader?.length > 0
                        ?
                        <div>
                            <div><h2 className="big-title mb-5"> {data?.getProjectDatasTeamLeader[0]?.offer?.project?.title?.fr}</h2></div>
                            {data?.getProjectDatasTeamLeader?.map((mission, index) => {
                                return <ExpertCard key={index} expert={mission?.expert} offer={mission?.offer} mission={mission} evaluationEmployee={mission?.evaluationEmployee} />
                            })}
                        </div>
                        :
                        <div>
                            <NoResultTemplate title={NO_EVALUATION_TITLE} text={NO_EVALUATION_TEXT} />
                        </div>
                    }
                </div>
            </div>
        );
    }
};

export default HomeTeamLeader;