import React from "react";
import { Form } from "react-bootstrap";

const BtnDowload = (props) => {
    let { path, name, extension } = props;
    if (path) {
        path = path.slice(1, path.length);
        return <a className="btn btn-export" href={process.env.REACT_APP_API_URL + "/" + path + '/' + name + '.' + extension} title="telecharger"> </a>
    } else return null;
}

const Document = (props) => {
    const docsWithNumber = ["Passport", "VitalCard"];
    const { value, formik } = props;
    const { values, setValues } = formik;
    return (
        <div className="d-colum align-items-center pb-2">
            <div className={"d-flex align-items-center subselect-toupload justify-content-between" + (props.keyInput !== 0 ? " to-upload-line" : "")}>
                {props.value[0]}
                <BtnDowload path={props.value[3]} name={props.value[0]} extension={props.value[4]} />
            </div>
            {docsWithNumber.includes(value[2]) &&
                <div className="mb-2">
                    NUMERO
                    < Form.Control
                        value={(values.supporting_documents[value[1]] && values.supporting_documents[value[1]].number)}
                        onChange={(e) => {
                            values.supporting_documents[value[1]].number = e.target.value;
                            setValues(values)
                        }}
                    />
                </div>
            }
        </div>
    );
}

const DocumentSubContainer = (props) => {
    const { value, formik } = props;
    const { values } = formik;
    const { supporting_documents } = values;
    const array = [];
    if (supporting_documents) {
        for (let i = 0; i < supporting_documents.length; i++) {
            if (supporting_documents[i].type === value) {
                array.push([supporting_documents[i].name, i, supporting_documents[i].type, supporting_documents[i].path, supporting_documents[i].extension])
            }
        }
    }
    if (array.length > 0) {
        return (
            <div className="px-3 mb-4 py-3" style={{ backgroundColor: "#f7f7f7" }}>
                {array.map((value, key) => (
                    <Document value={value} key={key} keyInput={key} formik={formik} />
                ))}
            </div>
        )
    }
    else return null;
}

export default DocumentSubContainer;