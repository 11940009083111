import React, { useContext } from "react";
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../../stores/collapseStore";
import { Mutation } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { generalConstants } from "../../../constants/general.constants";
import * as utils from "../../../utils/utils";
import { Form } from "react-bootstrap";
import { Formik } from 'formik';
import { UPSERT_SUPP_DOC } from "../../../mutations/upsertExpert";
import DocumentsToUploadComponent from "./sections/DocumentsToUploadComponent";
import { ExpertDetailsContext } from "../../../stores/expertsDetailsStore";

const SupportingDocuments = () => {
    const { MODIFY } = generalConstants;
    const collapseContext = useContext(CollapseContext);
    const {state} = useContext(ExpertDetailsContext);
    const collapseProps = useCollapse({ isOpen: collapseContext.state.supporting_documents });
    const { getToggleProps, getCollapseProps } = collapseProps;
    return (
        <Mutation
            mutation={UPSERT_SUPP_DOC}
            onError={(error) => { console.log("error pour supp_doc") }}
        >
            {(docToUploadCallback, { error, loading }) => {

                return (
                    <Formik
                        initialValues={state.expert}
                        enableReinitialize
                        render={formikBag => {
                            return (
                                <section>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <div className="d-flex align-items-center">
                                            <span className="icon-number"><b>6</b></span>
                                            <h2 className="mb-0"><FormattedMessage id='menu.documentToUpload' defaultMessage="Not translated" /></h2>
                                        </div>
                                        <button className="btn btn-civipol-white" {...getToggleProps({
                                            onClick: () => {
                                                collapseContext.methods.setCollapse("supporting_documents", !collapseContext.state.supporting_documents);
                                                window.scrollTo(0, collapseContext.state.yPos);
                                            }
                                        })}>{MODIFY}</button>
                                    </div>
                                    <section name="supporting_documents" {...getCollapseProps()} onTransitionEnd={(e) => {
                                        for (let values in collapseContext.state) {
                                            if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                e.target.style.height = ""
                                                utils.scrollTo(values);
                                            }
                                        }
                                    }}>
                                        <div className="card">
                                            <div className="card-body">
                                                <Form onSubmit={formikBag.handleSubmit}>
                                                    <div className="profile-sections to-upload">
                                                        <DocumentsToUploadComponent {...formikBag} />
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            )
                        }} />
                )
            }}
        </Mutation>

    )
}

export default SupportingDocuments;