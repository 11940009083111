import React, { useState } from 'react';
import { GET_EXPERTS_BY_PAGE } from '../../../queries/getExperts';
import { useQuery, useMutation } from '@apollo/react-hooks';
import client from '../../../ApolloSetup';
import { useParams, Link, useHistory } from 'react-router-dom';
import { expertConstants } from "../../../constants/experts.contants";
import { template } from "../../../constants/templates.constants";
import moment from 'moment';
import { UNLOCK_EXPERT } from '../../../mutations/upsertExpert';
import NoResultTemplate from '../../templates/NoResultTemplate';
import ErrorTemplate from '../../templates/ErrorTemplate';
import { Loader } from '../../loader/Loader';

const { DATE_CREATION, LAST_MODIFICATION, UNLOCK_ALL_BUTTON } = expertConstants;
const { NO_ACCOUNT_BLOCKED_TITLE, NO_ACCOUNT_BLOCKED_TEXT } = template;

const ExpertsBlockedList = () => {
    const params = useParams();
    const [selected, setSelected] = useState([]);
    const history = useHistory();
    const { loading, error, data, refetch } = useQuery(GET_EXPERTS_BY_PAGE, {
        variables: {
            filters: {
                status: 'BLOCKED',
            },
            page: parseInt(params.page)
        },
        fetchPolicy: "cache-and-network",
        client
    });

    const unlock = () => {
        client.mutate(
            {
                mutation: UNLOCK_EXPERT,
                variables: {
                    expertsIDs: selected
                },
                fetchPolicy: "no-cache",
            }
        ).then(() => history.push('/home'))
    }

    const selectAll = ({ target }) => {
        let tmp = [];
        if (target.checked) {
            for (const key in data?.getExperts?.experts) {
                const expert = data.getExperts.experts[key];
                if (!selected?.includes(expert.id)) {
                    tmp.push(expert.id);
                }
            }
        }
        setSelected(tmp);
    };

    const ExpertBlockedCard = ({ datas }) => {
        const { firstName, lastName, profile_pic, id, createdAt, updatedAt } = datas;

        const handleSelect = ({ target }) => {
            if (target.checked) {
                setSelected([...selected, id]);
            } else {
                let tmp = [...selected];
                const indexOfId = tmp.indexOf(id);
                tmp.splice(indexOfId, 1);
                setSelected(tmp);
            }
        };

        return (
            <div className="col">
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="row flex-row justify-content-between align-items-start">
                            <div className="col-8 mb-4 mb-lg-0">
                                <div className="d-flex justify-content-start align-items-center mb-4">
                                    <div className="rounded-circle profile-picture" style={profile_pic ? { backgroundImage: 'url(' + process.env.REACT_APP_API_URL + profile_pic.substring(1, profile_pic.length) + ')' } : null}>
                                    </div>
                                    <div className="d-flex flex-column ml-4">
                                        <div className="d-flex">
                                            <h4 className="profile-name">{`${firstName} ${lastName}`}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-start align-items-start">
                                    <div className="information-expert mr-4 mr-lg-5">
                                        <label className="text-uppercase">{DATE_CREATION}</label>
                                        <h4 className="mb-0">{moment(createdAt).format("DD/MM/YYYY")}</h4>
                                    </div>
                                    <div className="information-expert mr-4 mr-lg-5">
                                        <label className="text-uppercase">{LAST_MODIFICATION}</label>
                                        <h4 className="mb-0">{moment(updatedAt).format("DD/MM/YYYY")}</h4>
                                    </div>
                                    <div className="information-expert mr-4 mr-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 d-flex flex-column justify-content-end">
                                <Link className="text-decoration-none btn btn-civipol-white mb-3" to={`/experts/expert/${id}`}>Voir le profil</Link>
                                <div className="d-flex justify-content-end custom-control custom-checkbox custom-checkbox-fat">
                                    <input type="checkbox"
                                        className="custom-control-input"
                                        id={"choice1-checked-" + id}
                                        onChange={handleSelect}
                                        name={id}
                                        checked={selected.includes(id)}
                                    />
                                    <label className="custom-control-label" htmlFor={"choice1-checked-" + id}>Selectionner</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div id="content" className="py-5" style={{ minHeight: '50vh' }}>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                        <h2 className="big-title mb-5">EXPERTS BLOQUÉS</h2>
                    </div>
                </div>
                {loading && <Loader />}
                {error && <ErrorTemplate />}
                {(!loading && !error && data?.getExperts?.experts?.length === 0) && <NoResultTemplate title={NO_ACCOUNT_BLOCKED_TITLE} text={NO_ACCOUNT_BLOCKED_TEXT} />}
                {(!loading && !error && data?.getExperts?.experts?.length > 0) &&
                    <>
                        <div className="d-flex justify-content-end">
                            {selected?.length > 0 && <button onClick={() => { unlock(); refetch(); }} className="btn btn-civipol mr-3">{UNLOCK_ALL_BUTTON}</button>}
                            <div className="d-flex justify-content-end custom-control custom-checkbox custom-checkbox-fat">
                                <input type="checkbox"
                                    className="custom-control-input"
                                    id={"choice1-checked-all"}
                                    onChange={selectAll}
                                    checked={selected.length === data.getExperts.experts.length}
                                />
                                <label className="custom-control-label" htmlFor={"choice1-checked-all"}>Selectionner tout</label>
                            </div>
                        </div>
                        {data?.getExperts?.experts?.map((expert, key) => <ExpertBlockedCard key={key} datas={expert} index={key} />)}
                    </>
                }
            </div>
        </div>
    );
};

export default ExpertsBlockedList;