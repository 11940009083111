import React from 'react';
import { Text, View, Link } from '@react-pdf/renderer';
import { styles, sectionTitleStyle } from '../styles/styles';
import { messages } from '../../../static/messages/messages';

const PDFSupportingDocuments = (props) => {
    const { supporting_documents } = props;
    return (
        <View wrap={false}>
            <View style={sectionTitleStyle.container}>
                <View style={sectionTitleStyle.sectionTitlePastille}>
                    <Text>7</Text>
                </View>
                <Text style={sectionTitleStyle.sectionTitle}>Pieces justificatives</Text>
            </View>
            <View style={styles.sectionProfile}>
                {supporting_documents.length > 0 ?
                    supporting_documents?.map((document, key) => {
                        if (document?.type !== "CV")
                            return (
                                <View key={key}>
                                    <Text>{messages.fr.expertForm.documentToUpload[document?.type]}</Text>
                                    <Link style={styles.normalBlackTextDin} src={`${props.env.REACT_APP_API_URL}${document?.path}/getFile/${document?.name}.${document?.extension}+${document?.id}`}>Voir le fichier</Link>
                                </View>
                            );
                    })
                    :
                    <Text style={styles.normalBlackText}>
                        Non renseigné
                    </Text>
                }
            </View>
        </View>

    );

};

export default PDFSupportingDocuments;