import {translate} from "./translation.constants";

export const expertConstants = {
    DATE_CREATION: translate("expert.creationDate"),
    LAST_MODIFICATION: translate("expert.lastModification"),
    MISSION_FOR_CIVIPOL: translate("expert.missionsForCivipol"),
    DATE_ACCEPTED: translate("expert.dateAccepted"),
    DATE_POSTULAT: translate("expert.dateCandidature"),
    UNLOCK_EXPERT_BUTTON: translate("expert.unlockButton"),
    UNLOCK_ALL_BUTTON: translate("expert.unlockAllButton")
}
