import React, {useReducer, createContext} from "react";

const initialState = {
    modifications: [],
    consultations: [],
}

const logsContext = createContext(initialState);

const reducer = (state, action) => {
    const {target} =  action;

    switch(action.type) {
        case "setModifications" : {
            return {
                ...state, 
                modifications: target
            }
        }
        case "setConsultations" : {
            return {
                ...state, 
                consultations: target
            }
        }
        case "reinitState": {
            return {
                modifications: [],
                consultations: []
            }
        }
        default: return state
    }
}

const LogsProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    
    return (
        <logsContext.Provider value={{state, dispatch}}>
            {children}
        </logsContext.Provider>
    )
}

export {logsContext, LogsProvider};