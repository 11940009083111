import React, {useReducer, createContext} from "react";

const initialState = {
    nb_registered_on_week: null,
    nb_total_expert: null,
    nb_project_without_attributed_offer: null,
    experts_to_evaluate: null
}

const statisticContext = createContext(initialState);

const reducer = (state, action) => {
    const {target} =  action;

    switch(action.type) {
        case "setStats" : {
            return {
                ...target
            }
        }
        
        default: return state
    }
}

const StatisticProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    
    return (
        <statisticContext.Provider value={{state, dispatch}}>
            {children}
        </statisticContext.Provider>
    )
}

export {statisticContext, StatisticProvider};