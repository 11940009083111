import React, { useEffect, useContext } from "react";
import client from "../../../../ApolloSetup";
import { GET_CONSULTATIONS_LOGS } from "../../../../queries/getLogs";
import { withRouter } from "react-router";
import { logsContext } from "../../../../stores/logsStore";
import ConsultationsComponent from "./ConsultationComponent";
const getConsultations = (id_expert, { dispatch }) => {

    client
        .query({
            query: GET_CONSULTATIONS_LOGS,
            variables: { expertID: id_expert }
        })
        .then(values => { dispatch({ type: "setConsultations", target: values.data.getLogsConsultations }) })
        .catch(err => console.log(err));
}

const ConsultationsContainer = (props) => {

    const logsStore = useContext(logsContext);
    const idExpert = props.match.params;
    
    useEffect(() => {
        getConsultations(idExpert.id, logsStore);
    }, [])

    if (logsStore.state.consultations && logsStore.state.consultations.length > 0) {
        return (
            <div>
                {logsStore.state.consultations.map((value, key) => {
                    return <ConsultationsComponent {...value} key={key} />
                })
                }
            </div>
        );
    } else return null;
}

export default withRouter(ConsultationsContainer);