import React, { useState } from "react";
import { abilitiesConstants } from "../../../../constants/profile-expert.constants";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { messages } from "../../../../static/messages/messages";
import { TranslateContextWrapper } from '../../../../stores/TranslateContext';

const sectionMessageName = "expertForm.abilities."

const {
    EXPERTISE_DOMAIN_TITLE,
    EXPERTISE_DOMAIN_YEARS,
    EXPERTISE_DOMAIN_DIPLOMA,
} = abilitiesConstants;

const DomainExpertise = (props) => {
    const [state, setState] = useState({ skills: [] });

    const [elemsToNotDisplay, setElemToNotDisplay] = useState([]);

    //permet de supprimer un tag si on a cliqué sur la croix
    const deleteSelection = (e, value) => {
        state.skills[e.target.id] = null;
        state.skills = state.skills.filter((el) => {
            return el != null
        })
        props.values.skills[e.target.id] = null;
        props.values.skills = props.values.skills.filter((el) => {
            return el != null
        })
        props.setValues({ ...props.values })
        reinsertInTagsField(value.type)
        setState(prevState => { return { ...prevState, ...state } })
    }
    //retourne les tags selectionnés
    const ExpertiseSelections = () => {
        return (
            <div>
                <Form.Label className={(props.errors.skills && props.touched.skills) ? "is-invalid-label" : null}>
                    <p><FormattedMessage id={sectionMessageName + "selectDomain"} defaultMessage="Not translated" /></p>
                </Form.Label>
                {(props.errors.skills && props.touched.skills) ? <p className="is-invalid"><FormattedMessage id={sectionMessageName + ".error"} defaultMessage="Not translated" /></p> : null}
                <div>
                    {   //map le tableau de tags selectionnés
                        props.values.skills.map((value, key) => {
                            if (value !== null && props.values.skills) {
                                //permet de recuperer la traduction du tag
                                let strBtn = props.translateContext.translateMessage(sectionMessageName + "tags." + value.type)

                                return (
                                    <Form.Group className={"d-flex flex-column justify-content-between expertise-field-container"} key={key}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="tag-selected d-flex align-items-center justify-content-center text-center pl-3 pr-4 py-2 mr-auto">
                                                <img src={require("../../../../static/images/button/icon_addedSkill.svg")} alt="encoche-tag" className="mr-2" />
                                                <span className="text-white font-weight-bold">{strBtn}</span>
                                            </div>
                                            <img
                                                className='btn transparent btn-delete-white px-0 py-0 ml-2'
                                                id={key}
                                                onClick={(e) => deleteSelection(e, value)}
                                                src={require("../../../../static/images/button/icon-remove.svg")}
                                                alt="close_icon"
                                            />
                                        </div>
                                        <div className="row flex-row align-items-center my-3">
                                            <div className="col-12 col-md-6 mb-2 mb-md-0">
                                                <div className="d-flex align-items-center">
                                                    <label className="mb-0 mr-3">{EXPERTISE_DOMAIN_YEARS}</label>
                                                    <div style={{ width: "80px" }}>
                                                        <Form.Control
                                                            //input pour la selection du nombre d'années d'experiences
                                                            // className="w-100"
                                                            as="select"
                                                            onChange={e => {
                                                                props.values.skills[key].year_experience = parseInt(e.target.value)
                                                                props.setValues({
                                                                    ...props.values
                                                                })
                                                            }}
                                                            value={props.values.skills[key].year_experience}
                                                        >
                                                            {
                                                                //40 années
                                                                [...Array(40)].map((value, key) => {
                                                                    return (
                                                                        <option
                                                                            key={key}
                                                                        >{key + 1}
                                                                        </option>
                                                                    )
                                                                })}
                                                        </Form.Control>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <label className="mb-0 mr-3">{EXPERTISE_DOMAIN_DIPLOMA}</label>
                                                    <div>
                                                        <Form.Group>
                                                            <Form.Check className="custom-control custom-checkbox custom-checkbox-fat" type='checkbox' inline>
                                                                <Form.Check.Input
                                                                    type='checkbox' name="diploma" className="custom-control-input" id={"diploma" + key} value={props.values.skills[key].diploma}
                                                                    checked={props.values.skills[key].diploma}
                                                                    onChange={(e) => {
                                                                        props.values.skills[key].diploma = !props.values.skills[key].diploma;
                                                                        props.setValues(props.values)
                                                                    }}
                                                                />
                                                                <Form.Check.Label htmlFor={"diploma" + key} className='custom-control-label'>
                                                                </Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                )
                            } return null
                        })}
                </div>
            </div>
        )
    }


    //check dans les tags si ils existent dans la globale pour voir si on les réaffiche ou pas
    const reinsertInTagsField = (value) => {
        for (var i = 0, count = elemsToNotDisplay.length; i < count; i++) {
            if (elemsToNotDisplay[i] === value) {
                elemsToNotDisplay.splice(i, 1);
                i--;
            }
        }
        //fonction qui enregistre les éléments a ne pas afficher dans le champs de tags une fois qu'ils sont séléctionnés
        setElemToNotDisplay(elemsToNotDisplay);
    }

    //retourne false si on a deja cliqué sur un des tags pour voir si on l'affiche dans le champs de tags
    const isNotInSkillArray = (value) => {
        if (props.values.skills) {
            for (var i = 0, count = props.values.skills.length; i < count; i++) {
                if (value === props.values.skills[i].type) {
                    return false
                }
            }
        }
        return true
    }

    const compare = (a, b) => {
        const valA = props.translateContext.translateMessage("expertForm.abilities.tags." + a);
        const valB = props.translateContext.translateMessage("expertForm.abilities.tags." + b);
        let comparison = 0;
        if (valA > valB)
            comparison = 1;
        else if (valA < valB)
            comparison = -1;
        return comparison;
    }
    //retourne le champs de tags
    const TagsField = () => {

        //recupére le champs de tags dans les traductions
        let tags = messages.fr.expertForm.abilities.tags;
        tags = Object.keys(tags);
        tags.sort(compare)
        return (
            <div className="tag-fields mb-4">
                {tags.map((value, key) => {

                    if (isNotInSkillArray(value) === true) {
                        return (
                            <button
                                className='tag-btn my-2 mx-2'
                                key={key}
                                onClick={e => {
                                    e.preventDefault();
                                    setState({
                                        ...state,
                                        skills: [
                                            ...state.skills,
                                            { year_experience: 1, type: value },
                                        ]
                                    })
                                    //on remplis les variables avec les valeurs par défault
                                    props.values.skills.push({ year_experience: 1, type: value });
                                    props.setValues({ ...props.values })
                                    //on rempli les éléments a ne pas réafficher
                                    elemsToNotDisplay.push(value)
                                    setElemToNotDisplay(elemsToNotDisplay)
                                }}
                            >
                                <FormattedMessage id={"expertForm.abilities.tags." + value} defaultMessage="Not translated" />
                            </button>)
                    } else return null;
                }
                )}
            </div>
        )
    }

    //retourne les tags séléctionnés, le champs de tags et la sélection de diplomes
    return (
        <div className='expertise-selection'>
            <h2 className="title-section-blue text-uppercase mb-4">{EXPERTISE_DOMAIN_TITLE}</h2>
            <ExpertiseSelections state={state} />
            <TagsField />
        </div>
    )
}

export default TranslateContextWrapper(DomainExpertise);