import React, { useContext, useState } from "react";
import { candidaturesConstants } from "../../../../constants/missions.constants";
import { expertConstants } from "../../../../constants/experts.contants";
import * as moment from "moment";
import { Link } from "react-router-dom";
import client from "../../../../ApolloSetup";
import { ACCEPT_CANDIDATURE, REJECT_CANDIDATURE } from "../../../../mutations/upsertMissions";
import { OfferDetailsContext } from "../../../../stores/detailsOfferStore";
import { notificationContext } from "../../../../stores/notificationsStore";
import { withRouter } from "react-router-dom";


const handleCandidatureError = ({ dispatch }, history, id_expert, graphQLErrors) => {
    for (let error in graphQLErrors) {
        if (graphQLErrors[error].message === "Already team leader assigned") {
            dispatch({ type: "setAlert", target: { element: "teamLeaderAlreadyAssigned", isCancel: false } });
        } else {
            dispatch({ type: "setAlert", target: { element: "errorAlert", isCancel: false } });
        }
    }
}

const validateCandidature = (id_expert, id_mission, { dispatch }, notificationStore, history, isTeamLeader) => {
    client.mutate({
        mutation: ACCEPT_CANDIDATURE,
        variables: { id_expert: id_expert, id_mission: id_mission, isTeamLeader }
    })
        .then(values => { dispatch({ type: "reload" }) })
        .catch(({ graphQLErrors }) => { handleCandidatureError(notificationStore, history, id_expert, graphQLErrors) });
}

const rejectCandidature = (id_expert, id_mission, { dispatch }, notificationStore, history, isMulti) => {
    client.mutate({
        mutation: REJECT_CANDIDATURE,
        variables: { id_expert: id_expert, id_mission: id_mission }
    })
        .then(() => { if (!isMulti) dispatch({ type: "reload" }) })
        .catch(err => console.log(err));
}

const rejectAllCandidatures = async (selected, offerStore) => {
    for (const key in selected) {
        await rejectCandidature(selected[key].expert_id, selected[key].mission_id, offerStore, true);
    }
    offerStore.dispatch({ type: "reload" });
};

const getPostule = (array) => {
    for (let cell in array) {
        if (array[cell]) {
            if (array[cell].staffed === "Postule") {
                return array[cell]
            }
        }
    };
    return null;
}

const CandidatureComponent = (value) => {
    const { staffed, date_postulat, expert, notificationStore, offerStore, history, selected, setSelected, index } = value;
    const [isTeamLeader, setTeamLeader] = useState(false);
    const handleSelect = ({ target }) => {
        if (target.checked) {
            setSelected([...selected, { expert_id: expert.id, mission_id: value.id }]);
        } else {
            let tmp = [...selected];
            const indexOfId = tmp.indexOf(expert.id);
            tmp.splice(indexOfId, 1);
            setSelected(tmp);
        }
    };

    const handleTeamLeader = ({ target }) => {
        setTeamLeader(target.checked);
    };

    const isChecked = () => {
        return selected.find(elem => expert.id === elem.expert_id) ? true : false;
    };

    if (staffed === "Postule") {
        if (expert) {
            const { DATE_POSTULAT } = expertConstants;
            const { BUTTON_VALIDATION, BUTTON_REJECTION, REJECT_SELECT_LABEL } = candidaturesConstants;
            const { firstName, lastName, profile_pic, id } = expert;

            let divStyle = { backgroundImage: 'url("../../../../static/images/experts-card/portrait-male-default@2x.png")' };
            if (profile_pic) {
                divStyle = { backgroundImage: 'url(' + process.env.REACT_APP_API_URL + profile_pic.substring(1, profile_pic.length) + ')' };
            }

            return (
                <div className="row flex-row">
                    <div className="col">
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row flex-row justify-content-between align-items-start">
                                    <div className="col-12 mb-4 mb-lg-0">
                                        <div className="d-flex justify-content-start align-items-center mb-4">
                                            <div className="rounded-circle profile-picture" style={divStyle || null} />
                                            <div className="d-flex justify-content-between ml-4  mr-4">
                                                <h4 className="profile-name">{`${firstName} ${lastName}`}</h4>
                                            </div>
                                            <div className="ml-4">
                                                <div className="information-expert mr-4 mr-lg-5">
                                                    <label className="text-uppercase">{DATE_POSTULAT}</label>
                                                    <h4 className="mb-0">{moment(date_postulat).format("DD/MM/YYYY")}</h4>
                                                </div>
                                            </div>
                                            <Link className="btn btn-civipol-white" to={`/experts/expert/${id}`}>VOIR LE PROFIL</Link>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center px-4">
                                            <div className="custom-control custom-checkbox custom-checkbox-fat">
                                                <input type="checkbox" className="custom-control-input" id="teamLeader" onClick={handleTeamLeader} />
                                                <label className="custom-control-label" htmlFor="teamLeader">Team Leader</label>
                                            </div>
                                            <button onClick={() => validateCandidature(id, value.id, offerStore, notificationStore, history, isTeamLeader)} className="btn btn-green mr-4">
                                                {BUTTON_VALIDATION}
                                            </button>
                                            <button onClick={() => rejectCandidature(id, value.id, offerStore, notificationStore, history)} className="btn btn-red">
                                                {BUTTON_REJECTION}
                                            </button>
                                            <div class="custom-control custom-checkbox custom-checkbox-fat">
                                                <input type="checkbox" class="custom-control-input" id="choice1-checked" onChange={handleSelect} name={index} checked={isChecked()} />
                                                <label class="custom-control-label" for="choice1-checked">{REJECT_SELECT_LABEL}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else return null;
    } else return null;
}

const CandidaturesMapper = (props) => {

    const offerStore = useContext(OfferDetailsContext);
    const notificationStore = useContext(notificationContext);
    let components = [];

    for (let candidature in props) {
        components.push(
            <CandidatureComponent
                {...props[candidature]}
                key={candidature}
                index={candidature}
                offerStore={offerStore}
                notificationStore={notificationStore}
                history={props.history}
                selected={props.selected}
                setSelected={props.setSelected}
            />
        );
    }
    return (components);
}

const CandidaturesContainer = (props) => {
    let isPostule = getPostule(props);
    const [selected, setSelected] = useState([])
    const offerStore = useContext(OfferDetailsContext);

    const handleSelectAll = ({ target }) => {
        if (target.checked) {
            let tmp = [];
            for (let candidature in props) {
                if (props[candidature] && props[candidature].id) {
                    tmp.push({
                        expert_id: props[candidature].expert.id,
                        mission_id: props[candidature].id
                    });
                }
            }
            setSelected(tmp);
        } else {
            setSelected([]);
        }
    };

    const isCheckedAll = () => {
        let count = 0;
        for (const candidature in props) {
            if (props[candidature] && props[candidature].staffed && props[candidature].staffed === 'Postule') {
                count++;
            }
        }
        if (count === selected.length) {
            return true;
        }
        return false;
    };

    if (isPostule) {
        const { REJECT_SELECTION_BUTTON, REJECT_ALL_LABEL } = candidaturesConstants;
        return (
            <div className="row">
                <div className="col mb-4">
                    <h2 className="big-title mb-5"> CANDIDATURES </h2>
                    <div className='row mb-3'>
                        <div className='col d-flex justify-content-end align-items-center'>
                            {selected.length > 0 && <button className="btn btn-civipol-white mr-4" onClick={async () => await rejectAllCandidatures(selected, offerStore)}>{REJECT_SELECTION_BUTTON}</button>}
                            <div class="custom-control custom-checkbox custom-checkbox-fat">
                                <input type="checkbox" class="custom-control-input" id="choice1-checked" onClick={handleSelectAll} checked={isCheckedAll()} />
                                <label className="custom-control-label" htmlFor="choice1-checked">{REJECT_ALL_LABEL}</label>
                            </div>
                        </div>
                    </div>
                    <CandidaturesMapper {...props} selected={selected} setSelected={setSelected} />
                </div>
            </div>
        )
    } else return null;

}
export default withRouter(CandidaturesContainer);