import React from "react";
import {FormattedMessage} from "react-intl";
import * as utils from "../../utils/utils";

const Error = (props) => {
    const { error } = props;
    if (!error || utils.is_empty_obj(error)) return null;
    else return <p className={"text-danger"}> <FormattedMessage id={error} /> </p>
}

export default Error;