import React from "react";
import { FormattedMessage } from 'react-intl';
import { getIn } from 'formik';
import { Form } from "react-bootstrap";

const Input = ({ name, label, padding, columns, formik, type, facultatif, maxlength }) => {
    const { errors, handleBlur, setFieldTouched, touched } = formik;
    
    //le name correspond a la section et le champs attribué
    let allPath = name.split('.');
    const section = allPath[0];
    const champs = allPath[1];
    return (
        <div className={((columns) ? columns : "col-12 col-md-6") + ((padding) ? padding : "")}>
                <div className="form-row">
                    <div className="col-12 col-lg-12 form-group mb-4">
                        <Form.Label className={touched && touched[section] && touched[section][champs] && errors[section] && errors[section][champs] ? "is-invalid-label" : null}>
                                <FormattedMessage id={label} defaultMessage="Not translated"/>
                                {facultatif ? <small className="text-muted ml-2"><FormattedMessage id="general.optional" defaultMessage="Not translated" /></small> : null}
                        </Form.Label>
                        <Form.Control
                            id={name}
                            value={(formik.values[section][champs]) ? (formik.values[section][champs]) : ""}
                            type={(type === "number") ? "number" : "text"}
                            name={name}
                            onChange={formik.handleChange}
                            isInvalid={getIn(errors, name)}
                            className="form-control"
                            onBlur={handleBlur}
                            onKeyUp={() => setFieldTouched(name, true)}
                            maxLength={maxlength}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" className="invalid-feedback">
                            {touched[section] && touched[section][champs] && errors[section] && errors[section][champs] ? (errors[section][champs] === "validation.notvalid") ? <p>Ce champs n'est pas valide</p> : <p>{errors[section][champs]}</p> : null}
                        </Form.Control.Feedback>
                    </div>
                </div>
        </div>
    )
}

export default Input;