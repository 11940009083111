import gql from "graphql-tag";

const SIGN_IN = gql`
    mutation signInEmployee($login: String!, $password: String!){
        signInEmployee(login: $login, password: $password){
            rights
            expert {
                email_1
                projectLeader
            }
            employe {
                groups
                login
                email
            }
        }
    }
`;

export { SIGN_IN };