import React, { useEffect, useContext } from "react";
import { GET_EVALUATION_EXPERT_BY_PAGE } from "../../queries/getEvaluations";
import client from "../../ApolloSetup";
import { arrayEvaluationsContext } from "../../stores/arrayEvaluationsStore";
import { withRouter } from "react-router-dom";
import { Loader } from "../loader/Loader";
import EvaluationExpertComponent from "./EvaluationExpertComponent";

const getEvaluationAsync = async (id_mission, store) => {

    const { dispatch } = store;

    client.query({
        query: GET_EVALUATION_EXPERT_BY_PAGE,
        variables: { id_mission: id_mission }
    })
        .then(values => {dispatch({ type: "setArray", target: values.data.getEvaluationByPage })})
        .catch(err => dispatch({ type: "setError", target: err }));
}

const SeeEvaluations = (props) => {

    let store = useContext(arrayEvaluationsContext);
    const { id_mission } = props.match.params;
    const { state } = store;

    useEffect(() => {
        getEvaluationAsync(id_mission, store);
    }, []);
    
    if (state.isLoading) return <div><Loader class="center" /></div>
    else if (state.isError) return <div>ERROR</div>
    else {
        return (
            <div id="content" className="py-5">
                <div className="container">
                    <EvaluationExpertComponent />
                </div>
            </div>
        );
    }

}
export default withRouter(SeeEvaluations);