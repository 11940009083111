import React from "react";
import moment from "moment";
import { projectCardConstant } from "../../../../constants/projects.constants";
import { TranslateContextWrapper } from "../../../../stores/TranslateContext";
import { Link } from "react-router-dom";

const {
    START_DATE,
    END_DATE,
    SEE_PROJECT_BUTTON,
    NO_OFFER,
    PROJECT_OVER,
    SEE_DETAILS_BUTTON,
} = projectCardConstant;

const parseChronology = (chronology) => {
    if (chronology) {
        let tmp = chronology.split(",");
        return {
            start: tmp[0].replace("\"", ""),
            end: tmp[tmp.length - 1].replace("\"", "")
        }
    } else {
        return {
            start: "",
            end: ""
        }
    }
}

const getDefaultImage = (themes) => {
    switch (themes.fr.length === 0) {
        case themes.id_taxo === "72": {
            return require("../../../../static/images/projects-card/theme-72.jpg");
        }
        case themes.id_taxo === "73": {
            return require("../../../../static/images/projects-card/theme-73.jpg");
        }
        case themes.id_taxo === "75": {
            return require("../../../../static/images/projects-card/theme-75.jpg");
        }
    }
}

const setStatus = (project_status, offers) => {
    if (project_status?.en === 'Finished') {
        return "OVER";
    } else if (offers.length === 0 || (offers.missions && offers.missions.length === 0)) {
        return "NONE";
    } else {
        let count = offers.length;
        for (let i = 0; i < offers.length; i++) {
            if (offers[i].missions.length > 0) {
                for (let j = 0; j < offers[i].missions.length; j++) {
                    if (
                        offers[i].missions[j].staffed === "Agreed"
                        || offers[i].missions[j].staffed === "Terminated"
                        || offers[i].missions[j].staffed === "InMission"
                    ) {
                        count--;
                    }
                }
            }
            if (offers[i].soft_delete) {
                count--;
            }
        }
        return (count < 0) ? 0 : count;
    }
}

const ProjectComponent = (props) => {
    const { title, primary_image, themes, link, chronology, offers, id, project_status } = props.value;
    const { start, end } = parseChronology(chronology);
    let default_img = getDefaultImage(themes);
    let img = (primary_image.fr.length > 0) ? process.env.REACT_APP_CIVIPOL + primary_image["fr"] : default_img;
    let status = setStatus(project_status, offers);
    let offersNotAttrib = "";
    if (typeof status === "number") {
        offersNotAttrib = `Il y a ${status} offre${(status > 1) ? "s" : ""} sans expert sélectionné.`;
    }

    return (
        <div className="row mb-3 project-card">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-2 ">
                                <img style={{ maxHeight: "150px" }} className="img-fluid" src={img} alt="illu-projet" />
                            </div>
                            <div className="col">
                                <div className="row flex-column justify-content-between">
                                    <div className="col">
                                        <div className="row mb-2">
                                            <div className="col-7">
                                                <h2 className="title-section-blue text-left text-uppercase mb-4">{title.fr}</h2>
                                            </div>
                                            <div className="col">
                                                <label className="text-uppercase d-flex justify-content-center">{START_DATE}</label>
                                                <h4 className="text-uppercase text-center">{moment(start).format("DD/MM/YYYY")}</h4>
                                            </div>
                                            <div className="col">
                                                <label className="text-uppercase d-flex justify-content-center">{END_DATE}</label>
                                                <h4 className="text-uppercase text-center">{moment(end).format("DD/MM/YYYY")}</h4>
                                            </div>
                                        </div>
                                        <div className="row flex-row align-items-end justify-content-between">
                                            <div className="col d-flex align-items-center text-uppercase">
                                                {(status === "OVER") ? <span>{PROJECT_OVER}</span> : (status === "NONE") ? <span>{NO_OFFER}</span> : <span>{offersNotAttrib}</span>}
                                            </div>
                                            <div className="col d-flex justify-content-end">
                                                <Link className="btn btn-civipol-white mr-2" to={"/projects/detail-project/" + id}>{SEE_DETAILS_BUTTON}</Link>
                                                <a href={"http://civipol.fr/" + link["fr"]} target="_blank" rel="noopener noreferrer" className="btn btn-civipol-white">{SEE_PROJECT_BUTTON}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TranslateContextWrapper(ProjectComponent);