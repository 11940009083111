import React from "react";
import moment from "moment";
import Female from '../../../../../static/images/expert-card/portrait-female-default@2x.png';
import Male from '../../../../../static/images/expert-card/portrait-male-default@2x.png';

const ConsultedComponent = (props) => {

    const { firstName, lastName, profile_pic, civility} = props.expert;
    const { date_consultation } = props;
    let divStyle = "";
    if (!profile_pic && civility === 'FEMALE') {
        divStyle = { backgroundImage: `url(${Female})` };
    }
    if (!profile_pic && civility === 'MALE') {
        divStyle = { backgroundImage: `url(${Male})` };
    }
    if (profile_pic) {
        divStyle = { backgroundImage: 'url(' + process.env.REACT_APP_API_URL + profile_pic.substring(1, profile_pic.length) + ')' };
    }
    return (
        <div className="card animate">
            <div className="card-body">
                <div className="d-flex justify-content-start align-items-center">
                    <div className="rounded-circle profile-picture" style={divStyle || null}>
                    </div>
                    <div>
                        <h4 className="ml-4 profile-name mb-2 text-capitalize"> {`${firstName.toLowerCase()} ${lastName.toLowerCase()}`}</h4>
                        <span className="ml-4 d-flex profile-view text-uppercase mb-2">Profil consulté le {moment(date_consultation).format("DD/MM/YYYY")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConsultedComponent;