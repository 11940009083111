import React, { useContext, useEffect } from "react";
import { evaluationConstants, notationsConstants, generalNotationsConstants } from "../../constants/evaluation.constants";
import { FormattedMessage } from "react-intl";
import { TranslateContextWrapper } from "../../stores/TranslateContext";
import { Table } from "react-bootstrap";
import { messages } from "../../static/messages/messages";
import { evaluationContext } from "../../stores/evaluationStore";
import { GET_EVALUATION_EMPLOYE } from "../../queries/getEvaluations";
import { SET_EVALUATION_EMPLOYE } from "../../mutations/setEvaluations";
import client from "../../ApolloSetup";
import { withRouter } from "react-router-dom";
import { Loader } from "../loader/Loader";
import { notificationContext } from "../../stores/notificationsStore";
import { Link } from "react-router-dom";
import { candidaturesConstants } from "../../constants/missions.constants";
import * as moment from "moment";
import { getStorageUser } from "../utils/localStorage";

const {
    DATE_TITLE,
    APTITUDES_TITLE,
    GENERAL_TITLE,
    CHIEF_POLE,
    RESPONSABLE_PROJECT,
    COUNTRY,
    REGISTER,
    PROJECT_CODE,
    MISSION_INFORMATION,
    COMPETENCES_TITLE
} = evaluationConstants;

const { BUTTON_SEE_OFFER } = candidaturesConstants;

const {
    NON_APPLICABLE,
    NOTIONS,
    CONFIRMED,
    MAITRISE,
    EXPERT,
} = notationsConstants;

const {
    AMELIORABLE,
    CONFORM,
    DEPASSE,
} = generalNotationsConstants;

const pathCompetences = "evaluationExpert.competencesCriterias.";
const pathAptitudes = "evaluationExpert.aptitudesCriterias.";

const onChangeCriterias = (e, dispatch, section) => {
    dispatch({ type: "setCriteria", target: e.target, section: section });
}

const onChangeInput = (e, dispatch) => {
    dispatch({ type: "setInput", target: e.target });
}

const CompetencesCriteriasMapper = (props) => {

    const { dispatch } = props.context;
    const criterias = messages.fr.evaluationExpert.competencesCriterias;
    let table = Object.keys(criterias).map((value, key) => {
        return (
            <tr key={key}>
                <td><FormattedMessage id={pathCompetences + value} /></td>
                <td >
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input defaultChecked onChange={(e) => onChangeCriterias(e, dispatch, "competencesCriterias")} type="radio" value='-1' name={value} id={"non_applicable-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"non_applicable-" + value}></label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input onChange={(e) => onChangeCriterias(e, dispatch, "competencesCriterias")} type="radio" value='0' name={value} id={"notions-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"notions-" + value}></label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input onChange={(e) => onChangeCriterias(e, dispatch, "competencesCriterias")} type="radio" value='1' name={value} id={"confirmed-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"confirmed-" + value}></label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input onChange={(e) => onChangeCriterias(e, dispatch, "competencesCriterias")} type="radio" value='2' name={value} id={"maitrise-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"maitrise-" + value}></label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input onChange={(e) => onChangeCriterias(e, dispatch, "competencesCriterias")} value="3" type="radio" name={value} id={"expert-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"expert-" + value}></label>
                        </div>
                    </div>
                </td>

            </tr>
        )
    })
    return (
        <tbody>
            {table}
        </tbody>
    )
}

const AptitudesCriteriasMapper = (props) => {
    const { dispatch } = props.context;
    const criterias = messages.fr.evaluationExpert.aptitudesCriterias;
    let table = Object.keys(criterias).map((value, key) => {
        return (
            <tr key={key}>
                <td><FormattedMessage id={pathAptitudes + value} /></td>
                <td>
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input defaultChecked onChange={(e) => onChangeCriterias(e, dispatch, "aptitudesCriterias")} type="radio" value='-1' name={value} id={"non_applicable-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"non_applicable-" + value}></label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input type="radio" onChange={(e) => onChangeCriterias(e, dispatch, "aptitudesCriterias")} value='0' name={value} id={"notions-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"notions-" + value}></label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input type="radio" onChange={(e) => onChangeCriterias(e, dispatch, "aptitudesCriterias")} value='1' name={value} id={"confirmed-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"confirmed-" + value}></label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input type="radio" onChange={(e) => onChangeCriterias(e, dispatch, "aptitudesCriterias")} value='2' name={value} id={"maitrise-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"maitrise-" + value}></label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center align-item-center">
                        <div className="custom-control custom-radio custom-radio-fat text-center">
                            <input type="radio" onChange={(e) => onChangeCriterias(e, dispatch, "aptitudesCriterias")} value="3" name={value} id={"expert-" + value} className="custom-control-input"></input>
                            <label className="custom-control-label" htmlFor={"expert-" + value}></label>
                        </div>
                    </div>
                </td>
            </tr>
        )
    })
    return (
        <tbody>
            {table}
        </tbody>
    )
}

const EvaluationExpert = (props) => {

    const { login, id_offer } = props;
    const { translateMessage } = props.translateContext;
    const evaluationStore = useContext(evaluationContext);
    const { state } = evaluationStore;

    const handleSelectTrialPeriod = ({ target }) => {
        evaluationStore.dispatch({ type: "setTrialPeriod", target });
    };

    return (
        <div id="content" className="py-5">
            <div className="container">
                <div className="row justify-content-center mb-4">
                    <div className="col-12 mb-3">
                        <h2 className="big-title mb-5">{translateMessage("evaluationExpert.evaluationOf", "Not Translated", { firstName_expert: state.firstName, lastName_expert: state.lastName })}</h2>
                        <h3>{state?.projectName}</h3>
                        <div className="d-flex">
                            <div className="information-expert mr-3">
                                <label className="text-uppercase">
                                    {PROJECT_CODE}
                                </label>
                                <div >
                                    <input onChange={(e) => onChangeInput(e, evaluationStore.dispatch)} name="codeProject" type="text" className="form-control w-75" id="default-input" />
                                </div>
                            </div>
                            <div className="information-expert mr-3">
                                <label className="text-uppercase">
                                    {COUNTRY}
                                </label>
                                <input type="text" readOnly className="form-control-plaintext" value={state.countryIntervention || ""} />
                            </div>
                        </div>
                    </div>
                    <div className='col align-items-center p-5' style={{ backgroundColor: 'rgb(247, 247, 247)' }}>
                        <h3 style={{ fontSize: '2rem' }} className='big-title mb-5'>{MISSION_INFORMATION}</h3>
                        <div className="d-flex justify-content-between mb-3">
                            <h3>{state.offerName}</h3>
                            <Link className='btn btn-civipol-white' to={`/details-offer/${id_offer}`}>{BUTTON_SEE_OFFER}</Link>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <div className="information-expert mr-3">
                                <label className="text-uppercase">
                                    {DATE_TITLE}
                                </label>
                                <div>{moment().format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="information-expert mr-3">
                                <label className="text-uppercase">
                                    {CHIEF_POLE}
                                </label>
                                <div >
                                    <input onChange={(e) => onChangeInput(e, evaluationStore.dispatch)} name="chiefPole" type="text" className="form-control w-100" id="default-input" />
                                </div>
                            </div>
                            <div className="information-expert mr-3">
                                <label className="text-uppercase">
                                    {RESPONSABLE_PROJECT}
                                </label>
                                <div >
                                    <input type="text" readOnly className="form-control-plaintext" value={login || ""} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column mb-5">
                    <h2 className="title-section-blue text-uppercase mb-4">{COMPETENCES_TITLE}</h2>
                    <div className="mb-5">
                        <div className="card">
                            <div className="card-body">
                                <Table>
                                    <thead >
                                        <tr>
                                            <th style={{ borderTop: 'none' }}>
                                            </th>
                                            <th style={{ borderTop: 'none' }}>
                                                <span className="text-uppercase mb-0">{NON_APPLICABLE}</span>
                                            </th>
                                            <th style={{ borderTop: 'none' }}>
                                                <span className="text-uppercase mb-0">{NOTIONS}</span>
                                            </th>
                                            <th style={{ borderTop: 'none' }}>
                                                <span className="text-uppercase mb-0">{CONFIRMED}</span>
                                            </th>
                                            <th style={{ borderTop: 'none' }}>
                                                <span className="text-uppercase mb-0">{MAITRISE}</span>
                                            </th>
                                            <th style={{ borderTop: 'none' }}>
                                                <span className="text-uppercase mb-0">{EXPERT}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <CompetencesCriteriasMapper context={evaluationStore} />
                                </Table>
                            </div>
                        </div>
                    </div>
                    <h2 className="title-section-blue text-uppercase mb-4">{APTITUDES_TITLE}</h2>
                    <div className="card">
                        <div className="card-body">
                            <Table>
                                <thead >
                                    <tr>
                                        <th style={{ borderTop: 'none' }}>
                                        </th>
                                        <th style={{ borderTop: 'none' }}>
                                            <span className="text-uppercase mb-0">{NON_APPLICABLE}</span>
                                        </th>
                                        <th style={{ borderTop: 'none' }}>
                                            <span className="text-uppercase mb-0">{NOTIONS}</span>
                                        </th>
                                        <th style={{ borderTop: 'none' }}>
                                            <span className="text-uppercase mb-0">{CONFIRMED}</span>
                                        </th>
                                        <th style={{ borderTop: 'none' }}>
                                            <span className="text-uppercase mb-0">{MAITRISE}</span>
                                        </th>
                                        <th style={{ borderTop: 'none' }}>
                                            <span className="text-uppercase mb-0">{EXPERT}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <AptitudesCriteriasMapper context={evaluationStore} />
                            </Table>
                        </div>
                    </div>
                </div>
                <h2 className="title-section-blue text-uppercase mb-4">{GENERAL_TITLE}</h2>
                <div className="card">
                    <div className="card-body">
                        <div className="col-6 text-left">
                            <div className="custom-control custom-radio custom-radio-fat text-left">
                                <input defaultChecked onChange={(e) => onChangeCriterias(e, evaluationStore.dispatch, null)} type="radio" value='-1' name="general" id="improvable" className="custom-control-input"></input>
                                <label className="custom-control-label" htmlFor="improvable">{AMELIORABLE}</label>
                            </div>
                            <div className="custom-control custom-radio custom-radio-fat text-left">
                                <input onChange={(e) => onChangeCriterias(e, evaluationStore.dispatch, null)} type="radio" value='0' name="general" id="conform" className="custom-control-input"></input>
                                <label className="custom-control-label" htmlFor="conform">{CONFORM}</label>
                            </div>
                            <div className="custom-control custom-radio custom-radio-fat text-left">
                                <input onChange={(e) => onChangeCriterias(e, evaluationStore.dispatch, null)} type="radio" value='1' name="general" id="outstanding" className="custom-control-input"></input>
                                <label className="custom-control-label" htmlFor="outstanding">{DEPASSE}</label>
                            </div>
                        </div>
                    </div>
                </div>
                {props.setTrial &&
                    <div class="custom-control custom-checkbox custom-checkbox-fat">
                        <input type="checkbox" class="custom-control-input" id="choice1-checked"
                            onChange={handleSelectTrialPeriod}
                        />
                        <label class="custom-control-label" for="choice1-checked">Renouveler la période d'essai</label>
                    </div>
                }
            </div>
        </div>
    )
}

const registerEvaluationAsync = (infos, history, user) => {
    client.mutate({
        mutation: SET_EVALUATION_EMPLOYE,
        variables: infos
    }).then((values) => {
        if (user === "EMPLOYE") {
            history.push('/experts/expert/' + infos.id_expert);
        } else {
            history.push('/team-leader');
        }
    })
        .catch(err =>
            console.log(err)
        );
};

const RegisterEvaluationButton = (props) => {

    const { dispatch } = useContext(notificationContext);
    const infosEvaluation = {
        id_mission: props.id_mission,
        id_evaluation: props.id_evaluation,
        id_expert: props.store.state.id_expert,
        infos: {
            ...props.store.state
        }
    };

    const handleSubmitEvaluation = () => {
        dispatch({
            type: "setAlert",
            target: {
                element: "expertEvaluation",
                callback: () => registerEvaluationAsync(infosEvaluation, props.history, props.user)
            }
        });
    };

    return (
        <div className="row">
            <div className="col-12 text-center">
                <button
                    onClick={handleSubmitEvaluation}
                    className="btn btn-civipol">{REGISTER}</button>
            </div>
        </div>
    )
}

const EvaluationComponent = (props) => {
    const { match } = props;
    const user = getStorageUser();
    const store = useContext(evaluationContext);

    const getInfosAsync = async () => {
        await client.query({
            query: GET_EVALUATION_EMPLOYE,
            variables: { id_evaluation: match.params.id },
        })
            .then(values => { store.dispatch({ type: "setInfosEvaluation", target: values.data.getInfosEvaluations }) })
            .catch(err => { store.dispatch({ type: "setError", target: err }) });
    }
    useEffect(() => {
        getInfosAsync();
    }, []);

    if (store.state.isLoading) return <Loader className="center" />
    else if (store.state.isError) return (<div> ERROR </div>)
    else return (
        <div id="content" className="py-5">
            <div className="container">
                <EvaluationExpert id_offer={store.state.id_offer} login={user?.employe?.login || user?.expert?.email_1} translateContext={props.translateContext} setTrial={(store.state.evaluation_counter === 1) ? true : false} />
                <RegisterEvaluationButton id_mission={match.params.id} id_evaluation={match.params.id} store={store} history={props.history} user={user?.employe?.login ? "EMPLOYE" : "TEAM_LEADER"} />
            </div>
        </div>
    )
}

export default withRouter(TranslateContextWrapper(EvaluationComponent));
