import React, { createContext, useReducer } from "react";
import * as utils from "../utils/utils";

const initialState = {
    expert: null,
    isLoading: true,
    error: false,
    expertsIds: [],
    expertsIdsIndex: 0,
}

const ExpertDetailsContext = createContext(initialState);

const reducer = (state, action) => {
    let { target, type } = action;
    switch (type) {
        case "setExpert": {
            return {
                ...state,
                expert: target,
                isLoading: false,
            }
        }
        case "setExpertsIds": {
            return {
                ...state,
                expertsIds: target,
                isLoading: false,
            }
        }
        case "setError": {
            return {
                ...state,
                error: target,
                isLoading: false,
            }
        }
        case "setIndex": {
            return {
                ...state,
                expertsIdsIndex: target
            }
        }
        case "unMountExpert": {
            return {
                ...state,
                expert: null,
                isLoading: true
            }
        }
        default: return state
    }
}

const ExpertDetailsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setExpert = (infos) => {
        let values = { ...infos };
        const arrayOfObjsKey = ["ICE",
            "expert_ability",
            "languages",
            "skills",
            "supporting_documents",
            "medicalInformations",
            "personalInformations",
            "wasAcceptedToMission",
            "needs_renewal_consent"];
        let identity = {}
        for (var value in values) {
            if (!arrayOfObjsKey.includes(value) && value !== "__typename") {
                identity = {
                    ...identity,
                    [value]: values[value]
                }
                delete values[value];
            }
        }
        values = {
            ...values,
            identity
        }
        if (values.identity.countries) {
            values.identity.countries = JSON.parse(values.identity.countries);
        }
        if (values.identity.discovered) {
            values.identity.discovered = JSON.parse(values.identity.discovered);
        }
        if (values.identity.mission_duration) {
            values.identity.mission_duration = JSON.parse(values.identity.mission_duration);
        }
        utils.deleteTypeName(values);
        utils.nullToString(values);
        if (values.ICE === "") {
            values.ICE = {
                first_name: "",
                last_name: "",
                address_number_street: "",
                address_postal_code: "",
                address_city: "",
                address_country: "",
                telephone: "",
                email: "",
                discovered: "",
            }
        }
        if (values.languages.length !== 3) {
            const objLang = { language: "", level: "" };
            let nb = values.languages.length;
            for (let i = nb; i < 3; i++) {
                values.languages.push((objLang))
            }
        }

        delete values.identity.status;
        values.identity.infosExacts = true;
        values.identity.criminal_conviction = true;
        values.status = infos.status;
        dispatch({ type: "setExpert", target: { ...values } });
    }

    return (
        <ExpertDetailsContext.Provider value={{ state, dispatch, methods: { setExpert: setExpert } }}>
            {children}
        </ExpertDetailsContext.Provider>
    )
}

export { ExpertDetailsContext, ExpertDetailsProvider };