import React from "react";
import AbilitiesSection from "./AbilitiesSection/AbilitiesSection";
import FirstSection from "./FirstSection/FirstSection";
import NationalityLangues from "./NationalityLangues/NationalityLangues";
import CheckBoxesSection from "./CheckBoxesSection/CheckBoxesSection";
import { Formik } from 'formik';

const AdvancedSearch = () => {
    return (
        <Formik
            initialValues={{
                mission_duration: [],
                date_disponibility: null,
                grade_entity: null,
                date_retired: null,
                skills: [],
                nationality: null,
                country: null,
                lang: null,
                lang_level: null,
                available: false
            }}
            enableReinitialize={true}
        >
            {(props) => {
                return (
                    <div>
                        <FirstSection formikProps={props} />
                        <AbilitiesSection formikProps={props}/>
                        <NationalityLangues formikProps={props}/>
                        <CheckBoxesSection formikProps={props}/>
                    </div>
                );
            }}
        </Formik>
    );
}

export default AdvancedSearch;