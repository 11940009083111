import React, { useContext } from "react";
import { ExpertDetailsContext } from "../../../../stores/expertsDetailsStore";
import { FormattedMessage } from 'react-intl';
import { Form } from "react-bootstrap";
import DocumentSubContainer from "./DocumentSubContainer";

const path = "expertForm.documentToUpload.";

const DocumentContainer = (props) => {

    const { state } = useContext(ExpertDetailsContext);
    const { keyInput, value } = props;
    
    return (
        <div className={"flex-column" + (keyInput !== 0 ? " to-upload-line" : "")} >
            <div className="d-flex justify-content-between align-items-center py-3">
                <Form.Label
                    className={((state.errorsToUpload && state.errorsToUpload[value]) ? 'is-invalid-label' : null) + 'mb-0'}>
                    <p className="mb-0"><FormattedMessage id={path + value} defaultMessage="Not translated"/></p>
                </Form.Label>
            </div>
            <DocumentSubContainer {...props} />
        </div>
    )
}

export default DocumentContainer;