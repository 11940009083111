import React from "react";

const NoMatch = () => {

    return (
        <div id="content" className="py-5">
            <div className="container mb-5">
                <section className="mb-5">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-body text-center py-5">
                                    <img src={require("../../../static/images/not-found/illu_noserp.svg")} className="card-img img-fluid mb-4" alt="noresult"/>
                                    <h5 className="card-title mb-5">Aucun Resultat</h5>
                                    <p className="card-text mb-5">Il n'y a pas d'expert qui corresponde à la recherche. Merci d'essayer d'autres critères.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default NoMatch;