import React from 'react';
import { Text, View, Link } from '@react-pdf/renderer';
import { styles, sectionTitleStyle } from '../styles/styles';

const ICEs = (props) => {

    if (props?.ICE?.length > 0) {
        return props.ICE.map((ice, key) => {
            return (
                <View key={key}>
                    <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Prénom</Text>
                            <Text style={styles.normalBlackText}>{ice?.first_name}</Text>
                        </View>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Nom</Text>
                            <Text style={styles.normalBlackText}>{ice?.last_name}</Text>
                        </View>
                    </View>
                    <View style={{ marginBottom: 15 }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Numéro et nom de rue</Text>
                            <Text style={styles.normalBlackText}>{ice?.address_number_street}</Text>
                        </View>
                    </View>
                    <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Code postal</Text>
                            <Text style={styles.normalBlackText}>{ice?.address_postal_code}</Text>
                        </View>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Ville</Text>
                            <Text style={styles.normalBlackText}>{ice?.address_city}</Text>
                        </View>
                    </View>
                    <View style={{ marginBottom: 15 }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Pays</Text>
                            <Text style={styles.normalBlackText}>{ice?.address_country}</Text>
                        </View>
                    </View>
                    <View style={{ marginBottom: 15 }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Lien de parenté</Text>
                            <Text style={styles.normalBlackText}>{ice?.relationship}</Text>
                        </View>
                    </View>
                    <View style={{ marginBottom: 15 }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Email</Text>
                            <Text style={styles.normalBlackText}>{ice?.email}</Text>
                        </View>
                    </View>
                    <View style={{ marginBottom: 15 }}>
                        <View style={{ width: '50%' }}>
                            <Text style={styles.greyLabel}>Numéro de télépphone</Text>
                            <Text style={styles.normalBlackText}>{ice?.telephone}</Text>
                        </View>
                    </View>
                </View>
            );
        });
    }
    return <View style={{ marginBottom: 15 }}>
        <View style={{ width: '50%' }}>
            <Text style={styles.normalBlackText}>Non renseigné</Text>
        </View>
    </View>;
};

const PersonnalInformations = (props) => {
    const { personalInformations } = props;
    return (
        <View>
            <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Nom matrimonial</Text>
                    <Text style={styles.normalBlackText}>{personalInformations?.matrimonialName}</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Commune de naissance</Text>
                    <Text style={styles.normalBlackText}>{personalInformations?.placeOfBirth}</Text>
                </View>
            </View>
            <View style={{ marginBottom: 15 }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Pays de naissance</Text>
                    <Text style={styles.normalBlackText}>{personalInformations?.nativeCountry}</Text>
                </View>
            </View>
            <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Numéro de passeport</Text>
                    <Text style={styles.normalBlackText}>{personalInformations?.passportNumber}</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Lieu de délivrance du passeport</Text>
                    <Text style={styles.normalBlackText}>{personalInformations?.passportPlaceDelivery}</Text>
                </View>
            </View>
            <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Date d'expiration du passeport</Text>
                    <Text style={styles.normalBlackText}>{personalInformations?.passportExpirationDate}</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Fichier passeport</Text>
                    {personalInformations?.passportFile ? <Link style={styles.normalBlackText} src={`${props.env.REACT_APP_API_URL}/public/personal-informations/getFile/${personalInformations?.passportFile}+${personalInformations?.id}`}>Voir le Passeport</Link> : <Text style={styles.normalBlackText}>Non renseigné</Text>}
                </View>
            </View>
            <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Numéro de sécurité sociale</Text>
                    <Text style={styles.normalBlackText}>{personalInformations?.socialSecurityNumber}</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Fichier carte vitale</Text>
                    {personalInformations?.vitalCardFile ? <Link style={styles.normalBlackText} src={`${props.env.REACT_APP_API_URL}/public/personal-informations/getFile/${personalInformations?.vitalCardFile}+${personalInformations?.id}`}>Voir la carte vitale</Link> : <Text style={styles.normalBlackText}>Non renseigné</Text>}
                </View>
            </View>
            <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Numéro de RIB</Text>
                    <Text style={styles.normalBlackText}>{personalInformations?.ribNumer}</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Numéro de IBAN</Text>
                    <Text style={styles.normalBlackText}>{personalInformations?.ibanNumber}</Text>
                </View>
            </View>
            <View style={{ marginBottom: 15 }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Fichier RIB</Text>
                    {personalInformations?.rib ? <Link style={styles.normalBlackText} src={`${props.env.REACT_APP_API_URL}/public/personal-informations/getFile/${personalInformations?.rib}+${personalInformations?.id}`}>Voir le RIB</Link> : <Text style={styles.normalBlackText}>Non renseigné</Text>}
                </View>
            </View>
        </View>
    );
};

const MedicalInformations = (props) => {
    const { medicalInformations } = props;
    return (
        <View>
            <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Nom du médecin traitant</Text>
                    <Text style={styles.normalBlackText}>{medicalInformations?.doctorName}</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Adresse</Text>
                    <Text style={styles.normalBlackText}>{medicalInformations?.doctorAddress}</Text>
                </View>
            </View>
            <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Numéro de téléphone fixe</Text>
                    <Text style={styles.normalBlackText}>{medicalInformations?.doctorPhoneNumberFix}</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Numéro de téléphone portable</Text>
                    <Text style={styles.normalBlackText}>{medicalInformations?.doctorCellPhoneNumber}</Text>
                </View>
            </View>
            <View style={{ marginBottom: 15 }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Email</Text>
                    <Text style={styles.normalBlackText}>{medicalInformations?.doctorEmail}</Text>
                </View>
            </View>
            <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Groupe sanguin</Text>
                    <Text style={styles.normalBlackText}>{medicalInformations?.bloodGroup}</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Rhésus</Text>
                    <Text style={styles.normalBlackText}>{medicalInformations?.rhesus}</Text>
                </View>
            </View>
            <View style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Contre-indications thérapeutiques</Text>
                    <Text style={styles.normalBlackText}>{medicalInformations?.therapeuticsContrindications}</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <Text style={styles.greyLabel}>Allergies</Text>
                    <Text style={styles.normalBlackText}>{medicalInformations?.allergies}</Text>
                </View>
            </View>
        </View>
    );

};

const PDFIndividualsInformations = (props) => {
    return (
        <View>
            <View style={sectionTitleStyle.container}>
                <View style={sectionTitleStyle.sectionTitlePastille}>
                    <Text>6</Text>
                </View>
                <Text style={sectionTitleStyle.sectionTitle}>Informations Individuelles</Text>
            </View>
            <View style={styles.sectionProfile}>
                <Text style={styles.blueSubtitles}>Personnes à prévenir en cas d'urgence</Text>
                <ICEs {...props} />
                <Text style={styles.blueSubtitles}>Informations Personnelles</Text>
                <PersonnalInformations {...props} />
                <Text style={styles.blueSubtitles}>Informations Médicales</Text>
                <MedicalInformations {...props} />
            </View>
        </View>

    );

};

export default PDFIndividualsInformations;