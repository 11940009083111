import React from "react";
import Select from "react-select";

const array = ["friends", "administration", "institution", "medias", "socialNetworks", "salons", "other"];
const customStyles = {
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px"
    }),

}
const handleChange = ({ setValues, values }, selected) => {
    
    
    setValues({
        ...values,
        identity: {
            ...values.identity,
            discovered: (selected.value === "cancel") ? "" : selected
        }
    })
}

const Selector = ({ translate, ...props }) => {
    let arrayOption = array.map(value => ({ value: value, label: translate.translateMessage("expertForm.confirmation.arrayHowDidYouKnow." + value) }));
    arrayOption.unshift({ value: "cancel", label: "Annuler la sélection" });
    return (
        <div className="mb-4 col-12 col-md-6 pl-0">
            <Select
                styles={customStyles}
                options={arrayOption}
                onChange={(selected) => handleChange(props, selected)}
                placeholder={translate.translateMessage("expertForm.confirmation.arrayHowDidYouKnow.default")}
                value={(props.values.identity && props.values.identity.discovered) ? props.values.identity.discovered : null}
            />
        </div>
    )
}

export default Selector;