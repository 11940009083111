import { translate } from "./translation.constants";

export const alertNotifications = {
    modification: {
        TITLE: translate("notifications.alert.alertModificationTitle"),
        TEXT: translate("notifications.alert.alertModificationText"),
    },
    rejectExpert: {
        TITLE: translate("notifications.alert.alertRejectExpertTitle"),
        TEXT: translate("notifications.alert.alertRejectExpertText")
    },
    keyExpert: {
        TITLE: translate("notifications.alert.elertKeyExpertTitle"),
        TEXT: translate("notifications.alert.elertKeyExpertText"),
    },
    expertEvaluation: {
        TITLE: translate("notifications.alert.elertEvaluationExpertTitle"),
        TEXT: translate("notifications.alert.elertEvaluationExpertText"),
    },
    expertRecommandation: {
        TITLE: translate("notifications.alert.alertRecommandationExpertTitle"),
        TEXT: translate("notifications.alert.alertRecommandationExpertText"),
    },
    expertNotValid: {
        TITLE: translate("notifications.alert.generalErrorTitle"),
        TEXT: translate("notifications.alert.expertNotValidatedText"),
    },
    expertNotComplete: {
        TITLE: translate("notifications.alert.generalErrorTitle"),
        TEXT: translate("notifications.alert.expertNotCompleteText"),
    },
    teamLeaderAlreadyAssigned: {
        TITLE: translate("notifications.alert.teamLeaderAlreadyAssignedTitle"),
        TEXT: translate("notifications.alert.teamLeaderAlreadyAssignedText"),
    },
    errorAlert: {
        TITLE: translate("notifications.alert.generalErrorTitle"),
        TEXT: translate("notifications.alert.generalErrorText"),
    },
    deleteAccount1: {
        TITLE: translate("notifications.alert.deleteAccountTitle"),
        TEXT: translate("notifications.alert.deleteAccountText"),
    },

    notationAlert: {
        TITLE: translate("notifications.alert.notationTitle"),
    },
    candidaturesExpert: {
        TITLE: translate("notifications.alert.candidaturesExpertTitle")
    },
    errorInputProfile: {
        TITLE: translate("notifications.alert.errorInputProfileTitle"),
        TEXT: translate("notifications.alert.errorInputProfileText"),
    },
    errorAuthorizationAction: {
        TITLE: translate("notifications.alert.errorAuthorizationActionTitle"),
        TEXT: translate("notifications.alert.errorAuthorizationActionText"),
    },
}

export const toastNotifications = {
    successModification: {
        TITLE: translate("notifications.toast.toastModificationTitle"),
        TEXT: translate("notifications.toast.toastModificationText"),
    },
    successSuggestion: {
        TITLE: translate("notifications.toast.toastSuggestionTitle"),
        TEXT: translate("notifications.toast.toastSuggestionText")
    }
}

export const promptNotification = {
    historic: {
        TITLE: translate("notifications.alert.alertPromptHistoticTitle"),
        TEXT: translate("notifications.alert.alertPromptHistoticText"),
        PLACEHOLDER: translate("notifications.alert.alertPromptHistoticPlaceHolder"),
        SUBMIT: translate("notifications.alert.alertPromptHistoticSubmit"),
        CANCEL: translate("notifications.alert.alertPromptHistoticCancel"),
    }
}
