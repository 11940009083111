import React, { useContext } from "react";
import { projectDetails } from "../../../../constants/projects.constants";
import { projectDetailsContext } from "../../../../stores/projectDetailsStore";
import { Markup } from 'interweave';
import moment from "moment";
import { Loader } from "../../../loader/Loader";
const {
    ABOUT_TITLE,
    SEE_ON_WEB,
    START_DATE,
    END_DATE,
    DURATION,
} = projectDetails;

const getDefaultImage = (themes) => {
    switch (themes.fr.length === 0) {
        case themes.id_taxo === "72": {
            return require("../../../../static/images/projects-card/theme-72.jpg");
        }
        case themes.id_taxo === "73": {
            return require("../../../../static/images/projects-card/theme-73.jpg");
        }
        case themes.id_taxo === "75": {
            return require("../../../../static/images/projects-card/theme-75.jpg");
        }
        default: {
            return "";
        }
    }
}

const parseChronology = (chronology) => {
    if (chronology) {
        let tmp = chronology.split(",");
        return {
            start: tmp[0].replace("\"", ""),
            end: tmp[tmp.length - 1].replace("\"", "")
        }
    } else return {
        start: "",
        end: "",
    }
}

const Project = () => {
    const { state } = useContext(projectDetailsContext);


    if (state.isLoading) { return <Loader class="center" /> }
    else {
        const { title, link, introduction_text, themes, primary_image, chronology } = state.project;
        let default_img = getDefaultImage(themes);
        let img = (primary_image.fr.length > 0) ? process.env.REACT_APP_CIVIPOL + primary_image["fr"] : default_img;
        const { start, end } = parseChronology(chronology);


        return (
            <div>
                <div className="description-project" >
                    <div className="row">
                        <div className="col-12 col-lg-8 mb-5 mb-lg-0">
                            <h2 className="mb-5">{(title["fr"])}</h2>
                            <div className="mb-5">
                                <Markup content={introduction_text["fr"]} />
                            </div>
                            <div className="d-flex justify-content-center">
                                <a target="_blank" rel="noopener noreferrer" className="d-flex col-6 col-6 din-bold-p justify-content-between align-items-center text-uppercase mb-0 see-on-web-container" style={{ textDecoration: "none" }} href={process.env.REACT_APP_CIVIPOL + link["fr"]}>
                                    <img className="img-fluid" style={{ maxWidth: "90px", minWidth: "90px" }} src={require("../../../../static/images/detail-project/illu_fiche-projet.svg")} alt="onWebSite" />
                                    <p className="mb-0 text-center">{SEE_ON_WEB}</p>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="row mb-4">
                                <div className="col">
                                    <img className="img-fluid" src={img} alt="img-project" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col description-block-project">
                                    <div className="px-3 py-4" style={{ backgroundColor: "#F7F7F7" }}>
                                        <div className="row mb-4">
                                            <div className="col">
                                                <h2 className="big-title mb-5">
                                                    {ABOUT_TITLE}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col">
                                                <h3 className="text-uppercase">{START_DATE}</h3>
                                                <p></p>
                                                <p>{moment(start).format("DD/MM/YYYY")}</p>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col">
                                                <h3 className="text-uppercase">{END_DATE}</h3>
                                                <p></p>
                                                <p>{moment(end).format("DD/MM/YYYY")}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <h3 className="text-uppercase">{DURATION}</h3>
                                                <p></p>
                                                <p>-</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Project;