import React from "react";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import moment from "moment";

const ExpertsMapper = ({ missions }) => {

    let arrayExperts = missions.map((values, key) => {
        if (values && values.expert) {
            if (values.staffed === "Postule") {
                return (
                    <div key={key} className="d-flex align-items-center mb-2">
                        <div className='d-flex' style={{ minWidth: "70%", flexWrap: "wrap"}}>
                            <p className='text-capitalize'>{`${values.expert.firstName.toLowerCase()} ${values.expert.lastName.toLowerCase()}`}</p>
                            <p>{`a postulé le ${moment(values?.date_postulat).format("DD/MM/YYYY")}`}</p>
                        </div>
                        <Link to={`/experts/expert/${values.expert.id}`} className="btn btn-civipol" >Voir l'expert</Link>
                    </div>
                )
            }
        } else return null;
    })
    return arrayExperts;
};

const ModalListExpertsCandats = (props) => {
    const { missions } = props;
    if (missions) {
        return (
            <Modal
                {...props}
            >
                <div className="py-5 px-3">
                    <div className="d-flex flex-column">
                        <ExpertsMapper missions={missions} />
                    </div>
                </div>
            </Modal>
        );
    } else return null;
}

export default ModalListExpertsCandats;