import React, { useEffect, useContext } from "react";
import { withRouter, Link } from "react-router-dom"
import { GET_OFFER_BY_ID } from "../../queries/getOffers";
import { OfferDetailsContext } from "../../stores/detailsOfferStore";
import DetailsOffer from "./DetailsOffer";
import client from "../../ApolloSetup";
import CandidaturesContainer from "./sections/candadidatures/CandidaturesContainer";
import MissionComponent from "./sections/missions/MissionComponent";

const getOfferAsync = async (id, { dispatch }) => {

    client.query({
        query: GET_OFFER_BY_ID,
        variables: { id: id }
    })
        .then((values) => {dispatch({ type: "setOfferAndCandidatures", target: values.data.getOffer }) })
        .catch(err => console.log(err));
}

const CandidaturesOrAccepted = (props) => {
    const { candidatures } = props.offerStore.state;

    if (candidatures) {
        let isCandidature = true;
        for (let candidature in candidatures) {
            let staffedCandidature = candidatures[candidature];
            if (staffedCandidature.staffed) {
                if (staffedCandidature.staffed === "Agreed" || staffedCandidature.staffed === "InMission" || staffedCandidature.staffed === "Terminated") {
                    isCandidature = false;
                }
            }
        }
        if (!isCandidature) {
            return <MissionComponent {...candidatures} />
        }
        else return <CandidaturesContainer {...candidatures} />
    } else return null;
}

const DetailsOfferPage = (props) => {
    const { match } = props;
    const offerStore = useContext(OfferDetailsContext);
    const { offer, project, candidatures } = offerStore.state;
    useEffect(() => {
        getOfferAsync(match.params.id_offer, offerStore);
    }, [offerStore.state.reload]);
    
    if (offer && project && candidatures) {
        return (
            <div id="content" className="py-5">
                <div className="container">
                    <Link className="btn btn-civipol-white mb-5" to={`/projects/page/1`}>RETOUR AUX PROJETS</Link>
                    <div className="row">
                        <div className="col mb-4">
                            <h2 className="big-title mb-5">
                                DÉTAILS DE L'OFFRE
                        </h2>
                            <DetailsOffer />
                        </div>
                    </div>
                    <hr className="mb-5" />
                    <CandidaturesOrAccepted offerStore={offerStore} />
                </div>
            </div>
        );
    } else return null;
}

export default withRouter(DetailsOfferPage);