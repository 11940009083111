import gql from "graphql-tag";

const ACCEPT_CANDIDATURE = gql`
    mutation validateCandidature($id_expert: ID!, $id_mission: ID!, $isTeamLeader: Boolean){
        validateCandidature(id_expert: $id_expert, id_mission: $id_mission, isTeamLeader: $isTeamLeader){
            id
        }   
    }
`;

const REJECT_CANDIDATURE = gql`
    mutation rejectCandidature($id_expert: ID!, $id_mission: ID!){
        rejectCandidature(id_expert: $id_expert, id_mission: $id_mission){
            id
        }   
    }
`;

const SET_KEY_EXPERT = gql`
    mutation setExpertKey($id_mission: ID!, $value: Boolean!) {
        setExpertKey(id_mission: $id_mission, value: $value) {
            id
            title {fr}
            introduction_text {fr}
            primary_image {fr}
            themes {fr id_taxo}
            link {fr}
            chronology
            offers {
                id
                id_drupal
                title { fr en }
                description_text {fr en}
                mission_length {fr en parent}
                geographical_zone {fr en parent}
                application_expiration
                introduction_text  {fr en}
                profile_text {fr en}
                job_expertise {fr en parent id_taxo}

                missions {
                    id
                    staffed
                    date_accepted
                    date_postulat
                    key_expert
                    expert {
                        id
                        profile_pic
                        firstName
                        lastName
                        expert_ability {
                            fonctionnaire
                            DG
                        }
                    }
                }
            }
        }
    }
`

export { ACCEPT_CANDIDATURE, REJECT_CANDIDATURE, SET_KEY_EXPERT }