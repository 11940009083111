import React, { useContext } from "react";
import CheckBoxColored from "../../../../checkBox/CheckboxColored";
import { searchEngineContext } from "../../../../../stores/searchEngineStore";


const CheckBoxesSection = () => {

    const engineStore = useContext(searchEngineContext);

    return (
        <div className="row mb-4">
            <section className="col switch">
                <div className="row flex-row justify-content-between align-items-center">
                    <div className="col-12 col-lg-6">
                        <CheckBoxColored context={engineStore} dispatchType="setSearchInput" name="display_disponible" label="Afficher uniquement les experts disponibles" />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CheckBoxesSection;