import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ExpertDetailsContext } from "../../stores/expertsDetailsStore";
import client from "../../ApolloSetup";
import { GET_EXPERT_BY_ID } from "../../queries/getExperts";
import MenuForm from "../menu/MenuFom";
import Identity from "../formExpert/identity/Identity";
import MissionsSearched from "../formExpert/missionsSearched/MissionsSearched";
import Abilities from "../formExpert/abilities/Abilities";
import CV from "../formExpert/cv/CV";
import SupportingDocuments from "../formExpert/supportingDocuments/SupportingDocuments";
import LOG_CONSULTATION from "../../mutations/logsConsultation";
import MissionsSection from "../missions/MissionsSection";
import Administration from "../administration/Administration";
import QuickActionsExperts from "../quickActions/quickActionsExperts/quickActionsExperts";
import { Loader } from "../loader/Loader"
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import IndividualInformations from '../formExpert/IndividualsInformations/IndividualsInformations';

//execute query to get expert and mutation to log consultation
const getExpert = async (id, expertContext) => {
    expertContext.dispatch({ type: 'unMountExpert' });
    await client.mutate({
        mutation: LOG_CONSULTATION,
        variables: {
            id_expert: id
        }
    }).catch(err => console.log(err));
    await client.query({
        query: GET_EXPERT_BY_ID,
        variables: { expertID: id },
        fetchPolicy: 'no-cache',
    }).then(value => expertContext.methods.setExpert(value.data.getExpert)).catch(err => console.log(err));
}

const Expert = (props) => {
    const { id } = props.match.params;
    const expertContext = useContext(ExpertDetailsContext);

    useEffect(() => {
        getExpert(id, expertContext);
        return () => {
            expertContext.dispatch({ type: 'unMountExpert' })
        };
    }, []);

    if (expertContext.state.isLoading) {
        return <Loader class="center" />;
    } else if (expertContext.state.expert !== null) {
        window.scroll(0, 0);
        return (
            <div className="py-5" id="content">
                <div className="container">
                    <div>
                        <QuickActionsExperts />
                    </div>
                    <div className="row flex-row justify-content-between mb-5">
                        <div className="d-flex">
                            <h2 className="big-title text-capitalize">
                                {`Expert : ${expertContext.state.expert.identity.firstName.toLowerCase()} ${expertContext.state.expert.identity.lastName.toLowerCase()}`}
                            </h2>
                            {expertContext?.state?.expert?.projectLeader && <h5 className="ml-5 text-primary">Team Leader</h5>}
                        </div>

                        <Link className="btn btn-civipol-white" to={`/experts/expert/${id}/PDFexport`}><FormattedMessage id="pdfExport.exportButton" /></Link>
                    </div>
                    <div className="row scrollspy mb-5">
                        <MenuForm />
                        <div className="col-12 col-md-8">
                            <Identity />
                            <MissionsSearched />
                            <Abilities />
                            <CV />
                            <IndividualInformations />
                            <SupportingDocuments />
                        </div>
                    </div>
                    <hr />
                    <MissionsSection />
                    <hr className="mb-5" />
                    <Administration />
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default withRouter(Expert);
