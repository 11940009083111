import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import CheckBoxes from "./CheckboxesComponent";
import Selector from "./ConfirmationSelector";
import { confirmationConstants } from "../../../../constants/profile-expert.constants";

const {
    NOTIFICATION_TITLE,
    NOTIFICATION_OFFERS_BY_MAIL,
    NOTIFICATION_ACTUS_BY_MAIL,
    HOW_DID_YOU_KNOW_TITLE,
    CONDAMNATION_TITLE,
    CONDAMNATION_TEXT,

} = confirmationConstants;

const ConfirmationComponent = ({ translate, ...props }) => {
    return (
        <Fragment>
            <h2 className="title-section-blue text-uppercase mb-4">{NOTIFICATION_TITLE}</h2>
            <Form.Label className={props.errors.identity && props.errors.identity.notificationOffers ? "is-invalid-label" : null} > {NOTIFICATION_OFFERS_BY_MAIL} </Form.Label>
            <CheckBoxes
                name="notificationOffers"
                {...props}
            />
            <Form.Label className={props.errors.identity && props.errors.identity.notificationActus ? "is-invalid-label" : null} > {NOTIFICATION_ACTUS_BY_MAIL} </Form.Label>
            <CheckBoxes
                name="notificationActus"
                {...props}
            />
            <h2 className="title-section-blue text-uppercase mb-4">{HOW_DID_YOU_KNOW_TITLE}</h2>
            <Selector translate={translate} {...props} />
            <h2 className="title-section-blue text-uppercase mb-4">{CONDAMNATION_TITLE}</h2>
            <Form.Label className={props.errors.identity && props.errors.identity.criminal_conviction ? "is-invalid-label" : null} >{CONDAMNATION_TEXT} </Form.Label>
            <CheckBoxes
                name="criminal_conviction"
                checked={true}
                {...props}
            />
        </Fragment>
    )
}

export default ConfirmationComponent;