import React from "react";
import { TranslateContextWrapper } from "../../../stores/TranslateContext";
import { evaluationConstants } from "../../../constants/evaluation.constants";
import { candidaturesConstants } from "../../../constants/missions.constants";
import { Link } from "react-router-dom";

import * as moment from "moment";

const {
    PROJECT_TITLE,
    OFFER_TITLE,
    DATE_TITLE,
    CHIEF_POLE,
    RESPONSABLE_PROJECT,
    COUNTRY,
    LINK_TO_OFFER,
    PROJECT_CODE,
    MISSION_INFORMATION
} = evaluationConstants;

const { BUTTON_SEE_OFFER } = candidaturesConstants;

const HeaderPage = (props) => {

    const { firstName, lastName } = props.elem.expert;
    const { poleChief } = props.elem;
    const { id } = props.elem.mission.offer;
    const { translateMessage } = props.translateContext;
    const login = props?.elem?.teamLeader?.email_1 || props?.elem?.employe?.login || null;
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-12 mb-3">
                    <h2 className="big-title mb-5">{translateMessage("evaluationExpert.evaluationOf", "Not Translated", { firstName_expert: firstName, lastName_expert: lastName })}</h2>
                    <h3>{props?.elem?.mission?.offer?.project?.title?.fr}</h3>
                    <div className="d-flex">
                        <div className="information-expert mr-3">
                            <label className="text-uppercase">
                                {PROJECT_CODE}
                            </label>
                            <div>
                                <span>{props?.elem?.projectCode || "NON RENSEIGNÉ"}</span>
                            </div>
                        </div>
                        <div className="information-expert mr-3">
                            <label className="text-uppercase">
                                {COUNTRY}
                            </label>
                            <div>
                                <span>{props?.elem?.mission?.offer?.geographical_zone?.fr || "NON RENSEIGNÉ"}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col align-items-center p-5' style={{ backgroundColor: 'rgb(247, 247, 247)' }}>
                    <h3 style={{ fontSize: '2rem' }} className='big-title mb-5'>{MISSION_INFORMATION}</h3>
                    <div className="d-flex justify-content-between mb-3">
                        <h3>{props?.elem?.mission?.offer?.title?.fr || "NON RENSEIGNÉ"}</h3>
                        <Link className='btn btn-civipol-white' to={`/details-offer/${id}`}>{BUTTON_SEE_OFFER}</Link>
                    </div>
                    <div className="d-flex justify-content-between mt-3 mb-5">
                        <div className="information-expert mr-3">
                            <label className="text-uppercase">
                                {DATE_TITLE}
                            </label>
                            <div>{moment().format("DD/MM/YYYY")}</div>
                        </div>
                        <div className="information-expert mr-3">
                            <label className="text-uppercase">
                                {CHIEF_POLE}
                            </label>
                            <div>
                                <span>{poleChief || "NON RENSEIGNÉ"}</span>
                            </div>
                        </div>
                        <div className="information-expert mr-3">
                            <label className="text-uppercase">
                                {RESPONSABLE_PROJECT}
                            </label>
                            <div>
                                <span>{login || "NON RENSEIGNÉ"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <label className="title-section-blue text-uppercase mr-5">Période d'essai renouvelée</label>
                        <span>{props.elem.reniew_trial_period ? 'OUI' : 'NON'}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TranslateContextWrapper(HeaderPage);