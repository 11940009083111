import React, { useContext } from "react";
import { searchEngineConstant } from "../../../../constants/searchEngine.constants";
import { searchEngineContext } from "../../../../stores/searchEngineStore";
import { expertPageContext } from "../../../../stores/expertsPageStore";
import { GET_EXPERTS_BY_PAGE } from "../../../../queries/getExperts";
import client from "../../../../ApolloSetup";
import { getSessionItemByKey, setSessionItemByKeyValue } from "../../../utils/sessionStorage";
import { withRouter } from "react-router-dom";

const { SEARCH_BUTTON_INPUT, DELETE_BUTTON_INPUT } = searchEngineConstant;


const LaunchInputSearch = async (expertStore, engineStore, e, deleteInput) => {
    let { page, search } = getSessionItemByKey("searchExperts");
    let filters = (!search) ? {} : { filters: { ...search } };

    await client.query({
        query: GET_EXPERTS_BY_PAGE,
        variables: { page: parseInt(page), filters: filters }
    })
        .then((values) => {
            if (!values.errors) {
                expertStore.dispatch({ type: "setExpertsResearch", target: values.data.getExperts })
            } else {
                expertStore.dispatch({ type: "setError", target: values.errors[0].message })
            }
        })
        .catch((err) => console.log(err));
}

const onChange = (e, { dispatch }) => {
    dispatch({ type: "setSearchInput", target: { [e.target.name]: e.target.value } });
};

const InputSearch = ({ history }) => {
    const engineStore = useContext(searchEngineContext);
    const expertsStore = useContext(expertPageContext);
    const emptyObj = {
        inputSearch: "",
        mission_duration: "",
        nationality: "",
        countries: "",
        language: {
            langue: "",
            langue_level: "",
        },
        skills: [],
        servicesDetails: {},
        disponibility: "",
        display_disponible: false,
    };
    const pushToPageOne = (deleteInput) => {
        history.push("/experts/page/1");
        let item = getSessionItemByKey("searchExperts");
        item = { ...item, page: 1, search: engineStore.state };
        
        if (deleteInput) {
            item.search.inputSearch = "";
            engineStore.dispatch({ type: "setSearchInput", target: { inputSearch: "" } });
        }
        if (JSON.stringify(item.search) === JSON.stringify(emptyObj)) {
            item = {};
        }
        setSessionItemByKeyValue("searchExperts", item);
        if (!deleteInput) {
            LaunchInputSearch(expertsStore, engineStore);
        }
    }

    return (
        <div className="form-group input-search px-3 py-3 bg-white rounded shadow-sm mb-4">
            <div className="input-group">
                <input type="text" name="inputSearch" value={engineStore.state.inputSearch || ""} onChange={(e) => onChange(e, engineStore)} className="form-control mr-3 border-0" placeholder="Rechercher le nom, prénom, email d'un expert. 2 caractères minimum." />
                <button onClick={(e) => {
                    pushToPageOne();
                }} type="button" className="btn btn-civipol mr-3">
                    {SEARCH_BUTTON_INPUT}
                </button>
                <button onClick={(e) => {
                    pushToPageOne(true);
                }} type="button" className="btn btn-civipol-white">
                    {DELETE_BUTTON_INPUT}
                </button>
            </div>
        </div>
    )
};

export default withRouter(InputSearch);