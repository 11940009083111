import React from "react";
import {withRouter} from "react-router";
import { getStorageUser } from "../utils/localStorage";
const Footer = (props) => {

    const userInfos = getStorageUser();
    
    if (props.location.pathname.indexOf("signin") === -1 && (userInfos?.employe || userInfos?.expert )) {
        return (
            <footer id="footer">
                <div className="footer-section-civipol  py-4">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <img src={require("../../static/images/footer/logo-civipol-footer.svg")} alt="Civipol" />
                            <div className="d-flex justify-content-end align-items-center">
                                <button className="btn btn-link px-0 py-0 ml-4">
                                    <img src={require("../../static/images/footer/icon-scrolltotop.svg")} alt="Civipol" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-section-copyright py-4">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Copyright © 2019 - Site créé par la société <a href="https://www.wassa.io/" rel="noopener noreferrer" target="_blank"><u>Wassa</u></a></span>
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <a href="#">Support</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    } else return null;
}

export default withRouter(Footer);