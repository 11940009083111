import React, { useContext } from "react";
import { missionsConstants } from "../../../../constants/missions.constants";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { getStorageUser } from '../../../utils/localStorage';
import { notificationContext } from '../../../../stores/notificationsStore';
import { FormattedMessage } from "react-intl";
import { UPDATE_MISSION } from '../../mutations/updateMission';
import { useMutation } from '@apollo/react-hooks';
import client from '../../../../ApolloSetup';
import { missionsContext } from "../../../../stores/missionsStore";
import { errorsGesture } from "../../../utils/errors";

const { DATE_START, DATE_END, BUTTON_SEE_OFFER, EVALUATE_EXPERT } = missionsConstants;
const lang = localStorage.lang;

const EvaluationsButtons = ({ evaluationEmployee, id }) => {
    if (evaluationEmployee.length === 0) {
        return null;
    } else {
        return <Link to={`/evaluations/${id}/page/1`} className="btn btn-civipol-white mb-2">Voir les évaluations</Link>
    }
};

const MissionComponent = (props) => {

    const params = useParams();
    const notificationStore = useContext(notificationContext);
    const missionStore = useContext(missionsContext);
    const [updateMission] = useMutation(UPDATE_MISSION, { client });
    const { value } = props;
    const title = value.offer.title[lang];
    const { id, offer, evaluationEmployee, staffed, date_untied } = value;
    const { chronology } = offer.project;
    let date_start = "";
    let date_end = "";
    const employe = getStorageUser();
    const canSeeEvaluations = ((employe?.employe?.groups === 'RH_DIR' || employe?.employe?.groups === 'Wassa' || employe?.employe?.groups === 'BE_All') || (employe?.employe?.groups === 'RDP' && employe?.employe?.email === value?.offer?.project?.project_chief));
    const missionToEvaluate = evaluationEmployee?.find(evaluation => evaluation?.is_complete === false);
    
    if (chronology) {
        const date = chronology.split(",");
        date_start = date[0].replace("\"", "");
        date_end = date[date.length - 1].replace("\"", "");
    }

    const setAverageEvaluation = (valuesArray, add) => {
        let average = 0;
        for (let i = 0; i < valuesArray?.length; i++) {
            average += (valuesArray[i] + add);
        }
        return average / valuesArray.length;
    };

    const handleNote = () => {
        if (value?.evaluationEmployee?.length > 0) {
            let averageEvaluations = [];
            for (const evaluation in value?.evaluationEmployee) {
                const formattedNotes = value?.evaluationEmployee[evaluation]?.criterias?.map(criterias => criterias.value);
                averageEvaluations.push(setAverageEvaluation(formattedNotes, 1));
            }
            const result = setAverageEvaluation(averageEvaluations, 0);
            notificationStore.dispatch({ type: "setAlert", target: { element: "notationAlert", isCancel: false, custom: <FormattedMessage id="notifications.alert.notationText" values={{ nb_evaluations: value?.evaluationEmployee?.length, note: result }} /> } });
        }
    };

    const submit = () => {
        updateMission({
            variables: {
                expertID: params.id,
                staffed: 'Untied',
                missionId: id,
                offerId: offer.id
            }
        }).then(() => {
            missionStore.dispatch({ type: 'updateMissions' });
        }).catch((err) => {
            errorsGesture(err, () => notificationStore.dispatch({ type: "setAlert", target: { element: "errorAuthorizationAction" } }))
        })
    };

    const handleUntieExpert = () => {
        notificationStore.dispatch({ type: "setAlert", target: { element: "modification", callback: submit } });
    };

    return (
        <div className="row flex-column">
            <div className="col">
                <h2 className="mb-2">{title}</h2>
                <div className="d-flex align-items-center mb-2 justify-content-between">
                    <div className="date mr-2">
                        <p className="text-muted text-uppercase mb-0">{DATE_START}</p>
                        <p className="mb-0">{moment(date_start).format("DD/MM/YYYY") || "-"}</p>
                    </div>
                    <div className="date mr-2">
                        <p className="text-muted text-uppercase mb-0">{DATE_END}</p>
                        <p className="mb-0">{moment(date_end).format("DD/MM/YYYY") || "-"}</p>
                    </div>
                    {
                        (staffed === 'Untied') &&
                        <div className="date mr-2">
                            <p className="text-muted text-uppercase mb-0">DÉTACHÉ LE</p>
                            <p className="mb-0">{moment(date_untied).format("DD/MM/YYYY") || "-"}</p>
                        </div>
                    }
                    <Link to={`/details-offer/${offer.id}`} className="btn btn-civipol-white mb-2">{BUTTON_SEE_OFFER}</Link>
                    {(staffed !== 'Terminated' && staffed !== 'Untied' && staffed !== 'Refused') && <button className='btn btn-red' onClick={handleUntieExpert}>DÉTACHER L'EXPERT</button>}

                </div>
                <div className="d-flex justify-content-between" >
                    <EvaluationsButtons {...value} />
                    {(canSeeEvaluations && value?.evaluationEmployee?.length > 0) && <button className="btn btn-civipol-white mb-2" onClick={handleNote}>Voir la note</button>}
                    {(canSeeEvaluations && missionToEvaluate) && <Link style={{ maxHeight: '40px' }} to={`/evaluation/${missionToEvaluate.id}`} className="btn btn-civipol ">{EVALUATE_EXPERT}</Link>}
                </div>
            </div>
        </div>
    )
}

export default MissionComponent;