import React, { useContext, useEffect } from "react";
import { projectDetailsContext } from "../../stores/projectDetailsStore";
import { withRouter } from "react-router-dom";
import client from "../../ApolloSetup";
import { GET_PROJECT_BY_ID } from "../../queries/getProjects";
import { projectDetails } from "../../constants/projects.constants";
import Project from "./sections/project/Project";
import OffersContainer from "./sections/offers/OffersContainer";
const {
    ABOUT_TITLE,
    OFFERS_TITLE
} = projectDetails;

const getProjectAsync = (id, { dispatch }) => {
    client.query({
        query: GET_PROJECT_BY_ID,
        variables: { id: id }
    })
        .then((values) => { dispatch({ type: "setProject", target: values.data.getProjectByID }) })
        .catch(err => { console.log(err) });
}

const DetailProject = (props) => {
    const projectStore = useContext(projectDetailsContext);
    const { history } = props;
    const { id } = props.match.params;

    useEffect(() => {
        getProjectAsync(id, projectStore);
    }, []);

    return (
        <div id="content" className="py-5">
            <div className="container">
                <button className="btn btn-civipol-white mb-5" onClick={() => history.goBack()}>RETOUR AUX PROJETS</button>
                <div className="row">
                    <div className="col mb-4">
                        <h2 className="big-title mb-5">
                            {ABOUT_TITLE}
                        </h2>
                        <Project />
                    </div>
                </div>
                <hr className="mb-5" />
                <div className="row">
                    <div className="col mb-4">
                        <h2 className="big-title mb-5">
                            {OFFERS_TITLE}
                        </h2>
                        <OffersContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DetailProject);