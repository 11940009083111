import React from 'react';
import { Link, Text, View } from '@react-pdf/renderer';
import { styles, sectionTitleStyle, selectStyle } from '../styles/styles';

const PDFConfirmations = (props) => {
    return (
        <>
            <View>
                <Text style={styles.blueSubtitles}>Préférences de notification</Text>
                <View style={{ marginBottom: 15 }}>
                    <View>
                        <Text style={styles.greyLabel}>Recevoir les nouvelles offres d'emploi par mail:</Text>
                        <Text style={styles.normalBlackText}>
                            {
                                (props.identity?.validationConfirmation)
                                    ? (props.identity.notificationOffers)
                                        ? "OUI"
                                        : "NON"
                                    : "Non Renseigné"
                            }
                        </Text>
                    </View>
                </View>
                <View style={{ marginBottom: 15 }}>
                    <View>
                        <Text style={styles.greyLabel}>Recevoir les actualités générales de CIVIPOL par mail:</Text>
                        <Text style={styles.normalBlackText}>
                            {
                                (props.identity?.validationConfirmation)
                                    ? (props.identity.notificationActus)
                                        ? "OUI"
                                        : "NON"
                                    : "Non Renseigné"
                            }
                        </Text>

                    </View>
                </View>
            </View>
            <View>
                <Text style={styles.blueSubtitles}>Comment l'expert a découvert CIVIPOL</Text>
                <View style={{ marginBottom: 15 }}>
                    <View style={selectStyle.selectContainer}>
                        <Text style={styles.normalBlackText}>{props.identity?.discovered?.label || 'Non Renseigné'}</Text>
                    </View>
                </View>
            </View>
            <View style={{ marginBottom: 15 }}>
                <Text style={styles.blueSubtitles}>Condamnations pénales</Text>
                <Text style={styles.greyLabel}>L'expert déclare sur l'honneur ne pas être soumis à une condamnation pénale et n'être également sujet à aucune procédure pénale en cours:</Text>
                <Text style={styles.normalBlackText}>
                    {
                        (props.identity?.validationConfirmation)
                            ? (props.identity?.criminal_conviction)
                                ? "OUI"
                                : "NON"
                            : "Non renseigné"
                    }
                </Text>
            </View>
            <View style={{ marginBottom: 15 }}>
                <Text style={styles.blueSubtitles}>Exactitude des informations</Text>
                <Text style={styles.greyLabel}>L'expert déclare sur l'honneur que les informations saisies sur son profil sont exactes:</Text>
                <Text style={styles.normalBlackText}>
                    {
                        (props.identity?.validationConfirmation)
                            ? (props.identity?.infosExacts)
                                ? "OUI"
                                : "NON"
                            : "Non renseigné"
                    }
                </Text>
            </View>
        </>
    );
};

export default PDFConfirmations;