import gql from "graphql-tag";

const GET_EVALUATION_EMPLOYE = gql`
    query getInfosEvaluations($id_evaluation: ID!){
        getInfosEvaluations(id_evaluation: $id_evaluation){
            id_expert
            firstName
            lastName
            projectName
            offerName
            linkToOffer
            countryIntervention
            startDate
            endDate
            id_offer
            yearEvaluation
            evaluation_counter
        }
    }
`;

const GET_EVALUATION_EXPERT_BY_PAGE = gql`
    query getEvaluationByPage($id_mission: ID!) {
        getEvaluationByPage(id_mission: $id_mission) {
            id
            projectCode
            createdAt
            expert {firstName lastName}
            criterias {name value}
            generalEvaluation
            poleChief
            employe { login }
            teamLeader { email_1 }
            reniew_trial_period
            mission {
                offer {
                    id
                    geographical_zone {fr en}
                    title {fr en}
                    project {
                        title {fr en}
                    }
                }
            }
        }
    }
`;

export { GET_EVALUATION_EMPLOYE, GET_EVALUATION_EXPERT_BY_PAGE };