const handleSubmit = (values, identityCallBack, id) => {
    let tmp = Object.create(values);

    let obj = {
        identity: tmp.identity,
    }
    for (let value in obj.identity) {
        if (obj.identity[value] === "") {
            delete obj.identity[value]
        }
    }
    delete obj.identity.discovered;
    delete obj.identity.status;
    delete obj.identity.id;
    delete obj.identity.ICE
    delete obj.identity.mission_duration
    delete obj.identity.notificationActus
    delete obj.identity.notificationOffers
    obj.identity.countries = JSON.stringify(obj.identity.countries)
    identityCallBack({ variables: { input: obj, expertID: id } })
}

export default handleSubmit;