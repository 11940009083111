import { translate } from "./translation.constants";

export const searchEngineConstant = {
    SEARCH_TITLE: translate("searchEngine.searchTitle"),
    PLACEHOLDER_INPUT: translate("searchEngine.placeHolderInput"),
    SEARCH_BUTTON_INPUT: translate("searchEngine.searchButtonInput"),
    DELETE_BUTTON_INPUT: translate("searchEngine.deleteButtonInput"),
    ADVANCED_SEARCH_TITLE: translate("searchEngine.advancedSearchTitle"),
    HISTORY_SEARCH: translate("searchEngine.historySearch"),
    DELETE: translate("searchEngine.delete"),
    MISSION_DURATION: translate("searchEngine.missionDuration"),
    PUBLIC_FUNCTION: translate("searchEngine.publicFunction"),
    SINCE: translate("searchEngine.since"),
    SELECT_ONLY_RECOMMANDED: translate("searchEngine.selectOnlyRecommandedProfile"),
    DISPLAY_ONLY_RECOMMANDER: translate("searchEngine.displayOnlyRecommandedProfile"),
    DISPLAY_ONLY_DISPO: translate("searchEngine.displayOnlyDispoProfile"),
    SEARCH_ENGINE_BUTTON: translate("searchEngine.searchEngineButton"),
    ABILITIES: translate("searchEngine.abilities"),
    ABILITIES_SENIOR_SUBTITLE: translate("searchEngine.abilitiesSubtitle"),
    EXPORT_RESULTS_CSV: translate("searchEngine.exportResultsCSV"),
    EXPORT_RESULTS_XLS: translate("searchEngine.exportResultsXLS"),
    DISABLE_ADBLOCK: translate("searchEngine.exportResults"),
    OFFER_SUGGESTION: translate("searchEngine.offerSuggestion"),
}