import React, { useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Mutation } from "react-apollo";
import { SIGN_IN } from "../../mutations/signinMutation";
import { EmployeeContext } from "../../stores/employeeStore";
import Error from "../error/Error";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { setStorageUser } from "../utils/localStorage";


const SignIn = (props) => {

    const employeeStore = useContext(EmployeeContext);
    const [state, setState] = useState({ variables: null });
    const [error, setError] = useState(null);

    //on submit of form check if it filled or not
    const handleClick = (state) => {
        return new Promise((resolve, reject) => {
            if (!state.variables || !state.variables.login || !state.variables.password) {
                reject("signin.errorFieldEmpty");
            } else resolve(state);
        });
    }

    //store values onchange inputs
    const handleInput = (e) => {
        setState({
            ...state, variables: {
                ...state.variables, [e.target.name]: e.target.value
            }
        });
    };

    //if handelClick is resolved set employe in localstorage then redirect to home page
    const setEmployee = (values) => {
        setStorageUser(values.signInEmployee);
        employeeStore.dispatch({
            type: "setUser", target: {
                key: values.signInEmployee.employe !== null ? "employee" : "teamLeader",
                value: values.signInEmployee.employe ? values.signInEmployee.employe : values.signInEmployee.expert
            }
        });
        if (values.signInEmployee.expert) {
            props.history.push("/team-leader");
        } else {
            props.history.push("/home");
        }
    };

    return (
        <div id="login" className="d-flex flex-column justify-content-center pt-3">
            <img alt="Civipol Base Experts Administration" className="img-fluid logo mb-3" src={require("../../static/images/logo/logo-civipol-admin-white.svg")} />
            <div className="form rounded shadow-lg">
                <div className="header-login d-flex justify-content-center align-items-center">
                    <h1 className="mb-0 text-white text-uppercase"><FormattedMessage id="signin.title" defaultMessage="Not translated" /></h1>
                </div>
                <div className="form-login">
                    <form className="bg-white px-5 py-5 mb-0">
                        <div className="form-group mb-4">
                            <label htmlFor="email"><FormattedMessage id="signin.login" defaultMessage="Not translated" /></label>
                            <input placeholder="p.nom" name="login" type="email" className="form-control" id="email" onChange={handleInput} />
                        </div>
                        <div className="form-group mb-5">
                            <label htmlFor="password"><FormattedMessage id="signin.password" defaultMessage="Not translated" /></label>
                            <input name="password" type="password" className="form-control" id="password" onChange={handleInput} />
                        </div>
                        <div className="text-center">
                            <Error error={error} />
                        </div>
                        <Mutation mutation={SIGN_IN} onError={({ graphQLErrors }) => { setError(graphQLErrors[0].message) }} onCompleted={setEmployee}>
                            {(signin) => <button className="btn btn-civipol btn-block" onClick={(e) => { e.preventDefault(); handleClick(state).then((state) => signin(state)).catch(err => setError(err)) }}>Se connecter</button>}
                        </Mutation>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default withCookies(withRouter(SignIn));