import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route  {...rest}
            render={props => {
                return (
                    (Component) ?
                        <Component {...props} />
                        :
                        <Redirect to={{ pathname: "/home" }} />
                )
            }}
        />
    );
}

export default PrivateRoute;