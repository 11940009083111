import React, { useEffect, useContext } from "react";
import client from "../../../ApolloSetup";
import { expertPageContext } from "../../../stores/expertsPageStore";
import { withRouter, Link } from "react-router-dom";
import { GET_EXPERTS_BY_PAGE } from "../../../queries/getExperts";
import PaginationComponent from "../../pagination/PaginationComponent";
import ExpertCard from "./card/ExpertCard";
import SearchEngine from "../../searchEngine/SearchEngine";
import { Loader } from "../../loader/Loader";
import ExpertNotFound from "../sections/ExpertNotFound";
import { getSessionItemByKey, setSessionItemByKeyValue } from "../../utils/sessionStorage";
import { emptyObj } from "../../../stores/searchEngineStore";

const getExperts = async ({ dispatch }) => {

    let { page, search } = getSessionItemByKey("searchExperts");
    const emptyObj = {
        inputSearch: "",
        mission_duration: "",
        nationality: "",
        countries: "",
        language: {
            langue: "",
            langue_level: "",
        },
        skills: [],
        servicesDetails: {},
        disponibility: "",
        display_disponible: false,
    };
    let filters = (JSON.stringify(search) === JSON.stringify(emptyObj) ||  !search) ? {} : { filters: { ...search } };
    await client.query({
        query: GET_EXPERTS_BY_PAGE,
        variables: { page: parseInt(page), filters: filters }
    })
        .then((values) => {
            if (values.data.getExperts === null) {
                throw new Error();
            } else {
                dispatch({ type: "setExperts", target: values.data.getExperts });
            }
        })
        .catch(() => {
            dispatch({ type: "setError", target: "ERROR" });
        })
}

const ExpertsMapper = (props) => {

    const { match } = props;
    const expertsContext = useContext(expertPageContext);

    useEffect(() => {
        let searchExpertsStorage = getSessionItemByKey("searchExperts");
        setSessionItemByKeyValue("searchExperts", { ...searchExpertsStorage, page: match.params.page });
        getExperts(expertsContext);
    }, [match.params]);

    if (expertsContext.state.loading) return (<Loader class="center" />)
    else if (expertsContext?.state?.experts?.length === 0) return (<div><ExpertNotFound /></div>)
    else if (expertsContext.state.experts) {
        const { experts } = expertsContext.state;
        return (
            <section className="mb-5">

                {experts.map((value, key) => {
                    return (
                        <div className="text-decoration-none" key={key}>
                            <div key={key} className="row flex-row">
                                <ExpertCard  {...value} />
                            </div>
                        </div>
                    )
                })}
                <PaginationComponent context={expertsContext} keySearchStorage="searchExperts" />
            </section>
        )
    } else return null;
}

const Experts = (props) => {

    return (
        <div id="content" className="py-5">
            <div className="container">
                <SearchEngine />
                <hr className="mb-4" />
                <ExpertsMapper {...props} />
            </div>
        </div>
    )
}

export default withRouter(Experts);