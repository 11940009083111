import React, { Fragment } from "react";
import Alert from "./alerts/Alert";
import PromptAlert from "./alerts/PromptAlert";
import ToastComponent from "./toasts/Toast";

const Notification = () => {

    return (
        <Fragment>
            <Alert />
            <PromptAlert />
            <ToastComponent />
        </Fragment>
    )
}

export default Notification;
