import React, { createContext, useReducer } from "react";

const initialState = {
    experts: null,
    nb_all: null,
    nb_consulted: null,
    loading: true,
    error: false,
    update: false,
    expertsConsulted: null,
    isResearch: false,
}

const expertPageContext = createContext(initialState);

const reducer = (state, action) => {
    let { type, target } = action;
    switch (type) {
        case "setExperts": {
            return { ...state, experts: target.experts, loading: false, nb_all: target.nb_all, error: false }
        }
        case "setExpertsResearch": {
            return { ...state, experts: target.experts, loading: false, nb_all: target.nb_all, isResearch: true, error: false }
        }
        case "reset": {
            return { state }
        }
        case "setExpertsConsulted": {
            if (target) {
                return {
                    ...state,
                    expertsConsulted: target,
                    loading: false,
                    nb_consulted: target.length,
                    error: false
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    error: false
                }
            }
        }
        case "setError": {
            return { ...state, error: target, loading: false }
        }
        default: {
            return {state}
        }
    }
}

const ExpertPageProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    
    return (
        <expertPageContext.Provider
            value={{ state, dispatch }}
        >
            {children}
        </expertPageContext.Provider>
    )
}

export { expertPageContext, ExpertPageProvider };