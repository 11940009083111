import React, { useContext } from "react";
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../../stores/collapseStore";
import { Mutation } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { generalConstants } from "../../../constants/general.constants";
import * as utils from "../../../utils/utils";
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { UPSERT_CV } from "../../../mutations/upsertExpert";
import { ExpertDetailsContext } from "../../../stores/expertsDetailsStore";
import { TranslateContextWrapper } from "../../../stores/TranslateContext";
import CVComponent from "./sections/CVComponent";
import { notificationContext } from "../../../stores/notificationsStore";
import ConfirmationComponent from '../confirmation/sections/ConfirmationComponent';
import { errorsGesture } from "../../utils/errors";
import { useParams } from "react-router-dom";
import { handleSubmit } from './submit';
const CV = (props) => {
    const { MODIFY, REGISTER } = generalConstants;
    const { state } = useContext(ExpertDetailsContext);
    const notification = useContext(notificationContext);
    const collapseContext = useContext(CollapseContext);
    const collapseProps = useCollapse({ isOpen: collapseContext.state.CV });
    const { getToggleProps, getCollapseProps } = collapseProps;
    const params = useParams();
    return (
        <Mutation
            mutation={UPSERT_CV}
            onCompleted={(datas) => {
                notification.dispatch({ type: "setToast", target: { element: "successModification" } });
            }}
            onError={(datas) => errorsGesture(datas, () => notification.dispatch({ type: "setAlert", target: { element: "errorAuthorizationAction" } }))}
        >
            {(CVCallback, { error, loading }) => {
                return (
                    <Formik initialValues={state.expert}
                        enableReinitialize
                        onSubmit={(values) => notification.dispatch({ type: "setAlert", target: { element: "modification", callback: () => handleSubmit(values, CVCallback, params.id) } })}
                        render={formikBag => {
                            return (
                                <section>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <div className="d-flex align-items-center">
                                            <span className="icon-number"><b>4</b></span>
                                            <h2 className="mb-0"><FormattedMessage id='menu.CV' defaultMessage="Not translated" /></h2>
                                        </div>
                                        <button className="btn btn-civipol-white" {...getToggleProps({
                                            onClick: () => {
                                                collapseContext.methods.setCollapse("CV", !collapseContext.state.CV);
                                            }
                                        })}>{MODIFY}</button>
                                    </div>
                                    <section name="CV" {...getCollapseProps()} onTransitionEnd={(e) => {
                                        for (let values in collapseContext.state) {
                                            if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                e.target.style.height = "";
                                                utils.scrollTo(values);
                                            }
                                        }
                                    }}>
                                        <div className="card">
                                            <div className="card-body">
                                                <Form onSubmit={formikBag.handleSubmit}>
                                                    <div className="profile-sections">
                                                        <CVComponent {...formikBag} />
                                                        <ConfirmationComponent translate={props.translateContext} {...formikBag} />
                                                        <div className="card-footer border-top-0 py-4">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <button className="btn btn-civipol mx-2" type="submit">{REGISTER}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            )
                        }}
                    />
                )
            }}
        </Mutation>
    )
}

export default TranslateContextWrapper(CV);