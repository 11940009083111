import React, { useContext, useState } from 'react';
import { ExpertDetailsContext } from "../../../stores/expertsDetailsStore";
import { FormattedMessage } from 'react-intl';
import useCollapse from "react-collapsed";
import * as utils from "../../../utils/utils";
import { Mutation } from 'react-apollo';
import { Formik } from 'formik';
import { generalConstants } from "../../../constants/general.constants";
import { Form } from 'react-bootstrap';
import PersonsToContact from './PersonsToContact';
import PersonalInformations from './PersonalInformations';
import MedicalInformations from './MedicalInformations';
import {useRouteMatch} from 'react-router-dom';
import {handleSubmit} from './submit';
import gql from 'graphql-tag';
import { CollapseContext } from "../../../stores/collapseStore";
import { errorsGesture } from '../../utils/errors';
import { notificationContext } from '../../../stores/notificationsStore';
//MUTATION
const UPSERT_INDIVIDUALS_INFORMATIONS = gql`
    mutation upsertIndividualInformations($input: IndividualInformationsInputs, $expertID: ID) {
        upsertIndividualInformations(input: $input, expertID: $expertID) {
            id
        }
    }
`;

//INPUTS VALUES
const ICEinputs = {
    first_name: '',
    last_name: '',
    address_number_street: '',
    address_postal_code: '',
    address_city: '',
    address_country: '',
    relationship: '',
    telephone: '',
    email: '',
};

export const fileInput = {
    name: "",
    file: null,
    mimetype: '',
    size: ''
};

//COMPONENT
const IndividualsInformations = () => {
    const expertStore = useContext(ExpertDetailsContext);
    const initialState = {
        ICE: (expertStore.state.expert.ICE && expertStore.state.expert.ICE.length > 0) ? expertStore.state.expert.ICE : [ICEinputs],
        personalInformations: expertStore.state.expert.personalInformations || {
            matrimonialName: '',
            placeOfBirth: '',
            nativeCountry: '',
            passportNumber: '',
            passportPlaceDelivery: '',
            passportExpirationDate: '',
            passportFile: fileInput,
            socialSecurityNumber: '',
            vitalCardFile: fileInput,
            ibanNumber: '',
            ribNumber: '',
            rib: fileInput,
        },
        medicalInformations: expertStore.state.expert.medicalInformations || {
            doctorName: '',
            doctorAddress: '',
            doctorPhoneNumberFix: '',
            doctorCellPhoneNumber: '',
            doctorEmail: '',
            bloodGroup: '',
            rhesus: '',
            therapeuticsContrindications: '',
            allergies: ''
        }
    };
    const notification = useContext(notificationContext);
    const { MODIFY, REGISTER } = generalConstants;
    const [clicked, setClick] = useState(false);
    const [alertUpload, openAlertUpload] = useState(false);
    const [alertModification, openAlertModification] = useState(false);
    const collapseContext = useContext(CollapseContext);
    const collapseProps = useCollapse({ isOpen: collapseContext.state.individualsInformations });
    const { getToggleProps, getCollapseProps } = collapseProps;
    const match = useRouteMatch();

    return (
        <Mutation
            onCompleted={() => {
                notification.dispatch({ type: "setToast", target: { element: "successModification" } });
            }}
            mutation={UPSERT_INDIVIDUALS_INFORMATIONS}
            onError={(errors) => errorsGesture(errors, () => notification.dispatch({ type: "setAlert", target: { element: "errorAuthorizationAction" } }))}
        >
            {(upsertIndividualInformations, { errors, loading }) => {
                openAlertUpload(loading);
                return (
                    <Formik
                        initialValues={initialState}
                        onSubmit={(values) => {
                            notification.dispatch({ type: "setAlert", target: { element: "modification", callback: () => handleSubmit(values, upsertIndividualInformations, match.params.id) } });
                        }}
                        enableReinitialize
                        render={
                            (formikBag) => {
                                return (
                                    <section className="section-form">
                                        <div className="d-flex justify-content-between align-items-center mb-4 ">
                                            <div className="d-flex align-items-center">
                                                <span className="icon-number"><b>5</b></span>
                                                <h2 className="mb-0"><FormattedMessage id='menu.individualsInformations' defaultMessage="Not translated" /></h2>
                                            </div>
                                            <button className="btn btn-civipol-white" {...getToggleProps({ onClick: async (e) => { setClick(!clicked); await collapseContext.methods.setCollapse("individualsInformations", !collapseContext.state.individualsInformations); } })}>{MODIFY} </button>
                                        </div>
                                        <section name="individualsInformations" {...getCollapseProps()} onTransitionEnd={(e) => {
                                            for (let values in collapseContext.state) {
                                                if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                    e.target.style.height = "";
                                                    utils.scrollTo(values);
                                                }
                                            }
                                        }}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <Form onSubmit={formikBag.handleSubmit}>
                                                        <div className="profile-sections">
                                                            {/* ICES */}
                                                            <h2 className="title-section-blue text-uppercase mb-4"><FormattedMessage id={"expertForm.individualsInformations.personsToContact.title"} defaultMessage="Not translated" /></h2>
                                                            <PersonsToContact formikProps={formikBag} ICEinputs={ICEinputs} />
                                                            {/* PERSONAL INFORMATIONS */}
                                                            <h2 className="title-section-blue text-uppercase mb-4"><FormattedMessage id={"expertForm.individualsInformations.personalInformations.title"} defaultMessage="Not translated" /></h2>
                                                            <PersonalInformations formikProps={formikBag} />
                                                            {/* MEDICAL INFORMATION */}
                                                            <h2 className="title-section-blue text-uppercase mb-4"><FormattedMessage id={"expertForm.individualsInformations.medicalInformations.title"} defaultMessage="Not translated" /></h2>
                                                            <MedicalInformations formikProps={formikBag} />
                                                        </div>
                                                        <div className="card-footer border-top-0 py-4">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <button className="btn btn-civipol mx-2" type="submit">{REGISTER}</button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </section>
                                    </section>
                                );
                            }}
                    />
                );
            }
            }
        </Mutation>
    );

};

export default IndividualsInformations;