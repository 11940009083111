import React, { useContext, useEffect } from "react";
import { GET_CONSULTATIONS } from "../../../../queries/getConsultations";
import client from "../../../../ApolloSetup";
import { expertPageContext } from "../../../../stores/expertsPageStore";
import { Link } from "react-router-dom";
import NoResultTemplate from "../../../templates/NoResultTemplate";
import ConsultedComponent from "./cards/ConsultedComponent";

import { template } from "../../../../constants/templates.constants";

const {
    CONSULTED_PROFILE_TITLE,
    CONSULTED_PROFILE_TEXT
} = template;

const DisplayComponents = () => {
    const expertStore = useContext(expertPageContext);

    const getExpertsConsultedAsync = async () => {
        await client.query({
            query: GET_CONSULTATIONS,
        })
            .then(values => expertStore.dispatch({ type: "setExpertsConsulted", target: values.data.getConsultations }))
            .catch(err => expertStore.dispatch({ type: "setError", target: err }));
    }

    useEffect(() => {
        getExpertsConsultedAsync();
    }, []);

    if (expertStore.state.error) {
        return <div>ERROR</div>;
    } else if (expertStore.state.nb_consulted > 0) {
        let experts = expertStore.state.expertsConsulted.map((value, key) => {
            return (
                <Link to={`/experts/expert/${value.expert.id}`} className="col-12 col-lg-6 mb-4 card-group text-decoration-none" key={key}>
                    <ConsultedComponent {...value} />
                </Link>
            )
        })
        return experts;
    }
    else {
        return <NoResultTemplate title={CONSULTED_PROFILE_TITLE} text={CONSULTED_PROFILE_TEXT} />;
    }
}

const ProfilesConsulted = () => {
    return (
        <div>
            <div><h2 className="big-title mb-5"> Mes derniers profils consultés </h2></div>
            <section className="mb-5">
                <div className="row">
                    <DisplayComponents />
                </div>
            </section>
        </div>
    )
}

export default ProfilesConsulted;