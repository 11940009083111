import React, { useContext } from "react";
import { searchEngineContext } from "../../../../stores/searchEngineStore";
import { historicSearchContext } from "../../../../stores/historicSearchStore";
import { notificationContext } from "../../../../stores/notificationsStore";
import client from "../../../../ApolloSetup";
import { REGISTER_SEARCH } from "../../../../mutations/historic";
import { withCookies } from "react-cookie";

const handleClick = (search, name, dispatch) => {

    client.mutate({
        mutation: REGISTER_SEARCH,
        variables: { search: search, name: name }
    })
        .then(value => { 
            dispatch({ type: "setHistoricArray", target: value.data.setHistoric }); 
            dispatch({ target: "updateHistoric" }) })
        .catch(err => console.log(err))
}

const RegisterSearch = (props) => {

    const searchStore = useContext(searchEngineContext);
    const historicStore = useContext(historicSearchContext);
    const notificationStore = useContext(notificationContext);
    return (
        <button type="button"
            onClick={() => notificationStore.dispatch({ type: "setAlert", target: { element: "historic", callback: (title) => handleClick(searchStore.state, title, historicStore.dispatch) } })}
            href="#"
            className="btn btn-civipol-white btn-save mb-2 mb-md-0 mr-md-2"
            >
            Enregistrer cette recherche
        </button>
    )
};

export default withCookies(RegisterSearch);