import React, { useContext } from "react";
import { OfferDetailsContext } from "../../stores/detailsOfferStore";
import { Markup } from 'interweave';
import moment from "moment";

const DetailsOffer = () => {

    const detailsStore = useContext(OfferDetailsContext);
    const { offer, project } = detailsStore.state;

    if (!offer) return null;
    else {
        const { title, introduction_text, application_expiration, geographical_zone, profile_text, description_text } = offer;
        const { contract_type } = project;
        return (
            <div className="description-project" >
                <div className="row">
                    <div className="col-12 col-lg-8 mb-5 mb-lg-0">
                        <h2 className="mb-5">{(title["fr"])}</h2>
                        <div className="mb-5">
                            <Markup content={introduction_text["fr"]} />
                            <Markup content={description_text["fr"]} />
                            <Markup content={profile_text["fr"]} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="row">
                            <div className="col description-block-project">
                                <div className="px-3 py-4" style={{ backgroundColor: "#F7F7F7" }}>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <h2 className="big-title mb-5">
                                                À PROPOS DE L'OFFRE
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <h3 className="text-uppercase">DATE DE FIN DE CANDIDATURE</h3>
                                            <p></p>
                                            <p>{moment(application_expiration).format("DD/MM/YYYY")}</p>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <h3 className="text-uppercase">TYPE DE CONTRAT</h3>
                                            <p>{contract_type && contract_type["fr"]}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="text-uppercase">ZONE GÉOGRAPHIQUE</h3>
                                            <p>{geographical_zone && geographical_zone["fr"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetailsOffer;