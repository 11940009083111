import React from "react";
import {notificationsConstants} from "../../../../constants/missions.constants";

const {TITLE_CANDIDATURES, BUTTON_CANDIDATURES, TITLE_MISSIONS, BUTTON_MISSIONS} = notificationsConstants;

const NotificationMissionsTemplate = ({ type }) => {
    let illustration = "";
    if (type === "missions") {
        illustration = require("../../../../static/images/alert-card/illu_no_missions.svg")
    } else {
        illustration = require("../../../../static/images/alert-card/illu_candidatures.svg")
    }

    return (
        <div className="col">
            <div className="d-flex justify-content-between notifications position-relative mb-4">
                <div className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                    <img src={illustration} alt="illu_mission" style={{maxHeight: "150px", maxWidth: "150px"}} className="img-fluid mb-4 mb-lg-0 mr-lg-5" />
                    <div className="d-flex flex-column align-items-center align-items-lg-start">
                        <div className="mb-4">
                            <p className="title text-center text-lg-left">
                            {type === "missions" ? TITLE_MISSIONS : TITLE_CANDIDATURES}
                            </p>
                        </div>
                        <button className="btn btn-civipol-white">
                        {type === "missions" ? BUTTON_MISSIONS : BUTTON_CANDIDATURES}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationMissionsTemplate;
