import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import PhoneInput from "../../../phone-input/PhoneInput";
import Input from "./Input";
import Selector from '../../../selector/Selector';
import * as countries from "i18n-iso-countries";
import { identityConstants } from "../../../../constants/profile-expert.constants";

const {
    EXPERT_INFORMATIONS_COORDINATES,
    EXPERT_INFORMATIONS_CONTACT,
    PRINCIPAL_ADDRESS
} = identityConstants;

const sectionMessageName = "profile.sections.identity.";

const SecondAddress = (props) => {
    let array = Object.values(countries.getNames("fr"));
    const path = "profile.sections.identity.contactInformations.secondAddress";

    return (
        <div >
            <div className="row">
                <div className="col">
                    <p><FormattedMessage id={"expertForm.identity.address_2"} defaultMessage="Not translated" /></p>
                </div>
            </div>
            <Input name="identity.address_2_number_street" label={"expertForm.identity.numberAndStreet_2"} padding="pl-0" columns="col-12" formik={props} maxlength="60" />
            <div className="row flex-row justify-content-around">
                <Input name="identity.address_2_postal_code" label={"expertForm.identity.address_2_postal_code"} formik={props} maxlength="20" />
                <Input name="identity.address_2_city" label={"expertForm.identity.address_2_city"} formik={props} maxlength="30" />
            </div>
            <div className="col-6 mb-4 px-0">
                <Selector array={array} section="identity" name="address_2_country" path2={path} {...props} />
            </div>
        </div>
    )
}

// ce composant affiche l'input pour la premiere addresse
const FirstAddress = (props) => {
    let array = Object.values(countries.getNames("fr"));
    return (
        <div>
            <div className="row">
                <div className="col">
                    <p>{PRINCIPAL_ADDRESS}</p>
                </div>
            </div>
            <div className="row">
                <Input name="identity.address_1_number_street" label="expertForm.identity.address_1_number_street" columns="col" formik={props} />
            </div>
            <div className="row">
                <Input name="identity.address_1_postal_code" label={"expertForm.identity.address_1_postal_code"} maxlength="20" formik={props} />
                <Input name="identity.address_1_city" label={"expertForm.identity.address_1_city"} maxlength="30" formik={props} />
            </div>
            <div className="row">
                <div className="col-6 mb-4">
                    <Selector array={array} section="identity" name="address_1_country" path2={"expertForm.identity"} {...props} />
                </div>
            </div>
        </div>
    )
}

const Contact = (props) => {
    return (
        <div>
            <div className="row">
                <div className="col">
                    <p>{EXPERT_INFORMATIONS_CONTACT}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <PhoneInput {...props} label={"expertForm.identity.tel_mobile"} section="identity" name="tel_mobile" />
                </div>
                <div className="col-12 col-md-6">
                    <PhoneInput {...props} label={"expertForm.identity.telephone_2"} section="identity" name="tel_fix" facultatif />
                </div>
            </div>
            <div className="row ">
                <Input name="identity.email_1" label={"expertForm.identity.email_1"} formik={props} maxlength="50" />
                <Input name="identity.email_2" label={"expertForm.identity.email_2"} formik={props} facultatif maxlength="50" />
            </div>
        </div>
    )
}

//retourne le composant de contact de l'expert
const ContactInformations = (props) => {
    //base path pour les traductions
    const path = sectionMessageName + "contactInformations";

    //permet de définir si on ouvre ou pas la boite de seconde adresse
    const [secondAddress, setSecondAddress] = useState(false);

    //si on clique sur ce bouton on affiche la boxe de seconde adresse -> lorsqu'on reclique supprime la seconde adresse
    const Button = () => (
        <button
            style={{ fontFamily: "DIN-BOLD" }}
            className="btn btn-civipol-white"
            onClick={(e) => {
                e.preventDefault();
                setSecondAddress(!secondAddress)
            }}>
            {<FormattedMessage id={(secondAddress) ? "expertForm.identity.address_2_close" : "expertForm.identity.address_2"} defaultMessage="Not translated" />}
        </button>
    )


    //retourne les informations de contact de l'expert
    return (
        <div className="mb-4">
            <h2 className="title-section-blue text-uppercase mb-4">{EXPERT_INFORMATIONS_COORDINATES}</h2>
            <FirstAddress {...props} path={path} />
            {secondAddress && <SecondAddress {...props} path={path} />}
            <Button />
            <Contact {...props} path={path} />
        </div>
    )
}

export default ContactInformations;
