import React, {createContext, useReducer} from "react";

const initialState = {
    expertsValidating: {
        nb_all: null,
        experts: null,
    },
}

const HomeStoreContext = createContext(initialState);

const reducer = (state, action) => {

    switch (action.type) {
        case "setExpertsValidationg": {
            return {
                ...state, 
                expertsValidating: action.target
            }
        }
        default: return state
    }
}

const HomeStoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <HomeStoreContext.Provider value={{state, dispatch}}>
            {children}
        </HomeStoreContext.Provider>
    )
}

export {HomeStoreContext, HomeStoreProvider}