import React from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneInput from "../../phone-input/PhoneInput";

const InvalidFeedBack = ({ formikProps, value }) => {
    return (
        <div className="is-invalid">
            <FormattedMessage id={formikProps.errors.medicalInformations[value]} />
        </div>
    );
};


const MedicalInformations = ({ formikProps }) => {

    const translationPath = 'expertForm.individualsInformations.medicalInformations.inputs';

    const isValid = (value) => (!formikProps.errors || !formikProps.errors.medicalInformations || !formikProps.errors.medicalInformations[value]) || (!formikProps.touched || !formikProps.touched.medicalInformations || !formikProps.touched.medicalInformations[value])
    const setValues = (value) => (formikProps.values.medicalInformations[value]) || "";
    
    return (
        <div className="form">
            <div className="row flex-row justify-content-around">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`medicalInformations.doctorName`} className={`${!isValid('doctorName') && 'is-invalid'}`}><FormattedMessage id={`${translationPath}.doctorName`} />*</label>
                        <input className="form-control" name={`medicalInformations.doctorName`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('doctorName')}></input>
                        {!isValid('doctorName') && <InvalidFeedBack formikProps={formikProps} value="doctorName" />}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`medicalInformations.doctorAddress`} className={`${!isValid('doctorAddress') && 'is-invalid'}`}><FormattedMessage id={`${translationPath}.doctorAddress`} />*</label>
                        <input className="form-control" name={`medicalInformations.doctorAddress`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('doctorAddress')}></input>
                        {!isValid('doctorAddress') && <InvalidFeedBack formikProps={formikProps} value="doctorAddress" />}
                    </div>
                </div>
            </div>
            <div className="row flex-row justify-content-around">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <PhoneInput
                            section="medicalInformations"
                            name="doctorPhoneNumberFix"
                            facultatif={false}
                            label={`${translationPath}.doctorPhoneNumberFix`}
                            required
                            {...formikProps}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <PhoneInput
                            section="medicalInformations"
                            name="doctorCellPhoneNumber"
                            facultatif={false}
                            label={`${translationPath}.doctorCellPhoneNumber`}
                            {...formikProps}
                        />
                    </div>
                </div>
            </div>
            <div className="row flex-row justify-content-left">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`medicalInformations.doctorEmail`} className={`${!isValid('doctorEmail') && 'is-invalid'}`}><FormattedMessage id={`${translationPath}.doctorEmail`} />*</label>
                        <input className="form-control" name={`medicalInformations.doctorEmail`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('doctorEmail')}></input>
                        {!isValid('doctorEmail') && <InvalidFeedBack formikProps={formikProps} value="doctorEmail" />}
                    </div>
                </div>
            </div>
            <div className="row flex-row justify-content-around">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`medicalInformations.bloodGroup`} className={`${!isValid('bloodGroup') && 'is-invalid'}`}><FormattedMessage id={`${translationPath}.bloodGroup`} />*</label>
                        <input className="form-control" name={`medicalInformations.bloodGroup`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('bloodGroup')}></input>
                        {!isValid('bloodGroup') && <InvalidFeedBack formikProps={formikProps} value="bloodGroup" />}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`medicalInformations.rhesus`} className={`${!isValid('rhesus') && 'is-invalid'}`}><FormattedMessage id={`${translationPath}.rhesus`} />*</label>
                        <input className="form-control" name={`medicalInformations.rhesus`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('rhesus')}></input>
                        {!isValid('rhesus') && <InvalidFeedBack formikProps={formikProps} value="rhesus" />}
                    </div>
                </div>
            </div>
            <div className="row flex-row justify-content-around">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`medicalInformations.therapeuticsContrindications`} className={`${!isValid('therapeuticsContrindications') && 'is-invalid'}`}><FormattedMessage id={`${translationPath}.therapeuticsContrindications`} />*</label>
                        <input className="form-control" name={`medicalInformations.therapeuticsContrindications`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('therapeuticsContrindications')}></input>
                        {!isValid('therapeuticsContrindications') && <InvalidFeedBack formikProps={formikProps} value="therapeuticsContrindications" />}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`medicalInformations.allergies`} className={`${!isValid('allergies') && 'is-invalid'}`}><FormattedMessage id={`${translationPath}.allergies`} />*</label>
                        <input className="form-control" name={`medicalInformations.allergies`} onChange={formikProps.handleChange} value={setValues('allergies')}></input>
                        {!isValid('allergies') && <InvalidFeedBack formikProps={formikProps} value="allergies" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MedicalInformations;