import gql from "graphql-tag";

const GET_CONSULTATIONS = gql`
    query getConsultations{
        getConsultations{
            expert{
                id
                lastName
                firstName
                profile_pic
                civility
                expert_ability {
                    fonctionnaire
                    DG
                }
                missions {
                    staffed
                }
            }
            date_consultation
        }
    }
`;

export { GET_CONSULTATIONS };