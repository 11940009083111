import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles, sectionTitleStyle, selectStyle } from '../styles/styles';

const PDFMissionSearched = (props) => {
    return (
        <View wrap={false}>
            <View style={sectionTitleStyle.container}>
                <View style={sectionTitleStyle.sectionTitlePastille}>
                    <Text>2</Text>
                </View>
                <Text style={sectionTitleStyle.sectionTitle}>Mission Recherchée</Text>
            </View>
            <View style={styles.sectionProfile}>
                <Text style={styles.blueSubtitles}>Type de mission</Text>
                <Text style={styles.blackSubtitles}>Longueur de la mission souhaitée</Text>
                <View style={selectStyle.selectContainer}>
                    {(props.identity.mission_duration.length > 0 && typeof props.identity.mission_duration !== 'string' )
                    ? props.identity.mission_duration.map((missionDuration, key) => <Text key={key} style={styles.normalBlackText}>{missionDuration?.label}</Text>)
                    : <Text style={styles.normalBlackText}>Non renseigné</Text>
                    }
                </View>
            </View>
        </View>
    );
};

export default PDFMissionSearched;