import React from "react";
import { TranslateContextWrapper } from "../../../../stores/TranslateContext";
import moment from "moment";

const IdentityFormatter = (props) => {
    const { log } = props;
    let logKey = Object.keys(log);
    let section = "", field = "", newValue = "", oldValue = "";
    if (logKey[0] !== "birthday" && logKey[0] !== "mission_duration") {
        section = props.translateContext.translateMessage("expertForm.identity.title");
        field = props.translateContext.translateMessage(`expertForm.identity.${logKey[0]}`);
        if (newValue) {
            newValue = log[logKey[0]].new
            oldValue = log[logKey[0]].old
            if (logKey[0] === "countries") {
                newValue = JSON.parse(log[logKey[0]].new);
                oldValue = JSON.parse(log[logKey[0]].old);
                if (newValue.length === 0) {
                    newValue = newValue = "aucun";
                } else {
                    newValue = newValue[0].label;
                }
                if (oldValue.length === 0) {
                    oldValue = "aucun";
                } else {
                    oldValue = oldValue[0].label;
                }
            }
        }
    }
    if (logKey[0] === "fonctionnaire" || logKey[0] === "nonFonctionnaire") {
        section = "Compétences";
        return `${section} - Les sélecteurs de situation professionnelle ont été modifiés.`;
    }
    if (logKey[0] === "birthday") {
        newValue = moment(newValue).format("DD/MM/YYYY");
        oldValue = moment(oldValue).format("DD/MM/YYYY");
    }
    if (logKey[0] === "mission_duration") {
        section = props.translateContext.translateMessage("expertForm.missionSearched.title");
        field = props.translateContext.translateMessage(`expertForm.missionSearched.missionDuration`);

        try {
            if (log[logKey[0]].old !== "") {
                let oldValueTmp = JSON.parse(log[logKey[0]].old);
                if (oldValueTmp) {
                    for (let i = 0; i < oldValueTmp.length; i++) {
                        oldValue += `${oldValueTmp[i].label} `;
                    }
                } else {
                    oldValue = "Non remplie";
                }
            } else {
                oldValue = "";
            }
            if (log[logKey[0]].new !== "") {
                let newValueTmp = JSON.parse(log[logKey[0]].new);

                if (newValueTmp) {
                    for (let i = 0; i < newValueTmp.length; i++) {
                        newValue += `${newValueTmp[i].label} `;
                    }
                } else {
                    newValue = "Non remplie";
                }
            }
            else {
                newValue = "";
            }
        } catch (err) {
            console.log("err =>", err);
        }
    }

    return `${section} - Le champs "${field}" a été modifé de "${oldValue}" à "${newValue}".`;
}

const ICEFormatter = (props) => {
    const { log } = props;
    let logKey = Object.keys(log);
    const section = props.translateContext.translateMessage("expertForm.ICE.iceTitle");
    const field = props.translateContext.translateMessage(`expertForm.ICE.${logKey[0]}`);
    let newValue = log[logKey[0]].new
    let oldValue = log[logKey[0]].old

    if (logKey[0] === "birthday") {
        newValue = moment(newValue).format("DD/MM/YYYY");
        oldValue = moment(oldValue).format("DD/MM/YYYY");
    }
    return `${section} - Le champs "${field}" a été modifé de "${oldValue}" à "${newValue}".`;
}

const LanguagesFormatter = (props) => {
    let { log } = props;
    let logKey = Object.keys(log);
    return `Compétences - Le champs "${logKey[0]}" de "Language ${log[logKey[0]].key + 1}" a été modifié de "${log[logKey[0]].old}" à "${log[logKey[0]].new}".`;
}

const SkillsFormatter = (props) => {
    let { log } = props;
    let logKey = Object.keys(log);
    let actionType = ""
    if (log[logKey[0]].type === "delete") {
        actionType = "Suppression";
    }
    else if (log[logKey[0]].type === "create") {
        actionType = "Ajout";
    } else {
        actionType = "Modification";
        let tag = Object.keys(log[logKey[0]]);
        let values = {};
        let modif = "";
        if (tag[0] === "diploma") {
            values = { isValidate: (log[logKey[0]][tag].new === true) ? "cochée" : "décochée" };
            modif = props.translateContext.translateMessage("expertForm.abilities.diploma", "", values);
            return (`Compétences - ${actionType} du domaine d'expertise "${props.translateContext.translateMessage("expertForm.abilities.tags." + logKey)}". ${modif}`);
        }
        else if (tag[0] === "year_experience") {
            values = { old: log[logKey[0]][tag].old, new: log[logKey[0]][tag].new };
            modif = props.translateContext.translateMessage("expertForm.abilities.year_experience", "", values);
            return (`Compétences - ${actionType} du domaine d'expertise "${props.translateContext.translateMessage("expertForm.abilities.tags." + logKey)}". ${modif}`);
        }
    }

    return (`Compétences - ${actionType} du domaine d'expertise "${props.translateContext.translateMessage("expertForm.abilities.tags." + logKey)}"`);
}

const SupportingDocumentFormatter = (props) => {
    let { log } = props;
    const logKey = Object.keys(log);
    if (logKey[0] === "CV") {
        return (`CV - Le CV a été modifié.`);
    } else {
        const actionType = log[logKey[0]].type;
        let logType = "";
        if (actionType === "create") {
            logType = "a été créé";
        }
        if (actionType === "update") {
            logType = "a été modififé";
        }
        if (actionType === "delete") {
            logType = "a été supprimé";
        }
        return (`Pièce justificative - ${props.translateContext.translateMessage("expertForm.documentToUpload." + logKey[0])} ${logType}`);
    }
}

const ModificationsLogs = (props) => {
    switch (props.table) {
        case "Identity": {
            return <IdentityFormatter {...props} />
        }
        case "Languages": {
            return <LanguagesFormatter {...props} />
        }
        case "ExpertCivilServiceDetail": {
            return <IdentityFormatter {...props} />
        }
        case "ICE": {
            return <ICEFormatter {...props} />
        }
        case "SKILLS": {
            return <SkillsFormatter {...props} />
        }
        case "SupportingDocument": {
            return <SupportingDocumentFormatter {...props} />
        }
        default: {
            return "Non disponible"
        }
    }
}

export default TranslateContextWrapper(ModificationsLogs);