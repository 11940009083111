import gql from "graphql-tag";

const GET_EXPERTS_BY_PAGE = gql`
    query getExperts($page: Int, $filters: Filter_Input){
        getExperts(page: $page, filters: $filters){
            nb_all,
            experts {
                id
                firstName
                lastName
                createdAt
                updatedAt
                profile_pic
                birthday
                address_1_number_street
                address_1_postal_code
                address_1_city
                address_1_country
                tel_mobile
                status
                email_1
                projectLeader
                civility
                supporting_documents{
                    type
                    number
                    link
                    path
                    name
                    extension
                }
                expert_ability {
                    fonctionnaire
                    DG
                }
                missions {
                    staffed
                    key_expert
                    offer {
                        id
                        title {
                            fr
                            en
                        }
                    }
                }
            }
        }
    }
`;

const GET_EXPERTS_IDS_BY_STATUS_COMPLETE = gql`
    query getExperts($page: Int, $filters: Filter_Input){
        getExperts(page: $page, filters: $filters){
            nb_all,
            experts {
                id
                firstName
                lastName
                createdAt
                updatedAt
                profile_pic
                expert_ability {
                    fonctionnaire
                    DG
                }
                missions {
                    staffed
                    key_expert
                }
            }
        }
    }
`;

const GET_ALL_EXPERTS_BY_SEARCH = gql`
    query getExperts($filters: Filter_Input) {
        getExperts(filters: $filters){
        experts {
            id
            firstName
            lastName
            status
            nationality_1
            nationality_2
            birthday
            address_1_number_street
            address_1_postal_code
            address_1_city
            address_1_country
            civility
            address_2_number_street
            address_2_postal_code
            address_2_city
            address_2_country
            tel_mobile
            tel_fix
            email_1
            email_2
            mission_duration
            notificationActus
            notificationOffers
            countries
            languages {level language }
            expert_ability {fonctionnaire fonc_level DG accept_salarial_contract accept_service_contract since }
            skills{type year_experience diploma}
            supporting_documents {type path name extension id}
            }
        }
    }
`;

const GET_EXPERT_BY_ID = gql`
    query getExpert($expertID: ID!){
    getExpert(expertID: $expertID) {
        status
        firstName
        lastName
        nationality_1
        nationality_2
        birthday
        address_1_number_street
        address_1_postal_code
        address_1_city
        address_1_country
        civility
        address_2_number_street
        address_2_postal_code
        address_2_city
        tel_mobile
        tel_fix
        email_1
        email_2
        mission_duration
        criminal_conviction
        notificationActus
        notificationOffers
        infosExacts
        profile_pic
        status
        countries
        discovered
        validationIdentity
        validationMissionSearched
        validationExpertAbilities
        validationCV
        validationSupportingDocuments
        validationConfirmation
        ICE{first_name last_name address_number_street address_postal_code address_city address_country telephone email}
        languages {level language }
        supporting_documents{id type number link path name extension}
        expert_ability {
            fonctionnaire
            fonc_level
            DG
            accept_salarial_contract
            accept_service_contract
            last_remuneration
            since entity
            level
            is_retired
            date_retired
            is_fonctionnaire
            type_of_contract
        }
        skills {
            type
            year_experience
            diploma
        }
        personalInformations {
            id
            matrimonialName
            placeOfBirth
            nativeCountry
            passportNumber
            passportPlaceDelivery
            passportExpirationDate
            passportFile
            socialSecurityNumber
            vitalCardFile
            ibanNumber
            ribNumber
            rib
        }
        medicalInformations {
            doctorName
            doctorAddress
            doctorPhoneNumberFix
            doctorCellPhoneNumber
            doctorEmail
            bloodGroup
            rhesus
            therapeuticsContrindications
            allergies
        }
        ICE {
            first_name
            last_name
            address_number_street
            address_postal_code
            address_city
            address_country
            relationship
            telephone
            email
        }
        wasAcceptedToMission
        projectLeader
        }
    }
`;

const GET_EXPERT_PDF = gql`
    query getProfileExportPDF($expertID: ID!, $document: Upload) {
        getProfileExportPDF(expertID: $expertID, document: $document) {
            id
        }
    }
`;

export { GET_EXPERTS_BY_PAGE, GET_EXPERTS_IDS_BY_STATUS_COMPLETE, GET_EXPERT_BY_ID, GET_ALL_EXPERTS_BY_SEARCH, GET_EXPERT_PDF };