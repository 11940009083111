import React, { useContext } from "react";
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../../stores/collapseStore";
import { ExpertDetailsContext } from "../../../stores/expertsDetailsStore";
import { Mutation } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { generalConstants } from "../../../constants/general.constants";
import * as utils from "../../../utils/utils";
import { handleSubmit } from "./submit";
import { withRouter } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { UPSERT_ABILITIES } from '../../../mutations/upsertExpert';
import { Formik } from 'formik';
import Languages from "./sections/Languages";
import DomainExpertise from "./sections/DomainExpertise";
import CountriesSelector from "./sections/CountriesSelector";
import { notificationContext } from "../../../stores/notificationsStore";
import ProfessionalSituation from './sections/ProfessionalSituation';
import { errorsGesture } from "../../utils/errors";

const Abilities = (props) => {

    const notification = useContext(notificationContext);
    const { state } = useContext(ExpertDetailsContext);
    const { id } = props.match.params;
    const { MODIFY, REGISTER } = generalConstants;
    const collapseContext = useContext(CollapseContext);
    const { getToggleProps, getCollapseProps } = useCollapse({ isOpen: collapseContext.state.abilities });

    return (
        <Mutation
            onCompleted={(datas) => {
                notification.dispatch({ type: "setToast", target: { element: "successModification"} });
            }}
            onError={(datas) => errorsGesture(datas, () => notification.dispatch({ type: "setAlert", target: { element: "errorAuthorizationAction" } }))}
            mutation={UPSERT_ABILITIES}
        >
            {(abilitiesCallback, { errors, loading }) => {
                return (
                    <Formik
                        initialValues={state.expert}
                        onSubmit={(values) => {
                            notification.dispatch({ type: "setAlert", target: { element: "modification", callback: () => handleSubmit(values, abilitiesCallback, id) } });
                        }}
                        render={formikBag => {
                            return (
                                <section>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <div className="d-flex align-items-center">
                                            <span className="icon-number"><b>3</b></span>
                                            <h2 className="mb-0"><FormattedMessage id='menu.abilities' defaultMessage="Not translated" /></h2>
                                        </div>
                                        <button className="btn btn-civipol-white" {...getToggleProps({ onClick: () => { collapseContext.methods.setCollapse("abilities", !collapseContext.state.abilities); } })}>
                                            {MODIFY}
                                        </button>
                                    </div>
                                    <section name="abilities" {...getCollapseProps()} onTransitionEnd={(e) => {
                                        for (let values in collapseContext.state) {
                                            if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                e.target.style.height = ""
                                                utils.scrollTo(values);
                                            }
                                        }
                                    }}>
                                        <div className="card">
                                            <div className="card-body">
                                                <Form name="abilities" onSubmit={formikBag.handleSubmit}>
                                                    <Languages {...formikBag} />
                                                    <ProfessionalSituation {...formikBag} />
                                                    <DomainExpertise {...formikBag} />
                                                    <CountriesSelector {...formikBag} />
                                                    <div className="card-footer border-top-0 py-4">
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <button className="btn btn-civipol mx-2" type="submit">{REGISTER}</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            )
                        }}
                    />
                )
            }}
        </Mutation>
    )
}

export default withRouter(Abilities);