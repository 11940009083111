import { translate } from "./translation.constants";

export const template = {
    CONSULTED_PROFILE_TITLE: translate("template.consultedProfileTitle"),
    CONSULTED_PROFILE_TEXT: translate("template.consultedProfileText"),
    VALIDATION_PROFILE: translate("template.validationTitle"),
    VALIDATION_TEXT: translate("template.validationText"),
    NO_EVALUATION_TITLE: translate("template.noEvaluationTitle"),
    NO_EVALUATION_TEXT: translate("template.noEvaluationText"),
    NO_ACCOUNT_BLOCKED_TITLE: translate("template.noBlockedAccountTitle"),
    NO_ACCOUNT_BLOCKED_TEXT: translate("template.noBlockedAccountText"),
    ERROR_TITLE_DEFAULT: translate("template.errorDefaultTitle"),
    ERROR_TEXT_DEFAULT: translate("template.errorDefaultText"),
};