import gql from "graphql-tag";

const GET_MISSIONS_BY_EXPERT_ID = gql`
query getMissionsOfUser($expertID: ID!){
        getMissionsOfUser(expertID: $expertID){
                id
                staffed
                date_postulat
                date_rejected
                date_accepted
                offer {
                    id
                    title { fr en }
                    geographical_zone { fr en }
                    job_expertise {fr en parent id_taxo}
                    application_expiration
                    mission_length{ fr en }
                    project {
                        id
                        chronology
                        project_chief
                    }
                }
                evaluationEmployee {
                    id
                    is_complete
                    criterias {
                        name
                        value
                    }
                }
            }
    }
`;

export {GET_MISSIONS_BY_EXPERT_ID};