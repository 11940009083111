import React from "react";
import moment from "moment";
const ConsultationComponent = (props) => {
    const { createdAt, employe } = props;
    const date = moment(createdAt).format("DD/MM/YYYY - HH : mm");


    return (
        <div className="row px-4 mb-2">
            <div className="col">
                {date} {employe.login} a consulté le profil.
            </div>
        </div>
    );
}

export default ConsultationComponent;