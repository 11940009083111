import gql from "graphql-tag";

const GET_SEARCH = gql `
    query getHistoric{
        getHistoric{
            id
            name
            search
        }   
    }
`;

export {GET_SEARCH};