export const customStyles = {
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px"
    }),
    menu: (provided, state) => ({
        ...provided,
        position: "absolute",
        zIndex: "1000",
        height: "100px",
    }),
    menuList: (provided, state) => ({
        ...provided,
        height: "100px",
        zIndex: "1000",

    })

}