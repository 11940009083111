export const handleSubmit = (values, callback, id) => {
    
    const obj = {
        supporting_documents: values.supporting_documents.map(element => {
            if (element.id) {
                delete element.id;
            }
            return element;
        }),
        notificationActus: values.identity.notificationActus || null,
        notificationOffers: values.identity.notificationOffers || null,
        criminal_conviction: values.identity.criminal_conviction || null,
        discovered: JSON.stringify(values.identity.discovered) || null
    };
    callback({
        variables: {
            input: obj,
            expertID: id
        }
    });
};