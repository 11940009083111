import React from "react";

const onChange = (props) => {
    const {context, dispatchType, name} = props;
    const { dispatch, state } = context;
    
    dispatch({ type: dispatchType, target: { [name]: !state[name] } });
}

const CheckBoxColored = (props) => {

    const {context, name, label} = props;

    return (
        <div className="custom-control custom-switch mb-3">
            <input name={name} onChange={() => onChange(props)} type="checkbox" className="custom-control-input" id={name} checked={context.state[name]} />
            <label className="custom-control-label" htmlFor={name}>{label}</label>
        </div>
    )
}

export default CheckBoxColored;