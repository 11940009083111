import React from "react";
import { Link } from "react-router-dom";

const NoMatch = () => {

    return (
        <div id="content" className="py-5">
            <div className="container mb-5">
                <section className="mb-5">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-body text-center py-5">
                                    <img src={require("../static/images/not-found/illu_noserp.svg")} className="card-img img-fluid mb-4" alt="notFound"/>
                                    <h5 className="card-title mb-5">404 NOT FOUND</h5>
                                    <p className="card-text mb-5">La page que vous demandez n'est pas accessible ou n'existe pas.</p>
                                    <Link to="/home" className="btn btn-civipol" >Retour a l'accueil</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default NoMatch;