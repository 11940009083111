import React from 'react';
// import DatePicker from 'react-datepicker';
// import { registerLocale } from 'react-datepicker';
// import fr from 'date-fns/locale/fr';
// import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";

// registerLocale('fr', fr)



const CalendarComponent = (props) => {

    if (props.section) {
        const { section, name } = props;

        const handleChange = (e, props) => {
            props.setValues({
                ...props.values,
                [section]: {
                    ...props.values[section],
                    [name]: e.target.value
                }
            })
        }

        return (
            <input 
                type="date"
                className="form-control"
                value={moment(props.values[section][name]).locale("fr").format("YYYY-MM-DD")}
                onChange={(e) => handleChange(e, props)}
            >
            </input>
        )
    }
}

export { CalendarComponent };