import React, { useContext } from "react";
import { searchEngineConstant } from "../../../../../constants/searchEngine.constants";
import { messages } from "../../../../../static/messages/messages";
import { searchEngineContext } from "../../../../../stores/searchEngineStore";
import { TranslateContext } from "../../../../../stores/TranslateContext";

const { ABILITIES, ABILITIES_SENIOR_SUBTITLE } = searchEngineConstant;
let tags = messages['fr'].searchEngine.tags;

const _sortTags = () => {
    let arrayFormatted = [];
    let tagsObj = {};
    for (let key in tags) {
        arrayFormatted.push([key, tags[key]]);
    }
    arrayFormatted.sort((value1, value2) => {
        if (value1[1] < value2[1])
            return -1;
        if (value1[1] > value2[1])
            return 1;
        return 0;
    });
    arrayFormatted.forEach(value => {
        tagsObj[value[0]] = value[1];
    });
    return tagsObj;
};

const TagsField = () => {
    const { dispatch, state } = useContext(searchEngineContext);
    const field = [];
    const skills = state.skills;
    const sortedTags = _sortTags();
    for (let tag in sortedTags) {
        let isIn = false;
        for (let i = 0; i < skills.length; i++) { if (skills[i] && skills[i].tag === tag) isIn = true; }
        if (!isIn) {
            field.push(
                <div onClick={() => {
                    skills.push({ tag: tag, is_senior: false });
                    dispatch({ type: "setSearchInput", target: { skills: skills } })
                }} key={tag} className="btn btn-civipol-white btn-tag btn-add d-inline-flex my-2 mx-2">
                    <span>{sortedTags[tag]}</span>
                </div>
            )
        }
    }
    return field;
}

const filter = (array) => {
    let filtered = array.filter((el) => {
        return (el !== null)
    })
    return filtered;
}

const SelectedTagField = () => {

    const { state, dispatch } = useContext(searchEngineContext);
    const translateStore = useContext(TranslateContext);
    const { translateMessage } = translateStore.translateContext;
    let { skills } = state;

    let field = [];
    for (let tag in skills) {
        field.push(
            <li key={tag} className="list-group-item bg-grey px-0">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="btn btn-blue btn-tag btn-check d-inline-flex" style={{ textOverflow: "ellipsis" }}>
                        <span>{translateMessage("searchEngine.tags." + skills[tag].tag)}</span>
                    </div>
                    <div className="d-flex">
                        <div className="custom-control custom-checkbox custom-checkbox-fat">
                            <input onClick={() => {
                                for (let i = 0; i < skills.length; i++) {
                                    if (skills[i].tag === skills[tag].tag) {
                                        skills[i].is_senior = !skills[i].is_senior;
                                    }
                                }
                                dispatch({ type: "setSearchInput", target: { skills: skills } })
                            }} type="checkbox" className="custom-control-input" id={tag} />
                            <label className="custom-control-label" htmlFor={tag}>Senior</label>
                        </div>
                        <button type="button" onClick={() => {
                            delete skills[tag];
                            skills = filter(skills);
                            dispatch({ type: "setSearchInput", target: { skills: skills } })
                        }} className="btn btn-remove d-inline-flex"></button>
                    </div>
                </div>
            </li>
        )
    }
    return field;
}

const AbilitiesSection = () => {

    return (
        <div>
            <div className="d-flex mb-2">
                <h3 className="search-title mb-0 mr-2">{ABILITIES}</h3>
                <p className="text-muted mb-0">
                    {ABILITIES_SENIOR_SUBTITLE}
                </p>
            </div>
            <div className="row mb-4">
                <div className="col">
                    <ul className="list-group list-group-flush">
                        <SelectedTagField />
                    </ul>
                </div>
                <div className="col">
                    <div className="tags-cloud mb-4">
                        <TagsField />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AbilitiesSection;