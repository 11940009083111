import React from 'react';
import ReactDOM from 'react-dom';
import client from "./ApolloSetup";
import { ApolloProvider } from 'react-apollo';
import { addLocaleData, IntlProvider } from 'react-intl';
import "./static/css/style.scss";

import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import { flattenMessages, messages } from '../src/static/messages/messages';
import './static/css/style.scss';
import 'bootstrap/dist/css/bootstrap.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

require('dotenv').config('../dist/preprod.env');

addLocaleData([...en, ...fr]);

let locale = localStorage.getItem('lang');
if (!locale) {
    locale = 'fr';
    localStorage.setItem('lang', locale);
}


ReactDOM.render(

    <ApolloProvider client={client}>
        <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
            <App />
        </IntlProvider>
    </ApolloProvider>

    , document.getElementById('root'));

serviceWorker.unregister();
