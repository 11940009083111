import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import client from "../../../../ApolloSetup";
import { GET_OFFERS_DISPONIBLES } from "../../../../queries/getOffers";
import {SUGGEST_OFFER_TO_EXPERT} from "../../../../mutations/offersMutations";
import {withRouter} from "react-router-dom";
import { notificationContext } from "../../../../stores/notificationsStore";

const customStyles = {
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px",
    }),
    menu: (provided, state) => ({
        ...provided,
        position: "absolute",
        zIndex: "1030",
        height: "300px",

    }),
    menuList: (provided, state) => ({
        ...provided,
        height: "300px",
        zIndex: "1030",
    })
}

const getOffersAsync = async (setOffers) => {
    client.query({
        query: GET_OFFERS_DISPONIBLES
    })
        .then((values) => {
            let { getAllOffersDisponibles } = values.data;
            setOffers({allOffers: getAllOffersDisponibles});
        })
        .catch(err => console.log(err));
}

const suggestAsync = async (id_offer, id_experts, notification) => {
    client.mutate({
        mutation: SUGGEST_OFFER_TO_EXPERT,
        variables: {id_offer, id_experts}
    })
    .then(values => {
        notification.dispatch({ type: "setToast", target: { element: "successSuggestion"} });
    })
    .catch(err => console.log(err));
}

const Suggestions = (props) => {

    const [offers, setOffers] = useState({allOffers: [], offerSelected: null});
    const {id_experts} = props;

    const notifiactionStore = useContext(notificationContext);
    
    useEffect(() => {
        getOffersAsync(setOffers);
    }, []);


    if (offers.allOffers.length > 0) {
        let offersToSelect = [];

        offersToSelect = offers?.allOffers?.map((values) => { return { value: values.id_drupal, label: values.title.fr } });
        offersToSelect.unshift({ value: "cancel", label: "Annuler la sélection" })
        
        return (
            <div className='row w-100 d-flex justify-content-between align-items-center'>
                <div className="col-8">
                    <Select
                        styles={customStyles}
                        options={offersToSelect}
                        placeholder="Rechercher une offre"
                        onChange={(selected) => setOffers({...offers, offerSelected: selected.value})}
                    />
                </div>
                <div className="col d-flex justify-content-end">
                    <button className="btn btn-civipol-white" disabled={(offers.offerSelected && offers.offerSelected !== offersToSelect[0].value) ? false : true} onClick={() => suggestAsync(offers.offerSelected, id_experts, notifiactionStore)}>
                        SUGGÉRER CETTE OFFRE
                </button>
                </div>
            </div>
        );
    } else return null;
}

export default withRouter(Suggestions);