import gql from 'graphql-tag'

const UPSERT_IDENTITY = gql`
    mutation  upSertIdentity($input: IdentityInput!, $expertID: ID){
        upSertIdentity(input: $input, expertID: $expertID){
            id
            profile_pic
            status
            validationIdentity
            firstName
            lastName
            nationality_1
            nationality_2
            birthday
            address_1_number_street
            address_1_postal_code
            address_1_city
            address_1_country
            address_2_number_street
            address_2_postal_code
            address_2_city
            address_2_country
            tel_mobile
            tel_fix
            email_1
            email_2
            ICE{first_name last_name address_number_street address_postal_code address_city address_country telephone email}
            mission_duration
            criminal_conviction
            notificationActus
            notificationOffers
            infosExacts
        }
    }
`;

const UPSERT_MISSION_SEARCHED = gql`
    mutation  upSertMissionSearched($mission_duration: String!, $expertID: ID){
        upSertMissionSearched(mission_duration: $mission_duration, expertID: $expertID){
            id
            status
            validationMissionSearched
        }
    }
`;

const UPSERT_ABILITIES = gql`
    mutation upSertExpertAbilities($input: AbilitiesInput!, $expertID: ID){
        upSertExpertAbilities(input: $input, expertID: $expertID){
            id
            status
            validationExpertAbilities
        }
    }
`;
const UPSERT_CV = gql`
    mutation upsertCV($input: UpsertCVInputs!, $expertID: ID, $id_employee: ID){
        upsertCV(input: $input, expertID: $expertID, id_employee: $id_employee){
            id
            status
            supporting_documents{type number link path name}
            validationCV
        }
    }
`;

const UPSERT_SUPP_DOC = gql`
    mutation upsertSupportingDocuments($input: SupportingDocumentInputs!, $expertID: ID, $id_employee: ID){
        upsertSupportingDocuments(input: $input, expertID: $expertID, id_employee: $id_employee){
            id
            status
            supporting_documents{type number link path name}
            validationSupportingDocuments
        }
    }
`;

const UPSERT_CONFIRMATION = gql`
    mutation   upsertConfirmation($input: ConfirmationInput!, $expertID: ID){
        upsertConfirmation(input: $input, expertID: $expertID){
            id
            status
            validationConfirmation
        }
    }
`;

const DELETE_EXPERT = gql`

    mutation deleteExpert($expertID: ID!) {
        deleteExpert(expertID: $expertID){
            id
        }
    }

`;

const UNLOCK_EXPERT = gql`
    mutation unlockExperts($expertsIDs: [ID]) {
        unlockExperts(expertsIDs: $expertsIDs) {
            id
        }
    }
`;

export {
    UPSERT_IDENTITY,
    UPSERT_MISSION_SEARCHED,
    UPSERT_ABILITIES,
    UPSERT_CV,
    UPSERT_SUPP_DOC,
    UPSERT_CONFIRMATION,
    DELETE_EXPERT,
    UNLOCK_EXPERT
};