import { translate } from "./translation.constants";

export const identityConstants = {
    ABOUT_EXPERT: translate("expertForm.identity.aboutExpert"),
    BIRTHDAY: translate("expertForm.identity.birthday"),
    PROFILE_PICTURE: translate("expertForm.identity.idPic"),
    BUTTON_PROFILE_PIC: translate("expertForm.identity.buttonProfilePic"),
    EXPERT_INFORMATIONS_COORDINATES: translate("expertForm.identity.expertCoordinates"),
    EXPERT_INFORMATIONS_CONTACT: translate("expertForm.identity.contact"),
    PRINCIPAL_ADDRESS: translate("expertForm.identity.address_1"),
    ICE_PERSON_TO_CONTACT: translate("expertForm.ICE.iceTitle"),
}

export const missionSearchedConstants = {
    TYPE_OF_MISSION: translate("expertForm.missionSearched.typeOfMission"),
    MISSION_DURATION: translate("expertForm.missionSearched.missionDuration"),
}


export const abilitiesConstants = {
    LANGUAGES_TITLE: translate("expertForm.abilities.languagesTitle"),
    LANGUAGES_SUBTITLE: translate("expertForm.abilities.languageHowToKnow"),
    LANGUAGES_SUBTITLE_LINK: translate("expertForm.abilities.languageSeeNorms"),
    LANGUAGE_LANGUE: translate("expertForm.abilities.langue"),
    LANGUAGE_LANGUE_LABEL: translate("expertForm.abilities.langueLabel"),
    LANGUAGE_LEVEL_LABEL: translate("expertForm.abilities.levelLabel"),
    SERVICES_DETAILS_TITLE: translate("expertForm.abilities.professionnalSituation"),
    SERVICES_DETAILS_PUBLIC_AGENT: translate("expertForm.abilities.publicAgent"),
    SERVICES_DETAILS_FONTIONNARY: translate("expertForm.abilities.fonctionnary"),
    RETIRED_FONCTIONNARY: translate("expertForm.abilities.fonctionnary_retired"),
    RETIRED_SINCE: translate("expertForm.abilities.retired_since"),
    TYPE_OF_CONTRACT: translate("expertForm.abilities.typeOfContract"),
    SALARIAL_CONTRACT: translate("expertForm.abilities.salarialContract"),
    PRESTATION_CONTRACT: translate("expertForm.abilities.prestationContract"),
    ENTITY: translate("expertForm.abilities.entity"),
    GRADE: translate("expertForm.abilities.grade"),
    SERVICES_DETAILS_NOT_FONTIONNARY: translate("expertForm.abilities.notFonctionnary"),
    SERVICES_DETAILS_NOT_FONTIONNARY_SALARIAL: translate("expertForm.abilities.salarialContract"),
    SERVICES_DETAILS_NOT_FONTIONNARY_SERVICE: translate("expertForm.abilities.serviceContract"),
    EXPERTISE_DOMAIN_TITLE: translate("expertForm.abilities.expertiseDomainTitle"),
    EXPERTISE_DOMAIN_YEARS: translate("expertForm.abilities.yearOfExperience"),
    EXPERTISE_DOMAIN_DIPLOMA: translate("expertForm.abilities.diplomaForExperience"),
    SIGNIFICANTS_COUNTRIES: translate("expertForm.abilities.significantsCountries"),
    SINCE: translate("expertForm.abilities.since"),
    SELECT_PLACEHOLDER: translate("expertForm.abilities.placeholderSelector"),


}

export const CVConstants = {
    CV_TITLE: translate("expertForm.CV.title"),
    CV_CHOSE_FILE: translate("expertForm.CV.choseFile"),
    CV_FORMAT: translate("expertForm.CV.fileFormat"),
}

export const confirmationConstants = {
    NOTIFICATION_TITLE: translate("expertForm.confirmation.notificationsTitle"),
    NOTIFICATION_OFFERS_BY_MAIL: translate("expertForm.confirmation.getOffersByMail"),
    NOTIFICATION_ACTUS_BY_MAIL: translate("expertForm.confirmation.getActusByMail"),
    HOW_DID_YOU_KNOW_TITLE: translate("expertForm.confirmation.howDidYouKnowTitle"),
    CONDAMNATION_TITLE: translate("expertForm.confirmation.condamnationTitle"),
    CONDAMNATION_TEXT: translate("expertForm.confirmation.condmanationText"),
    EXACTITUDE_TITLE: translate("expertForm.confirmation.exactitudeTitle"),
    EXACTITUDE_TEXT: translate("expertForm.confirmation.exactitudeText"),
}