import React from "react";
import { Link } from 'react-scroll';
import { candidaturesConstants, missionsConstants } from "../../constants/missions.constants";

const MenuMissions = () => {

    return (
        <div className="col-12 col-lg-4 mb-4 mb-lg-0 ">
            <div className="sticky-top sticky-top-menu">
                <div id="menu-mission" >
                    <div className="d-flex align-items-center mb-2">
                        <Link
                            to="1"
                            id="menu-1"
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-100}
                            activeClass="card-active"
                            className="card w-100"
                        >
                            <div className="ml-5 card-body px-2 py-4">
                                {candidaturesConstants.TITLE}
                            </div>
                        </Link>
                    </div>
                    <div className="d-flex align-items-center">
                        <Link
                            to="2"
                            id="menu-2"
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-100}
                            activeClass="card-active"
                            className="card w-100"
                        >
                            <div className="ml-5 card-body px-2 py-4">
                                {missionsConstants.TITLE}
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MenuMissions;