import React from 'react';

export const Loader = (props) => {
    return (
        <div id="content" className="py-5 min-heigth-50">
            <div className="container py-5">
                <div className={"ajax-progress " + props.class}>
                </div>
            </div>
        </div>
    )
}