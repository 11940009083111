import React, { createContext, useReducer } from "react";
import { getSessionItemByKey } from "../components/utils/sessionStorage";

let searchStorage = getSessionItemByKey("searchExperts");
let emptyObj = {
    inputSearch: "",
    mission_duration: "",
    nationality: "",
    countries: "",
    language: {
        langue: "",
        langue_level: "",
    },
    skills: [],
    servicesDetails: {},
    disponibility: "",
    display_disponible: false,
};

let initialState = (searchStorage && searchStorage.search) || emptyObj;

const searchEngineContext = createContext(initialState);

const reducer = (state, action) => {
    let target = action.target;
    switch (action.type) {
        case "setSearchInput": {
            return {
                ...state,
                ...target,
            }
        }
        default: return state
    }
}

const SearchEngineProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <searchEngineContext.Provider value={{ state, dispatch }} >
            {children}
        </searchEngineContext.Provider>
    );
}

export { searchEngineContext, SearchEngineProvider, initialState, emptyObj };