import gql from "graphql-tag";

const LOG_CONSULTATION = gql`
    mutation logConsultation($id_expert: ID){
        logConsultation(id_expert: $id_expert){
            id
        }   
    }

`;

export default LOG_CONSULTATION;