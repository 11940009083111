import gql from "graphql-tag";

const GET_OFFERS = gql`    
    query {
        getAllOffers {
            id
            id_drupal
            title { fr en }
            description_text {fr en}
            mission_length {fr en parent}
            geographical_zone {fr en parent}
            application_expiration
            introduction_text  {fr en}
            profile_text {fr en}
            job_expertise {fr en parent id_taxo}
        }
    }
`;

const GET_OFFERS_DISPONIBLES = gql`
    query {
        getAllOffersDisponibles {
        id
        soft_delete
        id_drupal
        title { fr en }
        description_text {fr en}
        mission_length {fr en parent}
        geographical_zone {fr en parent}
        application_expiration
        introduction_text  {fr en}
        profile_text {fr en}
        job_expertise {fr en parent id_taxo}
    }
    }
`;

const GET_OFFER_BY_ID = gql`
    query getOffer($id: ID!) {
        getOffer(id: $id) {
            id_drupal
            title { fr en }
            description_text {fr en}
            mission_length {fr en parent}
            geographical_zone {fr en parent}
            application_expiration
            introduction_text  {fr en}
            profile_text {fr en}
            job_expertise {fr en parent id_taxo}
            missions {
                id
                staffed
                key_expert
                date_accepted
                date_postulat
                date_rejected
                expert {
                    id
                    firstName
                    lastName
                    expert_ability {
                        fonctionnaire
                        DG
                    }
                    profile_pic
                }
            }
            project {
                contract_type{fr en}
            }
        }
    }
`;

export { GET_OFFERS, GET_OFFER_BY_ID, GET_OFFERS_DISPONIBLES }