import * as Scroll from "react-scroll";

export const is_empty_obj = (obj) => {
    return (Object.entries(obj).length === 0 && obj.constructor === Object)
}

export const deleteTypeName = (expertInfos) => {
    let tmp = expertInfos;
    for (let value in tmp) {
        if (value === "__typename") {
            delete tmp[value];
        }
        if (typeof tmp[value] === "object") {
            if (tmp[value] && tmp[value]['__typename']) {
                delete tmp[value]['__typename']
            }
        }
        if (Array.isArray(tmp[value])) {
            for (let i = 0, count = tmp[value].length; i < count; i++) {
                if (tmp[value][i] && tmp[value][i]['__typename']) {
                    delete tmp[value][i]['__typename']
                }
            }
        }
    }
    return tmp;
}

export const nullToString = (expertInfos) => {
    let tmp = expertInfos;
    for (let value in tmp) {
        if (tmp[value] === null) {
            tmp[value] = "";
        }
        if (Array.isArray(tmp[value])) {
            for (let i = 0, count = tmp[value].length; i < count; i++) {
                if (tmp[value][i] && tmp[value][i] === null) {
                    tmp[value][i] = "";
                }
            }
        } else if (typeof tmp[value] === "object") {
            let tab = Object.keys(tmp[value]);
            for (let i = 0, count = tab.length; i < count; i++) {
                if (tmp[value][tab[i]] === null) {
                    tmp[value][tab[i]] = "";
                }
            }
        }
    }
    return tmp;
}

export const scrollTo = (id) => {
    let scroller = Scroll.scroller;
    scroller.scrollTo(id, {
        duration: 200,
        delay: 200,
        smooth: true,
        offset: -180
    })
}