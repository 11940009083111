import React, { createContext, useReducer } from "react";

const initialState = {
    historic: [],
    historicsArray: null,
    update: false,
}

const historicSearchContext = createContext(initialState);

const reducer = (state, action) => {
    let { target } = action;

    switch (action.type) {
        case "setHistoric": {
            return {
                ...state,
                historic: target,
                // update: !state.update,
            }
        }
        case "setHistoricArray": {
            return {
                ...state,
                historicsArray: target,
                // update: !state.update,
            }
        }
        case "updateHistoric": {
            return {
                ...state,
                update: !state.update,
            }
        }
        default: return state
    }
}

const HistoricSearchProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <historicSearchContext.Provider value={{ state, dispatch }} >
            {children}
        </historicSearchContext.Provider>
    );
}

export { historicSearchContext, HistoricSearchProvider };