import React, { createContext, useReducer } from "react";

const initialState = {
    missions: {
        agreed: [],
        inMission: [],
        terminated: [],
    },
    candidatures: {
        rejected: [],
        postule: [],
    },
    evaluation: {
        is_complete: null,
    },
    update: false,
}

const missionsContext = createContext(initialState);

const reducer = (state, action) => {
    const {target} = action;

    switch(action.type) {
        case "setMissions": {
            return {
                ...state,
                missions: target
            }
        }
        case "setCandidatures": {
            return {
                ...state,
                candidatures: target
            }
        }
        case "updateMissions": {
            return {
                update: !state.update
            }
        }
        default: return state
    }
}

const MissionsProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, missionsContext);
    
    return (
        <missionsContext.Provider value={{state, dispatch}}>
            {children}
        </missionsContext.Provider>
    )
}

export {missionsContext, MissionsProvider};