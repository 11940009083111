import React, { useState, Fragment, useEffect } from "react";
import { Form } from 'react-bootstrap';
import { CVConstants } from "../../../../constants/profile-expert.constants";

const {
    CV_TITLE,
    CV_FORMAT,
} = CVConstants;

const OpenCV = ({ cvProps }) => {

    if (cvProps.name) {
        return <a target='_blanck' className="btn btn-export" href={`${process.env.REACT_APP_API_URL}${cvProps.path}/${cvProps.name}.${cvProps.extension}`}></a>
    } else { return null; }
}

const CVComponent = (props) => {

    const { values } = props;

    const cvInfos = values?.supporting_documents?.find(documents => documents.type === "CV");

    // retourn le composant de CV
    return (
        <Fragment>
            <Form.Label
                className={
                    //si erreur on surligne en rouge
                    (props.errors.supporting_documents && props.errors.supporting_documents.CV
                    ) ? "is-invalid-label"
                        : null
                }
            >
                <label>{CV_TITLE}</label>
            </Form.Label>
            {cvInfos?.name &&
                <div className='d-flex justify-content-between'>
                    <p className="mb-0 ml-4">{cvInfos?.name}</p>
                    <OpenCV cvProps={cvInfos} />
                </div>
            }
            <div className="mb-0">
                <p>{CV_FORMAT}</p>
            </div>
        </Fragment>

    )
}

export default CVComponent;