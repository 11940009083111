import React from "react";
import * as countries from "i18n-iso-countries";
import { Form } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import Input from "./Input";
import { CalendarComponent } from "../../../calendar/CalendarComponent";
import Selector from "../../../selector/Selector";
import { identityConstants } from "../../../../constants/profile-expert.constants";

const sectionMessageName = "expertForm.identity";

const civilityArray = [
    { label: <FormattedMessage id={`${sectionMessageName}.female`} defaultMessage="Not translated" />, value: "FEMALE" },
    { label: <FormattedMessage id={`${sectionMessageName}.male`} defaultMessage="Not translated" />, value: "MALE" }
]

const AboutYou = (props) => {
    //chemin pour la traduction
    let array = Object.values(countries.getNames("fr"));
    const { ABOUT_EXPERT, BIRTHDAY, PROFILE_PICTURE, BUTTON_PROFILE_PIC } = identityConstants;

    let divStyle = "";
    if (props.values.identity.profile_pic) {
        divStyle = { backgroundImage: 'url(' + process.env.REACT_APP_API_URL + props.values.identity.profile_pic.substring(1, props.values.identity.profile_pic.length) + ')' };
    }

    //retourne le block concernant les information d'identité de l'expert
    return (
        <div className="mb-4">
            <h2 className="title-section-blue text-uppercase mb-4">{ABOUT_EXPERT}</h2>
            <div className="row mb-4">
                <div className="col-8 col-md-6">
                    <Selector array={civilityArray} section="identity" name="civility" path={sectionMessageName} {...props} />
                </div>
            </div>
            <div className="row flex-row justify-content-between">
                <Input name="identity.firstName" label={sectionMessageName + ".firstName"} formik={props} maxlength="50" />
                <Input name="identity.lastName" label={sectionMessageName + ".lastName"} formik={props} maxlength="50" />
            </div>
            <div className="row mb-4">
                <div className="col-8 col-md-6">
                    <Form.Label
                        className={props.errors.identity && props.errors.identity.birthday ? "is-invalid-label" : null}
                    >{BIRTHDAY}</Form.Label>
                    <CalendarComponent {...props} section='identity' name="birthday" />
                    <Form.Control.Feedback type="invalid" className="invalid-feedback">{props.errors.identity && props.errors.identity.birthday && <p>{props.errors.identity.birthday}</p>}</Form.Control.Feedback>
                </div>
            </div>

            <div className="row flex-row justify-content-between">
                <div className="col-12 col-md-6 mb-4">
                    <Selector array={array} section="identity" name="nationality_1" path={sectionMessageName} {...props} />
                </div>
                <div className="col-12 col-md-6">
                    <Selector array={array} section="identity" name="nationality_2" path={sectionMessageName} {...props} />
                </div>
            </div>
            <div className="d-flex identity">
                <Form.Group>
                    <div className="d-flex flex-column">
                        <Form.Label
                            className={props.errors && props.errors.identity && props.errors.identity.profile_pic ? "is-invalid-label" : null}
                        >
                            {PROFILE_PICTURE}
                        </Form.Label>
                        <div className="btn btn-civipol-white disabled" disabled>{BUTTON_PROFILE_PIC}</div>
                    </div>
                    <Form.Control
                        id="profile_image"
                        className="form-control d-none"
                        isInvalid={props.errors && props.errors.identity && props.errors.identity.profile_pic}
                    />
                    <Form.Control.Feedback className="invalid-feedback"> {props.errors && props.errors.identity && props.errors.identity.profile_pic ? <p><FormattedMessage id={"validation.badFormatRequired"} defaultMessage="Not translated" /></p> : null} </Form.Control.Feedback>
                </Form.Group>
            </div>
            {(props.values.identity.profile_pic && !props.values.identity.profile_pic.name) ?
                <div className="rounded-circle profile-picture profile-picture-large" style={divStyle || null}>
                </div>
                : null}
            <canvas id="canvas" height="0" width="0" className={(props.values.identity.profile_pic) ? null : "rounded-circle profile-picture canvas profile-picture-large"} />
        </div>
    )
}

export default AboutYou;
