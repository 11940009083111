import React, { createContext, useReducer } from "react";

const initialState = {
    firstName: null,
    lastName: null,
    id_expert: null,
    projectName: null,
    offerName: null,
    projectResponsable: null,
    linkToOffer: null,
    countryIntervention: null,
    codeProject: null,
    evaluationDate: null,
    startDate: null,
    endTryPeriod: null,
    endTryReniew: null,
    endDate: null,
    chiefPole: null,
    yearEvaluation: null,
    general: "-1",
    competencesCriterias: {
        technicalAbilities: "-1",
        administrativGestion: "-1",
        financialGestion: "-1",
        deliverable: "-1",
        pedagogy: "-1",
    },
    aptitudesCriterias: {
        communication: "-1",
        organisation: "-1",
        teamSpirit: "-1",
        senseOfHearing: "-1",
        exemplarity: "-1",
    },
    reniew_trial_period: false,
    isLoading: true,
    isError: false,
    error: null,
}

const evaluationContext = createContext(initialState);

const reducer = (state, action) => {
    const { type } = action;

    switch (type) {
        case "setCriteria": {
            if (action.section) {
                return {
                    ...state,
                    [action.section]: {
                        ...state[action.section],
                        [action.target.name]: action.target.value
                    }
                }
            } else {
                return {
                    ...state,
                    [action.target.name]: action.target.value
                }
            }
        }
        case "setInput": {
            return {
                ...state,
                [action.target.name]: action.target.value
            }
        }
        case "setError": {

            return {
                ...state,
                isError: true,
                error: action.target
            }
        }
        case "setInfosEvaluation": {
            return {
                ...state,
                isLoading: false,
                ...action.target
            }
        }
        case "setTrialPeriod": {
            return {
                ...state,
                reniew_trial_period: action.target.checked
            }
        }
        default: return state;
    }
}

const EvaluationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <evaluationContext.Provider value={{ state, dispatch }}>
            {children}
        </evaluationContext.Provider>
    )
}

export { evaluationContext, EvaluationProvider };