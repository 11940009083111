import React, { createContext, useReducer } from "react";

const initialState = {
    project: null,
    offersFromProject: null,
    isLoading: true,
    error: false,
}

const projectDetailsContext = createContext(initialState);

const reducer = (state, action) => {
    const { target } = action;

    switch (action.type) {
        case "setProject": {
            const offers = {...target.offers};
            delete target.offers;

            return {
                project: target,
                offersFromProject: offers,
                isLoading: false,
            }
        }
        default: return state
    }
}

const ProjectDetailsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    
    return (
        <projectDetailsContext.Provider value={{ state, dispatch }} >
            {children}
        </projectDetailsContext.Provider>
    );
}

export {ProjectDetailsProvider, projectDetailsContext};