import React, { useContext, useState } from "react";
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../../stores/collapseStore";
import { Mutation } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { generalConstants } from "../../../constants/general.constants";
import * as utils from "../../../utils/utils";
import { UPSERT_IDENTITY } from "../../../mutations/upsertExpert";
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useRouteMatch } from "react-router-dom";
import { ExpertDetailsContext } from "../../../stores/expertsDetailsStore";
import handleSubmit from "./submit";
import AboutYou from "./sections/AboutYou";
import ContactInformations from "./sections/ContactInformations";
import { notificationContext } from "../../../stores/notificationsStore";
import { TranslateContextWrapper } from '../../../stores/TranslateContext';
import { errorsGesture } from '../../utils/errors';

const Identity = (props) => {

    const notification = useContext(notificationContext);
    const { state } = useContext(ExpertDetailsContext)
    const collapseContext = useContext(CollapseContext);
    const collapseProps = useCollapse({ isOpen: collapseContext.state.identity });
    const { getToggleProps, getCollapseProps } = collapseProps;
    const { MODIFY, REGISTER } = generalConstants;
    const match = useRouteMatch();
    const [clicked, setClick] = useState(false);

    return (
        <Mutation
            mutation={UPSERT_IDENTITY}
            onCompleted={(datas) => {
                notification.dispatch({ type: "setToast", target: { element: "successModification" } });
            }}
            onError={(errors) => errorsGesture(errors, () => notification.dispatch({ type: "setAlert", target: { element: "errorAuthorizationAction" } }))}
        >
            {(identityCallBack, { loading, errors }) => {
                return (
                    <Formik initialValues={state.expert}
                        enableReinitialize
                        onSubmit={(values) => {
                            notification.dispatch({ type: "setAlert", target: { element: "modification", callback: () => handleSubmit(values, identityCallBack, match.params.id) } });
                        }}
                        onError={(err) => {
                            console.log(err);
                        }}

                        render={formikBag => {
                            return (
                                <section className="section-form">
                                    <div className="d-flex justify-content-between align-items-center mb-4 ">
                                        <div className="d-flex align-items-center">
                                            <span className="icon-number"><b>1</b></span>
                                            <h2 className="mb-0"><FormattedMessage id='menu.identity' defaultMessage="Not translated" /></h2>
                                        </div>
                                        <button className="btn btn-civipol-white" {...getToggleProps({ onClick: async (e) => { setClick(!clicked); await collapseContext.methods.setCollapse("identity", !collapseContext.state.identity); } })}>{MODIFY} </button>
                                    </div>
                                    <section name="identity" {...getCollapseProps()} onTransitionEnd={(e) => {
                                        for (let values in collapseContext.state) {
                                            if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                e.target.style.height = "";
                                                utils.scrollTo(values);
                                            }
                                        }
                                    }}>
                                        <div className="card">
                                            <div className="card-body">
                                                <Form onSubmit={formikBag.handleSubmit}>
                                                    <AboutYou {...formikBag} />
                                                    <ContactInformations {...formikBag} />
                                                    <div className="card-footer border-top-0 py-4">
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <button className="btn btn-civipol mx-2" type="submit">{REGISTER}</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </section>
                                </section>

                            )
                        }}
                    />
                )
            }}
        </Mutation>
    )
}

export default TranslateContextWrapper(Identity);