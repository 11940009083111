export const handleSubmit = (values, abilitiesCallback, id) => {
    let obj = {
        languages: values.languages,
        expert_ability: values.expert_ability,
        skills: values.skills,
        countries: values.identity.countries ? JSON.stringify(values.identity.countries) : null,
    }
    if (obj.expert_ability.fonctionnaire === true) {
        obj.expert_ability.accept_salarial_contract = null;
        obj.expert_ability.last_remuneration = null;
        obj.expert_ability.accept_service_contract = null;
    }
    for (let value in obj.expert_ability) {
        if (obj.expert_ability[value] === "true" || obj.expert_ability[value] === "false") {
            obj.expert_ability[value] = JSON.parse(obj.expert_ability[value])
        }
    }
    for (let value in obj.expert_ability) {
        if (value !== "last_remuneration" && obj.expert_ability[value] === "") {
            delete obj.expert_ability[value]
        }
    }
    if (obj.expert_ability === "") {
        obj.expert_ability = null;
    }
    abilitiesCallback({ variables: { input: obj, expertID: id} })
}