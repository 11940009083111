export const getStorageUser = () => {
    try {
        return JSON.parse(localStorage.getItem('currentEmployee'));
    } catch {
        return null;
    }
}

export const setStorageUser = (employe) => {
    try {
        localStorage.setItem('currentEmployee', JSON.stringify(employe));
    } catch {
        return null;
    }
}