import gql from "graphql-tag";

const GET_STATISTICS = gql`
    query getStatistics {
        getStatistics {
            nb_registered_on_week
            nb_total_expert
            offers_not_provided
            experts_to_evaluate
        }
    }
`;
export { GET_STATISTICS };