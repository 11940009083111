import React, { useState, useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import ModalListExpertsCandats from "./ModalListExpertsCandidats";
import { withCookies } from "react-cookie";
import { notificationContext } from "../../../../stores/notificationsStore";
import { SET_KEY_EXPERT } from "../../../../mutations/upsertMissions";
import client from "../../../../ApolloSetup";
import { projectDetailsContext } from "../../../../stores/projectDetailsStore";
import { candidaturesProjectsDetailS } from "../../../../constants/candidatures-projects-details.constants";
import { getStorageUser } from "../../../utils/localStorage";

const {
    SEE_PROFILE,
    ACCOUNT_DELETED,
    SEARCH_EXPERT,
    KEY_EXPERT,
    NO_CANDIDATURE,
    KEY_EXPERT_IN_MISSION,
    EXPERT_IN_MISSION,
    CANDIDATURE_LIMIT,
    MISSION_DURATION,
    GEO_ZONE,
    SEE_OFFER,
} = candidaturesProjectsDetailS;

const handleExpertRecommandation = (id_mission, { dispatch }, value) => {
    client.mutate({
        mutation: SET_KEY_EXPERT,
        variables: {
            id_mission: id_mission,
            value: !value
        }
    }).then(values => {
        dispatch({ type: "setProject", target: values.data.setExpertKey })
    }).catch(err => console.error(err))
}

const ExpertKeyRecommandation = (props) => {
    const { rights, missions, id } = props;
    const { dispatch } = useContext(notificationContext);
    const projectStore = useContext(projectDetailsContext);

    if (rights.can_recomand_key_expert) {
        return (
            <div className="form-row mr-3">
                <div className="d-flex align-items-center">
                    <div className="custom-control custom-checkbox custom-checkbox-fat">
                        <input checked={
                            missions[0].key_expert
                        }
                            onChange={
                                () => dispatch({
                                    type: "setAlert",
                                    target: {
                                        element: "keyExpert",
                                        callback: () => handleExpertRecommandation(missions[0].id, projectStore, missions[0].key_expert)
                                    }
                                })
                            }
                            type="checkbox"
                            className="custom-control-input"
                            id={`choice1-checked-${id}`} />
                        <label className="custom-control-label"
                            htmlFor={`choice1-checked-${id}`}>
                            {KEY_EXPERT}</label>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <span className="profile-status d-flex status mr-5" data-color="#da413f">
                    <span className="dot rounded-circle mr-2"></span>
                    <span className="text-red">
                        {
                            missions[0].key_expert
                                ? KEY_EXPERT_IN_MISSION
                                : EXPERT_IN_MISSION
                        }
                    </span>
                </span>
            </div>
        )
    }
}

const getImage = (theme) => {
    if (theme === "50") {
        return require("../../../../static/images/projects-card/theme-72.jpg")
    }
    if (theme === "51") {
        return require("../../../../static/images/projects-card/theme-73.jpg")
    }
    if (theme === "106") {
        return require("../../../../static/images/projects-card/theme-75.jpg")
    }
}

const MissionsComponent = (props) => {
    let count = 0;
    const { missions } = props;
    const { rights } = getStorageUser();
    const [show, setShow] = useState(false);

    for (let i = 0; i < missions.length; i++) {
        if (missions[i].staffed === "Terminated" || missions[i].staffed === "InMission" || missions[i].staffed === "Agreed") {
            const { date_accepted } = missions[i];
            const { expert } = missions[i];
            if (expert) {
                const {
                    profile_pic,
                    firstName,
                    lastName,
                    id,
                } = expert;
                let divStyle = "";
                if (profile_pic) {
                    divStyle = { backgroundImage: 'url(' + process.env.REACT_APP_API_URL + profile_pic.substring(1, profile_pic.length) + ')' };
                }
                return (
                    <div className="card">
                        <div className="card-body">
                            <div className="row flex-row justify-content-between align-items-center">
                                <div className="col-12 col-lg-6 mb-2 mb-lg-0">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="rounded-circle profile-picture"
                                            style={divStyle || null}></div>
                                        <div className="d-flex flex-column ml-4 text-capitalize">
                                            <h4 className="profile-name mb-2">{`${firstName.toLowerCase()} ${lastName.toLowerCase()}`}</h4>
                                            <span className="d-flex profile-view text-uppercase mb-2">Candidature acceptée le {
                                                moment(date_accepted).format("DD/MM/YYYY")
                                            }</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <ExpertKeyRecommandation rights={rights}
                                            missions={missions}
                                            id={id} />
                                        <Link to={"/experts/expert/" + id} className="btn btn-civipol-white">{SEE_PROFILE}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (expert === null) {
                return (
                    <span className="mb-0 pb-0">
                        {ACCOUNT_DELETED}
                    </span>
                )
            }
        } else if (missions[i].staffed === "Postule" && missions[i].expert) {
            count++;
        }
    }
    if (count > 0) {
        const plural = (count > 1) ? "S" : "";
        return (
            <div>
                <div className="d-flex justify-content-between">
                    <button onClick={() => setShow(true)} className="btn btn-civipol-white">{count} CANDIDAT{plural}</button>
                    <button className="btn btn-civipol">
                        {SEARCH_EXPERT}</button>
                </div>
                <ModalListExpertsCandats show={show} onHide={() => setShow(false)} missions={missions} centered />
            </div>
        )
    } else {
        return (
            <div className="d-flex justify-content-between">
                <span className="mb-0 pb-0">
                    {NO_CANDIDATURE}
                </span>
                <button className="btn btn-civipol">
                    {SEARCH_EXPERT}
                </button>
            </div>
        )
    }
}

const OfferComponent = (props) => {
    const { value } = props;
    const {
        id,
        title,
        mission_length,
        geographical_zone,
        application_expiration,
        job_expertise,
        missions,
        soft_delete
    } = value;
    const image = getImage(job_expertise.id_taxo);
    const theme = job_expertise.fr || job_expertise.en;
    const titleOffer = title.fr || title.en;
    let missionDuration = application_expiration;
    let zone;

    if (geographical_zone && geographical_zone.fr) {
        zone = geographical_zone.fr;
    } else {
        zone = "";
    }
    if (soft_delete) {
        return null;
    }
    return (
        <div className="row mb-3 project-card">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <div className="row d-flex">
                            <div className="col-12 col-md-4 order-1 order-md-0">
                                <img className="img-fluid"
                                    src={image}
                                    alt="img-offers" />
                            </div>
                            <div className="col-12 col-md-8 order-0 order-md-1 mb-4 mb-md-0">
                                <div className="row flex-column">
                                    <div className="col">
                                        <span className='theme-container mb-2'>
                                            {theme} </span>
                                        <h3 className="text-uppercase" style={{ fontFamily: "DIN-LIGHT", color: "#0F4D95" }}>
                                            {titleOffer}
                                        </h3>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div className="information-expert mr-3 mr-lg-4">
                                                <label className="text-uppercase">{CANDIDATURE_LIMIT}</label>
                                                <h4 className="mb-0">{moment(missionDuration).format("DD/MM/YYYY")}</h4>
                                            </div>
                                            <div className="information-expert mr-3 mr-lg-4">
                                                <label className="text-uppercase">{MISSION_DURATION}</label>
                                                <h4 className="mb-0">{mission_length.fr}</h4>
                                            </div>
                                            <div className="information-expert">
                                                <label className="text-uppercase">{GEO_ZONE}</label>
                                                <h4 className="mb-0">
                                                    {zone}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col text-right">
                                        <Link to={"/details-offer/" + id} className="btn btn-civipol-white mb-2">{SEE_OFFER}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col">
                                <MissionsComponent missions={missions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withCookies(OfferComponent);
