import React from "react";
import Select from "react-select";
import { TranslateContextWrapper } from "../../../../stores/TranslateContext";

const customStyles = {
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px",
    }),
    menu: (provided, state) => ({
        ...provided,
        position: "absolute",
        zIndex: "1030",
        maxHeight: "300px",

    }),
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: "300px",
        zIndex: "1030",
    })
}

const isIndifferent = (array) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i].value === "") {
            return true;
        }
    } return false;
}

const onChange = (isMulti, dispatch, state, selected, e) => {
    const { name } = e;

    if (isMulti) {
        if (name === "mission_duration" || name === "countries") {
            dispatch({ type: "setSearchInput", target: { [name]: (selected && selected.length > 0 && !isIndifferent(selected)) ? JSON.stringify(selected) : "" } });
        }
    } else if (name === "date_retired") {
        dispatch({ type: "setSearchInput", target: { servicesDetails: { ...state.servicesDetails, date_retired: selected.value } } })
    } else if (name === "public_function_status") {
        dispatch({ type: "setSearchInput", target: { servicesDetails: { ...state.servicesDetails, publicFunction: selected.value } } })
    } else if (name === "langue") {
        dispatch({ type: "setSearchInput", target: { language: { ...state.language, langue: selected.value, langue_level: (selected.label !== 'Indifférent') ? state.language.langue_level : '' } } })
    } else if (name === "langue_level") {
        dispatch({ type: "setSearchInput", target: { language: { ...state.language, langue_level: selected.value } } })
    } else {
        dispatch({ type: "setSearchInput", target: { [name]: selected.value } });
    }
};

const getValue = (isMulti, dispatch, state, name, options) => {
    if (name === "public_function_status") {
        return options.find(option => option.value === state.servicesDetails.publicFunction) || null;
    }
    if (name === "date_retired") {
        return options.find(option => option.value === state.servicesDetails.date_retired) || null;
    }
    if (name === "langue") {
        return options.find(option => option.value === state.language.langue) || null;
    }
    if (name === "langue_level") {
        return options.find(option => option.value === state.language.langue_level) || "";
    }
    if (isMulti) {
        if (name === "mission_duration" || name === "countries") {
            return (state[name] !== "" && state[name] !== null) ? JSON.parse(state[name]) : "";
        }
    }
    if (name === "nationality") {
        return options.find(option => option.value === state.nationality) || null;
    }
};

const SelectComponent = ({ options, dispatch, isMulti, name, state, disabled }) => {
    return (
        <div>
            <Select
                isDisabled={disabled}
                name={name}
                id={name}
                isMulti={isMulti}
                styles={customStyles}
                options={options}
                placeholder="Sélectionner"
                onChange={(selected, e) => onChange(isMulti, dispatch, state, selected, e)}
                value={getValue(isMulti, dispatch, state, name, options)}
            />
        </div>
    )
}
export default TranslateContextWrapper(SelectComponent);