import gql from "graphql-tag";

const SUGGEST_OFFER_TO_EXPERT = gql `
    mutation suggestOfferToExpert($id_experts: [ID!], $id_offer: ID!) {
        suggestOfferToExpert(id_experts: $id_experts, id_offer: $id_offer) {
            id
        }
    }
`;

export {SUGGEST_OFFER_TO_EXPERT};

