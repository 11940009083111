import React, { useReducer } from "react";
import { withCookies } from "react-cookie";
import { withRouter, NavLink, Redirect } from "react-router-dom";
import { headerConstants } from "../../constants/header.constants";
import { translate } from "../../constants/translation.constants";
import client from "../../ApolloSetup";
import gql from "graphql-tag";
import { getStorageUser } from "../utils/localStorage";

const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    client.mutate({
        mutation: gql`mutation{
        logoutEmploye{
            id
        }
    }`})
}

const reducer = (state, action) => {
    let { target } = action;

    switch (action.type) {
        case "setActive": {
            return {
                ...state, active: target
            }
        }
        default: return { state }
    }
}

const setActive = (dispatch, target) => {
    dispatch({ type: "setActive", target: target })
}

const parseActive = (active) => {
    if (active.indexOf("validations") !== -1) {
        return "validations"
    } else return active;
}

const Header = (props) => {

    const { location } = props;
    const { cookies } = props;
    const { WELCOME, LOGOUT, HOME, EXPERTS, PROJECTS } = headerConstants;

    const initialState = {
        active: location.pathname.split("/")[1],
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    if (!props.location.pathname.includes("signin")) {
        const userStorage = getStorageUser();

        if (!userStorage?.employe?.login && !userStorage?.expert?.email_1) {
            return <Redirect to="/signin" />
        }
        const login = userStorage?.employe?.login || userStorage?.expert?.email_1;
        return (
            <div>
                <header id="header" className="d-flex flex-column justify-content-between">
                    <div className="top-content">
                        <div className="background-opacity-black">
                            <div className="container">
                                <div id="top-bar" className="d-flex justify-content-end align-items-center text-white">
                                    <span className="welcome d-flex align-items-center">
                                        {WELCOME}
                                        <span className="font-weight-bold ml-1">{login}</span></span>
                                    <NavLink className="logout text-white" onClick={() => logout(cookies)} to="/signin">{LOGOUT}</NavLink>
                                </div>
                            </div>
                        </div>
                        <nav id="main-menu" className="navbar navbar-expand navbar-dark px-0">
                            <div className="container">
                                <NavLink to="/" className="navbar-brand">
                                    <h2 className="d-none">Civipol Base Experts Administration</h2>
                                </NavLink>
                                <div className="collapse navbar-collapse" id="main-navbar">
                                    {userStorage?.employe?.login &&
                                        <ul className="navbar-nav ml-auto">
                                            <li onClick={() => setActive(dispatch, "home")} className={(location.pathname.split('/')[1] === "home") ? "nav-item active" : "nav-item"}>
                                                <NavLink className="nav-link" to="/home">{HOME}</NavLink>
                                            </li>
                                            <li onClick={() => setActive(dispatch, "experts")} className={(location.pathname.split('/')[1] === "experts") ? "nav-item active" : "nav-item"}>
                                                <NavLink className="nav-link" to="/experts/page/1">{EXPERTS}</NavLink>
                                            </li>
                                            <li onClick={() => setActive(dispatch, "projects")} className={(location.pathname.split('/')[1] === "projects") ? "nav-item active" : "nav-item"}>
                                                <NavLink className="nav-link" to="/projects/page/1">{PROJECTS}</NavLink>
                                            </li>
                                        </ul>
                                    }
                                    {userStorage?.expert?.email_1 &&
                                        <ul className="navbar-nav ml-auto">
                                            <li onClick={() => setActive(dispatch, "team-leader")} className={(location.pathname.split('/')[1] === "team-leader") ? "nav-item active" : "nav-item"}>
                                                <NavLink className="nav-link" to="/team-leader">{HOME}</NavLink>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div id="introduction">
                        <div className="container">
                            <h1 className="title-page text-uppercase text-white mb-4">{translate(`header.${location.pathname.split('/')[1]}`)}</h1>
                        </div>
                    </div>
                </header>
            </div>
        )
    } else {
        return null
    }
}

export default withRouter(withCookies(Header));