import React, { useContext } from "react";
import { GET_EXPERTS_BY_PAGE } from "../../../../queries/getExperts";
import { expertPageContext } from "../../../../stores/expertsPageStore";
import client from "../../../../ApolloSetup";
import { withRouter } from "react-router-dom";
import { setSessionItemByKeyValue } from "../../../utils/sessionStorage";
import { emptyObj, searchEngineContext } from "../../../../stores/searchEngineStore";


const ReinitSearch = ({ history }) => {
    const expertsContext = useContext(expertPageContext);
    const searchStore = useContext(searchEngineContext);

    const handleClick = async () => {

        history.push("/experts/page/1");
        setSessionItemByKeyValue("searchExperts", emptyObj);
        searchStore.dispatch({
            type: "setSearchInput", target: {
                inputSearch: "",
                mission_duration: "",
                nationality: "",
                countries: "",
                language: {
                    langue: "",
                    langue_level: "",
                },
                skills: [],
                servicesDetails: {},
                disponibility: "",
                display_disponible: false,
            }
        });

        await client.query({
            query: GET_EXPERTS_BY_PAGE,
            variables: { page: 1 }
        })
            .then((values) => {
                expertsContext.dispatch({ type: "setExperts", target: values.data.getExperts })
            })
            .catch((error) => {
                expertsContext.dispatch({ type: "setError", target: "ERROR" })
            })
    }

    return (
        <button type='button'
            className="btn btn-civipol-white"
            onClick={handleClick}
        >
            Reinitialiser la recherche
        </button>
    )
}

export default withRouter(ReinitSearch);