import React, { useContext } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ModificationsContainer from "./sections/modifications/ModificationsContainer";
import ConsultationsContainer from "./sections/consultations/ConsultationsContainer";
import { notificationContext } from '../../stores/notificationsStore';
import client from '../../ApolloSetup';
import {useHistory, useParams} from 'react-router-dom';
import {DELETE_EXPERT} from '../../mutations/upsertExpert';
import {administrationConstant} from '../../constants/administration.constant';
import { errorsGesture } from "../utils/errors";


const Administration = () => {
    const notificationStore = useContext(notificationContext);
    const params = useParams();
    const history = useHistory();

    const handleDeleteAccount = () => {
        client.mutate({
            mutation: DELETE_EXPERT,
            variables: {
                expertID: params.id
            },
            fetchPolicy: 'no-cache'
        })
        .then((values) => history.push('/experts/page/1'))
        .catch(errors => errorsGesture(errors, () => notificationStore.dispatch({ type: "setAlert", target: { element: "errorAuthorizationAction" } })))
    };

    const handleClick = () => {
        notificationStore.dispatch({
            type: "setAlert",
            target: {
                element: "deleteAccount1",
                isCancel: true,
                callback:  handleDeleteAccount
            }
        });
    };

    return (
        <div className="row">
            <div className="col-12">
                <section className="tabs mb-5">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="big-title mb-5">{administrationConstant.TITLE}</h2>
                        <button className="btn btn-civipol" onClick={handleClick}>{administrationConstant.DELETE_EXPERT}</button>
                    </div>
                    <Tabs defaultActiveKey="modifications" className="border-top border-right border-left">
                        <Tab className="card border-top-0 overflow" eventKey="modifications" title="Historique des modifications">
                            <ModificationsContainer />
                        </Tab>
                        <Tab className="card border-top-0 overflow" eventKey="consultations" title="Consultations">
                            <ConsultationsContainer />
                        </Tab>
                    </Tabs>
                </section>
            </div>
        </div>
    )
}

export default Administration;
