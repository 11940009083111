import React, { Fragment } from "react";
import DocumentContainer from "./DocumentContainer";
import { Form } from "react-bootstrap";

const DocumentsToUploadComponent = (props) => {

    //tableau des clés de traduction des supporting documents
    const documents = [
        "ID",
        "Diploma",
        "Certificat",
        "AutorNotCumulActivity",
        "VitalCard",
        "DriveLicence",
        "KBIS",
        "Siret",
        "CriminalRecord",
        "RCAttestation",
        "TransportCertificate",
        "MedicalSuitability",
        "SalarySlip",
        "PensionSlip",
        "MutualInsuranceCertificat",
        "ResidencePermit"
    ];

    return (
        <Fragment>
            <Form.Label className={props.errors.supporting_documents && (props.errors.supporting_documents.documents) ? "is-invalid-label" : null}>
            </Form.Label>
            {(props.errors.supporting_documents && props.errors.supporting_documents.documents) ? <p className="is-invalid">{props.errors.supporting_documents.documents}</p> : null}
            <div className="document-upload-container">
                {documents.map((value, key) => <DocumentContainer value={value} key={key} keyInput={key} formik={props} />)}
            </div>
        </Fragment>
    )
}

export default DocumentsToUploadComponent;