import React from "react";
import moment from "moment";
import ModificationsLogs from "../logs/ModificationsLogs";

const whoModify = (props) => {
  const { role, expert, employe } = props;
  if (role === "EXPERT") {
    return (
      <span><span className="text-success">{`L'expert ${expert.firstName} ${expert.lastName} `}</span>a </span>
    )
  } else if (role === "EMPLOYEE") {
    if (employe && employe.login) {
      return (
        <span><span className="text-danger">{`L'employé ${employe.login} `}</span>a </span>
      )
    } else return null;
  }
}

const action = ({ action_type, mission, log, table }) => {
  if (log) {
    if (log.id_offer) {
      if (action_type === "UPDATE") return <span>{`modifié : `}</span>
      else if (action_type === "MISSION_POSTULE" && log.id_offer.id_drupal) return <span>{`postulé pour l'offre suivante:  ${log.id_offer.id_drupal}`}</span>
      else if (action_type === "MISSION_DEPOSTULE") return <span>{`annulé sa candidature pour l'offre suivante: ${log.id_offer}`}</span>
      else if (action_type === "MISSION_IS_REFUSED" && log.id_offer.mission) return <span>{`rejeté la candidature de l'expert pour l'offre suivante: ${log.id_offer.mission.id_drupal}`}</span>
      else return null;
    } else if (log.offer) {
      if (action_type === "MISSION_IS_ACCEPTED" && mission.offer.id_drupal) return <span>{`accepté la candidature de l'expert pour l'offre suivante: ${mission.offer.id_drupal}`}</span>
      else return null;
    } return null;
  } else return null;
}

const getLog = (props) => {
  const { action_type } = props;

  if (action_type === "UPDATE") {
    return <ModificationsLogs {...props} />
  } else return null;
}

const FormattedLogs = (props) => {
  const whoDid = whoModify(props)
  const actionType = action(props);
  const logStringified = getLog(props)
  return (
    <p>{whoDid} {actionType} {logStringified}</p>
  )
};


const ModificationComponent = (props) => {
  const { createdAt } = props.modification;
  const date = moment(createdAt).format("DD/MM/YYYY - HH : mm");

  return (
    <div className="row px-4 mb-2">
      <div className="col">
        {date}
        <FormattedLogs {...props.modification} />
      </div>
    </div>
  )
}

export default ModificationComponent;