import React, { useContext } from "react";
import { missionsContext } from "../../../../stores/missionsStore";
import { candidaturesConstants } from "../../../../constants/missions.constants";
import { Element } from 'react-scroll'
import CandidatureComponent from "./CandidatureComponent";
import NotificationMissionsTemplate from "../notifications/NotificationsMissionsTemplate";
import { TranslateContextWrapper } from "../../../../stores/TranslateContext";
const { TITLE, TITLE_POSTULE, TITLE_REJECT } = candidaturesConstants;

let setUnderlignes = (i, components) => {
    if (i === 0 && components.length > 1) {
        return ("underligne pb-4");
    } else if (i !== 0 && i < components.length - 1) {
        return ("underligne pb-4 pt-4");
    } else if (components.length === 1) {
        return ("");
    } else {
        return ("pt-4");
    }
}

const PostuleMapper = (props) => {
    const { candidatures } = props;
    if (candidatures.length > 0) {
        return (
            <div className="mb-4">
                <h2 className="title-section-blue text-uppercase mb-4">{TITLE_POSTULE}</h2>
                {candidatures.map((value, key) => {
                    return (
                        <div key={key} className={setUnderlignes(key, candidatures)}>
                            <CandidatureComponent value={value} />
                        </div>

                    )
                })}
            </div>
        )
    } else return null;
}

const RejectedMapper = (props) => {
    const { candidatures } = props;

    if (candidatures.length > 0) {
        return (
            <div className="mb-4">
                <h2 className="title-section-blue text-uppercase mb-4">{TITLE_REJECT}</h2>
                {candidatures.map((value, key) => {
                    return (
                        <div key={key} className={setUnderlignes(key, candidatures)}>
                            <CandidatureComponent reject value={value} />
                        </div>

                    )
                })}
            </div>
        )
    } else return null;
}

const CandidaturesContainer = (props) => {

    const { state } = useContext(missionsContext);
    const { candidatures } = state;
    const Title = () => {

        if (candidatures && candidatures.length > 0) {
            let values = {
                nb_candidatures: candidatures.length,
                s: (candidatures.length > 1) ? "s" : "",
            }

            let text = props.translateContext.translateMessage("missionsPage.candidatures.text", "", values);
            return (
                <div className="row mb-4">
                    <div className="col">
                        {text}
                    </div>
                </div>
            )
        } else return null;
    }

    return (
        <Element name="1" className="mb-4">
            <div className="d-flex flex-column">
                <div className="row">
                    <div className="col mb-4">
                        <h2 className="big-title mb-3">{TITLE}</h2>
                    </div>
                </div>
                {(candidatures && (candidatures.length > 0 || candidatures.postule.length > 0 || candidatures.rejected.length > 0)) ?
                    <div>
                        <Title />
                        <div className="row mb-4">
                            <div className="col">
                                <PostuleMapper candidatures={candidatures.postule} />
                                <RejectedMapper candidatures={candidatures.rejected} />
                            </div>
                        </div>
                    </div>
                    : <NotificationMissionsTemplate type="candidature" />
                }
            </div>
        </Element >
    )
}

export default TranslateContextWrapper(CandidaturesContainer);